@import url(https://fonts.googleapis.com/css?family=Roboto:500);
:root {
	--navbar-height: 50px;
	--progress-bar-height: 5px;
	--bottom-bar-height: 50px;
	--online-status-time: 2s;
	--online-status-height: 20px;
	--safe-area-inset: 20px;

	--empty-state-padding: 40px;
	--empty-state-width: 150px;
	--toc-opacity: 0.95;

	--article-settings-options-padding: 20px;
	--font-size-background: linear-gradient(145deg, #e6e6e6, #ffffff);
	--font-size-box-shadow: 5px 5px 4px #e8e8e8, -5px -5px 4px #ffffff;

	--font-size-background-pressed: #fff;
	--font-size-box-shadow-pressed: inset 12px 12px 24px #d9d9d9,
		inset -12px -12px 24px #ffffff;

	--transition-animation: height 400ms ease-in-out;
	--issue-navigator-size: 10vw;

	--swiper-theme-color: #333 !important;

	--hotspot-color-fill: #4285f4;
}

/*RESET*/
html {
	position: fixed;
	height: 100%;
	overflow: hidden;
}

html,
body {
	-webkit-overflow-scrolling: touch;
	text-rendering: optimizeSpeed;
}

.swiper-button-prev:after,
.swiper-container-rtl .swiper-button-next:after,
.swiper-button-next:after,
.swiper-container-rtl .swiper-button-prev:after {
	text-rendering: auto;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

.swiper-pagination {
	bottom: 0px !important;
}

.gallery .swiper-slide img {
	object-fit: contain;
	/*TODO*/
	/*max-height: 500px !important;*/
	margin: 0 auto !important;
	text-align: center !important;
	display: block !important;
}

/*#root > div {
	overflow: hidden;
}*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
	scroll-behavior: smooth;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block;
}
body {
	line-height: 1;
}
ol,
ul {
	list-style: none;
}
article ol,
article ul {
	-webkit-padding-start: 40px;
	        padding-inline-start: 40px;
}
article ol {
	list-style: decimal;
}
article ul {
	list-style: disc;
}
blockquote,
q {
	quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

input[type='color'],
input[type='date'],
input[type='datetime'],
input[type='datetime-local'],
input[type='email'],
input[type='month'],
input[type='number'],
input[type='password'],
input[type='search'],
input[type='tel'],
input[type='text'],
input[type='time'],
input[type='url'],
input[type='week'],
select:focus,
textarea {
	font-size: 16px;
}

/*END RESET*/

@media screen and (min-width: 767px) {
	:root {
		--issue-navigator-size: 7vw;
	}
}

@media screen and (max-width: 767px) {
	:root {
		--navbar-height: 50px;
	}
}

html,
body {
	height: 100%;
	width: 100%;
	padding: 0;
	margin: 0;
	overflow: hidden;
	font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
		Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

body > div {
	height: 100%;
	width: 100%;
	overflow: hidden;
}

main {
	height: 100%;
	width: 100%;
}

sup,
sub {
	font-size: 65%;
}

.App {
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
	position: fixed;
}

footer {
	position: absolute;
	width: 100%;
	bottom: 0;
	min-height: 50px;
	min-height: var(--bottom-bar-height);
}

nav {
	/*min-height: var(--navbar-height);*/
}

a {
	color: inherit;
	text-decoration: none;
}

* {
	box-sizing: border-box;
}

button {
	cursor: pointer;
	-webkit-user-select: none;
	-o-user-select: none;
	user-select: none;
}

.StripeElement {
	height: 40px;
	padding: 10px 12px;
	width: 100%;
	color: #32325d;
	background-color: white;
	border: 1px solid transparent;
	border-radius: 4px;

	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
	transition: box-shadow 150ms ease;
}

.StripeElement--focus {
	box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
	border-color: #fa755a;
}

.StripeElement--webkit-autofill {
	background-color: #fefde5 !important;
}

/*Components Default*/

.component img {
	vertical-align: middle;
}

.btn-31744 {
	display: none !important;
}

.content-protection {
	-webkit-user-select: none;
	user-select: none;
}

.content-protection article .component.text {
	-webkit-user-select: none;
	user-select: none;
}

.content-protection article img {
	pointer-events: none;
}

.gallery .swiper-button-next,
.gallery .swiper-button-prev {
	top: calc(50% - 22px);
}

/* * { overflow: visible !important; } */

/*article[dir="rtl"] section > div {
	flex-direction: column-reverse !important;
}*/

@media print {
	* {
		overflow-x: hidden;
	}

	html,
	body {
		position: static;
		overflow: visible;
		width: auto;
		height: auto;
	}

	.print-view {
		width: 100%;
		background: transparent;
	}

	.print-view > div {
		width: 100%;
		margin: auto;
		max-width: 720px;
		/*overflow-y: visible;*/
	}

	.print-view article {
		top: 0px;
	}

	.print-view article .canvas {
		height: auto !important;
	}

	/*body * {
		visibility: hidden;
	}

	.print-view article {
		left: 0px;
		top: 0px;
		position: absolute;
	}
	.print-view article p {
		page-break-before: always;
	}*/
}

.pulse-animation {
	-webkit-animation-name: color;
	        animation-name: color;
	-webkit-animation-duration: 2s;
	        animation-duration: 2s;
	-webkit-animation-iteration-count: 1;
	        animation-iteration-count: 1;
}

@-webkit-keyframes color {
	0% {
		fill: transparent;
	}
	50% {
		fill: #4285f4;
		fill: var(--hotspot-color-fill);
	}
	100% {
		fill: transparent;
	}
}

@keyframes color {
	0% {
		fill: transparent;
	}
	50% {
		fill: #4285f4;
		fill: var(--hotspot-color-fill);
	}
	100% {
		fill: transparent;
	}
}

.Home_home__gGhmG {
	height: calc(
		100vh - var(--bottom-bar-height) -
			env(safe-area-inset-bottom, var(--safe-area-inset))
	);

	background: #fafafa;
	padding-top: var(--navbar-height);
}

.Home_thumbnail-item__1RMNT {
	display: block;
	position: relative;
	cursor: pointer;
	height: 100%;
	width: var(--thumbnail-width);
	background: #fafafa;
	box-shadow: 0 0.5px 0 0 #ffffff inset, 0 1px 2px 0 #b3b3b3;
	overflow: hidden;
	-webkit-user-select: none;
	user-select: none;
}

.Home_thumbnail-item__1RMNT:after {
	z-index: -1;
	position: absolute;
	content: '';
	bottom: 15px;
	right: 10px;
	left: auto;
	width: 50%;
	top: 80%;
	max-width: 300px;
	background: #777;
	box-shadow: 0 15px 10px #777;
	-webkit-transform: rotate(3deg);
	transform: rotate(3deg);
}

.Home_thumbnail-item__1RMNT img {
	width: 100%;
}

.Home_thumbnail-item__1RMNT > .Home_issue-downloaded__idDj2 {
	position: absolute;
	bottom: 0px;
	right: 0px;
	margin-right: 5px;
	margin-bottom: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.Home_thumbnail-item__1RMNT > .Home_issue-downloaded__idDj2 > div {
	-webkit-mask: url(/static/media/icon-smartphone-2.8cf3997f.svg);
	        mask: url(/static/media/icon-smartphone-2.8cf3997f.svg);
	-webkit-mask-repeat: no-repeat;
	mask-repeat: no-repeat;
	margin: auto;
	height: 24px;
	width: 24px;
	background-color: black;
	box-shadow: 4px 5px 15px 0px rgba(0, 0, 0, 0.75);
}

.Home_corner-download__2ec5N {
	/*Positioning*/
	right: 0;
	bottom: 0;
	float: right;
	position: absolute;
	background: #eaeaea;
	border: none;

	/*Size*/
	width: 110px;
	height: 50px;

	/*Text*/
	color: #fff;
	font-weight: bold;
	font-size: 11pt;

	/*Center text*/
	display: flex;
	align-items: center;
	justify-content: center;

	/*Rotate the ribbon*/
	-webkit-transform: translateX(45px) translateY(15px) rotate(-45deg);
	        transform: translateX(45px) translateY(15px) rotate(-45deg);
	transition: box-shadow ease-out 200ms;
}

.Home_corner-download__2ec5N:hover {
	box-shadow: 1px 0px 5px rgba(0, 0, 0, 0.5);
}

.Home_corner-download__2ec5N div {
	-webkit-transform: rotate(45deg) translateY(-8px) translateX(-5px);
	        transform: rotate(45deg) translateY(-8px) translateX(-5px);
}

.Home_corner-ribbon__KR9wH {
	/*Positioning*/
	right: 0;
	float: right;
	position: absolute;
	background: #e43;

	/*Size*/
	width: 110px;
	height: 20px;

	/*Text*/
	color: #fff;
	font-weight: bold;
	font-size: 11pt;

	/*Center text*/
	display: flex;
	align-items: center;
	justify-content: center;

	/*Rotate the ribbon*/
	-webkit-transform: translateX(30px) translateY(20px) rotate(45deg);
	        transform: translateX(30px) translateY(20px) rotate(45deg);
	box-shadow: 6px 7px 11px -8px rgba(0, 0, 0, 0.75);
}

.Home_corner-ribbon__KR9wH.Home_paid__2Jp0o {
	font-size: 9pt;
	background: blue;
}

.Home_netflix-layout-container__Bsh-2 {
	height: calc(100% + var(--navbar-height) + 20px);
	overflow: hidden;
	max-height: 100%;
	width: 100%;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr 1fr;
	grid-gap: 0px 0px;
	gap: 0px 0px;
	grid-template-areas:
		'.'
		'.';
}

.Home_netflix-layout-container-single__3VpeH {
	height: calc(100% + var(--navbar-height) + 20px);
	width: 100%;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr;
	grid-gap: 0px 0px;
	gap: 0px 0px;
	grid-template-areas: '.';
}

.Home_netflix-banner__2vxw4 {
	height: 50vh;
	width: 100%;

	position: relative;
	/*margin-bottom: 50px;*/
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-direction: column;

	overflow: hidden;
	/*-webkit-box-shadow: 0px 6px 11px -8px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 0px 6px 11px -8px rgba(0, 0, 0, 0.75);
	box-shadow: 0px 6px 11px -8px rgba(0, 0, 0, 0.75);*/
	padding-top: 10px;
	-webkit-user-select: none;
	user-select: none;
}

.Home_netflix-banner__2vxw4 > .Home_cover-item__I1k5M {
	display: block;
	position: relative;
	z-index: 0;
	cursor: pointer;

	overflow: hidden;

	margin: auto;

	-webkit-user-select: none;
	user-select: none;
}

.Home_netflix-banner__2vxw4 > .Home_cover-item__I1k5M img {
	background: #fff;
	border: 1px solid #e0e4ea;
}

.Home_cover-item__I1k5M:after {
	z-index: -1;
	position: absolute;
	content: '';
	bottom: 15px;
	right: 10px;
	left: auto;
	width: 50%;
	top: 80%;
	max-width: 400px;
}

.Home_netflix-banner__2vxw4 .Home_cover-item__I1k5M a {
	height: 100%;
	max-width: 100%;
}

.Home_netflix-banner__2vxw4 .Home_cover-item__I1k5M img {
	height: 100%;
	width: 100%;
	object-fit: contain;
}

.Home_netflix-banner__2vxw4 > hr {
	border: 1px solid #e0e4ea;
	margin-top: 20px;
	margin-bottom: 30px;
	width: 100%;
}

.Home_netflix-banner__2vxw4 > .Home_logo__1_dib {
	width: 70vw;
	max-width: 70vw;
	z-index: 0;
	margin: auto;
}

.Home_cover-item__I1k5M > .Home_description__DvSm0 {
	text-align: center;
	margin-top: 3px;
	font-size: 10pt;
	line-height: 11pt;
	max-width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.Home_netflix-banner__2vxw4 > .Home_archive__3Vv2m {
	z-index: 0;
	display: flex;
	align-items: flex-start;
	color: #777;
	font-size: 10pt;
}

.Home_netflix-banner__2vxw4 > .Home_logo__1_dib > img {
	max-width: 70vw;
}

.Home_cover-blur-netflix__lsfLx {
	z-index: 0;
	position: absolute;

	filter: blur(4px);
	-webkit-filter: blur(4px);

	/* Full height */
	width: 100%;

	/* Center and scale the image nicely */
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.Home_netflix-item__2m7Ru {
	position: relative;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: baseline;

	-webkit-user-select: none;
	user-select: none;
}

.Home_netflix-item__2m7Ru > :first-child {
	width: 90%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.Home_netflix-item__2m7Ru > :first-child > .Home_description__DvSm0 {
	margin-top: 10px;
	display: flex;
	justify-content: center;
	font-size: 10pt;
	overflow: hidden;
	text-align: center;
}

.Home_netflix-list__1ZDNl {
	height: 100%;
	width: 100%;
	display: grid;
	overflow: initial;
	padding: 3%;
}

.Home_netflix-banner__2vxw4 > .Home_cover-item__I1k5M > .Home_corner-ribbon__KR9wH {
	top: -5px;
}

.Home_feed-list__21S5k {
	width: 100%;
	height: 100%;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 35vh;
	grid-gap: 0px 0px;
	gap: 0px 0px;
	grid-template-areas: '. .';
}

.Home_feed-group-list__hl1wI {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	padding: 0px 30px;
	padding-top: 10px;

	-webkit-user-select: none;
	user-select: none;
}

.Home_feed-group__2S2ZJ {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 50px 360px;
	grid-gap: 0px 0px;
	gap: 0px 0px;
	margin-top: 30px;
	grid-template-areas:
		'.'
		'.';
}

.Home_feed-group__2S2ZJ > :first-child {
	display: flex;
	height: 100%;
	align-items: center;
}

.Home_feed-group__2S2ZJ > :first-child > h3 {
	height: auto;
	font-size: 1.3rem;
	font-weight: 500;
	color: #222;
	margin-left: 12px;
}

.Home_feed-group__2S2ZJ > :last-child {
	display: flex;
	overflow-x: auto;
}

.Home_feed__P-6ds {
	width: 250px;
	display: flex;
	justify-content: center;
	flex-direction: column;
	padding-left: 12px;
	padding-right: 12px;
}

.Home_feed__P-6ds > .Home_image__1ePQQ {
	width: 225px;
	height: 300px;
	border-radius: 12px;
	overflow: hidden;
	background: #fff;
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
	transition: box-shadow 200ms ease-out;
	-webkit-user-select: none;
	user-select: none;
}

.Home_feed__P-6ds > .Home_image__1ePQQ > img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	padding: 15px;
	transition: -webkit-transform 200ms ease-out;
	transition: transform 200ms ease-out;
	transition: transform 200ms ease-out, -webkit-transform 200ms ease-out;
}

.Home_feed__P-6ds > .Home_image__1ePQQ:hover {
	box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.2), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

.Home_feed__P-6ds > .Home_image__1ePQQ:hover > img {
	-webkit-transform: scale(1.08);
	        transform: scale(1.08);
}

.Home_feed__P-6ds > .Home_name__3Han8 {
	margin-top: 8px;
	min-height: 30px;
}

.Home_feed__P-6ds > .Home_name__3Han8 > h3 {
	margin: 0;
	font-weight: 400;
	font-size: 0.9rem;
	color: #3d3d3d;
}

@media screen and (min-width: 769px) {
	.Home_home__gGhmG {
		display: flex;
		height: calc(
			100vh - env(safe-area-inset-bottom, var(--safe-area-inset))
		);
		overflow: hidden;
	}

	.Home_home__gGhmG > footer {
		display: none !important;
	}

	.Home_home__gGhmG > .Home_netflix-layout-container__Bsh-2 {
		overflow: hidden;
	}

	.Home_netflix-layout-container__Bsh-2 {
		display: grid;
		grid-template-columns: 0.7fr 0.3fr;
		grid-template-rows: 1fr;
		grid-gap: 0px 0px;
		gap: 0px 0px;
		grid-template-areas: '. .';
	}

	.Home_netflix-banner__2vxw4 {
		height: 100%;
		width: calc(100% - var(--navbar-height));
		margin-left: calc(var(--navbar-height) - 15px);
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		overflow: hidden;
		box-shadow: 0 0.5px 0 0 #ffffff inset, 0 1px 2px 0 #b3b3b3;
		background-color: #f4f4f4;
	}

	.Home_netflix-banner__2vxw4 > .Home_cover-item__I1k5M {
		margin: auto;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.Home_cover-item__I1k5M > .Home_description__DvSm0 {
		margin-top: 5%;
	}

	/*.cover-item > img {
		width: 95%;
	}*/

	.Home_cover-blur-netflix__lsfLx {
		filter: blur(4px);
		-webkit-filter: blur(4px);
		width: auto;
		height: 100%;
	}

	.Home_cover-blur-netflix__lsfLx > img {
		display: none;
		height: 100%;
	}

	.Home_netflix-list__1ZDNl {
		display: flex;
		overflow-y: auto;
		overflow-x: hidden;

		flex-direction: row;
		flex-flow: wrap;
		width: 100%;
	}

	.Home_netflix-item__2m7Ru {
		width: 100%;
		max-width: 300px;
		height: auto;
		margin: 20px 0px;
	}

	.Home_feed-list__21S5k {
		margin-left: var(--bottom-bar-height);
		width: calc(100% - var(--bottom-bar-height));
		grid-template-columns: 1fr 1fr 1fr;
		grid-template-rows: 60vh;
		grid-gap: 0px 0px 0px;
		gap: 0px 0px 0px;
		grid-template-areas: '. . .';
	}

	.Home_feed-group-list__hl1wI {
		margin-left: var(--bottom-bar-height);
		width: calc(100% - var(--bottom-bar-height));
	}
}

@media screen and (min-width: 992px) {
	.Home_home__gGhmG > .Home_netflix-layout-container__Bsh-2 {
		overflow: hidden;
		width: calc(100% - var(--bottom-bar-height));
		margin-left: var(--bottom-bar-height);
	}

	.Home_netflix-layout-container__Bsh-2 {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-template-rows: 1fr;
		grid-gap: 0px 0px;
		gap: 0px 0px;
		grid-template-areas: '. .';
		overflow: hidden;
		max-height: 100%;
	}

	.Home_netflix-banner__2vxw4 {
		height: 100%;
		width: calc(100%);
		margin-left: 0px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		overflow: hidden;
		position: relative;
		box-shadow: 0 0.5px 0 0 #ffffff inset, 0 1px 2px 0 #b3b3b3;
		background-color: #f4f4f4;
	}

	.Home_netflix-banner__2vxw4 > .Home_cover-item__I1k5M {
		max-width: 40vw;
		min-height: 30vh;
		min-width: 200px;
		margin: auto;

		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.Home_netflix-banner__2vxw4 > .Home_cover-item__I1k5M > img {
		width: auto;
		max-width: 90%;
		max-height: 90%;
	}

	/*.cover-item > img {
		width: 95%;
	}*/

	.Home_cover-blur-netflix__lsfLx {
		filter: blur(4px);
		-webkit-filter: blur(4px);
		width: auto;
		height: 100%;
	}

	.Home_cover-blur-netflix__lsfLx > img {
		display: none;
		height: 100%;
	}

	.Home_netflix-list__1ZDNl {
		display: flex;
		overflow-y: auto;
		overflow-x: hidden;

		flex-direction: row;
		flex-flow: wrap;
		justify-content: space-around;
		width: 100%;
	}

	.Home_cover-item__I1k5M > .Home_description__DvSm0 {
		margin-top: 5%;
	}

	.Home_netflix-item__2m7Ru {
		width: 100%;
		max-width: 50%;
		height: auto;
		margin: 20px 0px;
	}
}

@media screen and (min-width: 1200px) {
	.Home_netflix-banner__2vxw4 > .Home_cover-item__I1k5M {
		min-height: 70vh;
		margin: auto;
		max-width: 600px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.Home_netflix-list__1ZDNl {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		grid-gap: 40px 0px;
		gap: 40px 0px;
		grid-template-areas: '. . .';
	}

	.Home_netflix-item__2m7Ru {
		width: 100%;
		max-width: 100%;
		height: 100%;
	}

	.Home_feed-list__21S5k {
		margin-left: var(--bottom-bar-height);
		width: calc(100% - var(--bottom-bar-height));
		grid-template-columns: 1fr 1fr 1fr 1fr;
		grid-template-rows: 60vh;
		grid-gap: 0px 0px 0px 0px;
		gap: 0px 0px 0px 0px;
		grid-template-areas: '. . .';
	}

	.Home_feed-group-list__hl1wI {
		margin-left: var(--bottom-bar-height);
		width: calc(100% - var(--bottom-bar-height));
	}

	.Home_netflix-item__2m7Ru:nth-child(3n + 1):nth-last-child(-n + 3),
	.Home_netflix-item__2m7Ru:nth-child(3n + 1):nth-last-child(-n + 3) ~ .Home_netflix-item__2m7Ru {
		padding-bottom: 20px;
	}
}

@media screen and (max-width: 767px) {
	.Home_feed__P-6ds > .Home_name__3Han8 {
		min-width: 90%;
	}

	.Home_home__gGhmG {
		height: calc(
			100vh - var(--bottom-bar-height) -
				env(safe-area-inset-bottom, var(--safe-area-inset))
		);
	}

	.Home_netflix-banner__2vxw4 {
		height: 460px;
		justify-content: center;
	}

	.Home_cover-blur-netflix__lsfLx > img {
		width: 100%;
		display: none;
	}

	.Home_netflix-banner__2vxw4 > .Home_cover-item__I1k5M > img {
		height: 100%;
		width: 100%;
	}

	.Home_netflix-banner__2vxw4 > .Home_cover-item__I1k5M {
		margin-left: 0;
		height: 400px;
		min-width: 300px;
		max-width: 300px;
		margin: auto;
	}

	.Home_netflix-list__1ZDNl {
		overflow: initial;
		display: flex;
		flex-wrap: wrap;
		padding-bottom: calc(var(--bottom-bar-height) * 2.5);
	}

	.Home_netflix-item__2m7Ru {
		width: 100%;
		max-width: 50%;
		height: auto;
		margin: 20px 0px;
	}

	.Home_thumbnail-item__1RMNT {
		max-width: 100%;
	}
	.Home_thumbnail-item__1RMNT > img {
		min-height: 190px;
	}

	.Home_netflix-layout-container__Bsh-2 {
		overflow-x: hidden;
		overflow-y: auto;
	}

	.Home_home__gGhmG > footer {
		display: flex !important;
	}

	.Home_netflix-layout-container-single__3VpeH > .Home_netflix-banner__2vxw4 {
		height: 100%;
	}

	.Home_netflix-layout-container-single__3VpeH > .Home_netflix-banner__2vxw4 > .Home_cover-blur-netflix__lsfLx {
		display: none;
	}

	.Home_feed-group-list__hl1wI {
		padding: 0px;
		padding-left: 10px;
	}

	.Home_feed-group-list__hl1wI > :last-child > :last-child {
		margin-bottom: 50px;
	}

	.Home_feed-group__2S2ZJ {
		grid-template-rows: 50px 360px;
		grid-gap: 0px 0px;
		gap: 0px 0px;
		margin-top: 30px;
	}

	.Home_feed-group__2S2ZJ > :last-child {
		display: flex;
		overflow-x: auto;
	}

	.Home_feed__P-6ds {
		width: 270px;
		display: flex;
		justify-content: center;
		flex-direction: column;
		padding-left: 20px;
		padding-right: 20px;
	}
}

.Loading_loading-container__H8tFH {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	-webkit-user-select: none;
	user-select: none;
}

.Loading_lds-ripple__2APTP {
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
}
.Loading_lds-ripple__2APTP div {
	position: absolute;
	opacity: 1;
	border-radius: 50%;
	-webkit-animation: Loading_lds-ripple__2APTP 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
	        animation: Loading_lds-ripple__2APTP 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.Loading_lds-ripple__2APTP div:nth-child(2) {
	-webkit-animation-delay: -0.5s;
	        animation-delay: -0.5s;
}

.Loading_circle-loading__230Wb {
	border: 4px solid #f3f3f3; /* Light grey */
	border-top: 4px solid #3498db; /* Blue */
	border-radius: 50%;
	width: 20px;
	height: 20px;
	-webkit-animation: Loading_spin__1_bLM 2s linear infinite;
	        animation: Loading_spin__1_bLM 2s linear infinite;
}

@-webkit-keyframes Loading_spin__1_bLM {
	0% {
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		        transform: rotate(360deg);
	}
}

@keyframes Loading_spin__1_bLM {
	0% {
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		        transform: rotate(360deg);
	}
}

@-webkit-keyframes Loading_lds-ripple__2APTP {
	0% {
		top: 36px;
		left: 36px;
		width: 0;
		height: 0;
		opacity: 1;
	}
	100% {
		top: 0px;
		left: 0px;
		width: 72px;
		height: 72px;
		opacity: 0;
	}
}

@keyframes Loading_lds-ripple__2APTP {
	0% {
		top: 36px;
		left: 36px;
		width: 0;
		height: 0;
		opacity: 1;
	}
	100% {
		top: 0px;
		left: 0px;
		width: 72px;
		height: 72px;
		opacity: 0;
	}
}

.Loading_lds-ellipsis__4t9wC {
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
}
.Loading_lds-ellipsis__4t9wC div {
	position: absolute;
	top: 33px;
	width: 13px;
	height: 13px;
	border-radius: 50%;
	background: #fff;
	-webkit-animation-timing-function: cubic-bezier(0, 1, 1, 0);
	        animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.Loading_lds-ellipsis__4t9wC div:nth-child(1) {
	left: 8px;
	-webkit-animation: Loading_lds-ellipsis1__2e3rz 0.6s infinite;
	        animation: Loading_lds-ellipsis1__2e3rz 0.6s infinite;
}
.Loading_lds-ellipsis__4t9wC div:nth-child(2) {
	left: 8px;
	-webkit-animation: Loading_lds-ellipsis2__1Sy5Z 0.6s infinite;
	        animation: Loading_lds-ellipsis2__1Sy5Z 0.6s infinite;
}
.Loading_lds-ellipsis__4t9wC div:nth-child(3) {
	left: 32px;
	-webkit-animation: Loading_lds-ellipsis2__1Sy5Z 0.6s infinite;
	        animation: Loading_lds-ellipsis2__1Sy5Z 0.6s infinite;
}
.Loading_lds-ellipsis__4t9wC div:nth-child(4) {
	left: 56px;
	-webkit-animation: Loading_lds-ellipsis3__1kOL0 0.6s infinite;
	        animation: Loading_lds-ellipsis3__1kOL0 0.6s infinite;
}
@-webkit-keyframes Loading_lds-ellipsis1__2e3rz {
	0% {
		-webkit-transform: scale(0);
		        transform: scale(0);
	}
	100% {
		-webkit-transform: scale(1);
		        transform: scale(1);
	}
}
@keyframes Loading_lds-ellipsis1__2e3rz {
	0% {
		-webkit-transform: scale(0);
		        transform: scale(0);
	}
	100% {
		-webkit-transform: scale(1);
		        transform: scale(1);
	}
}
@-webkit-keyframes Loading_lds-ellipsis3__1kOL0 {
	0% {
		-webkit-transform: scale(1);
		        transform: scale(1);
	}
	100% {
		-webkit-transform: scale(0);
		        transform: scale(0);
	}
}
@keyframes Loading_lds-ellipsis3__1kOL0 {
	0% {
		-webkit-transform: scale(1);
		        transform: scale(1);
	}
	100% {
		-webkit-transform: scale(0);
		        transform: scale(0);
	}
}
@-webkit-keyframes Loading_lds-ellipsis2__1Sy5Z {
	0% {
		-webkit-transform: translate(0, 0);
		        transform: translate(0, 0);
	}
	100% {
		-webkit-transform: translate(24px, 0);
		        transform: translate(24px, 0);
	}
}
@keyframes Loading_lds-ellipsis2__1Sy5Z {
	0% {
		-webkit-transform: translate(0, 0);
		        transform: translate(0, 0);
	}
	100% {
		-webkit-transform: translate(24px, 0);
		        transform: translate(24px, 0);
	}
}

.Loading_lds-circle__3jfbQ {
	display: inline-block;
	-webkit-transform: translateZ(1px);
	        transform: translateZ(1px);
}
.Loading_lds-circle__3jfbQ > div {
	display: inline-block;
	width: 64px;
	height: 64px;
	margin: 8px;
	border-radius: 50%;
	background: #fff;
	-webkit-animation: Loading_lds-circle__3jfbQ 2.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
	        animation: Loading_lds-circle__3jfbQ 2.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
@-webkit-keyframes Loading_lds-circle__3jfbQ {
	0%,
	100% {
		-webkit-animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
		        animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
	}
	0% {
		-webkit-transform: rotateY(0deg);
		        transform: rotateY(0deg);
	}
	50% {
		-webkit-transform: rotateY(1800deg);
		        transform: rotateY(1800deg);
		-webkit-animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
		        animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
	}
	100% {
		-webkit-transform: rotateY(3600deg);
		        transform: rotateY(3600deg);
	}
}
@keyframes Loading_lds-circle__3jfbQ {
	0%,
	100% {
		-webkit-animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
		        animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
	}
	0% {
		-webkit-transform: rotateY(0deg);
		        transform: rotateY(0deg);
	}
	50% {
		-webkit-transform: rotateY(1800deg);
		        transform: rotateY(1800deg);
		-webkit-animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
		        animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
	}
	100% {
		-webkit-transform: rotateY(3600deg);
		        transform: rotateY(3600deg);
	}
}

.Loading_lds-rectangle-pulse__2QzN4 {
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
}
.Loading_lds-rectangle-pulse__2QzN4 div {
	display: inline-block;
	position: absolute;
	left: 8px;
	width: 16px;
	background: #fff;
	-webkit-animation: Loading_lds-rectangle-pulse__2QzN4 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
	        animation: Loading_lds-rectangle-pulse__2QzN4 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.Loading_lds-rectangle-pulse__2QzN4 div:nth-child(1) {
	left: 8px;
	-webkit-animation-delay: -0.24s;
	        animation-delay: -0.24s;
}
.Loading_lds-rectangle-pulse__2QzN4 div:nth-child(2) {
	left: 32px;
	-webkit-animation-delay: -0.12s;
	        animation-delay: -0.12s;
}
.Loading_lds-rectangle-pulse__2QzN4 div:nth-child(3) {
	left: 56px;
	-webkit-animation-delay: 0;
	        animation-delay: 0;
}
@-webkit-keyframes Loading_lds-rectangle-pulse__2QzN4 {
	0% {
		top: 8px;
		height: 64px;
	}
	50%,
	100% {
		top: 24px;
		height: 32px;
	}
}
@keyframes Loading_lds-rectangle-pulse__2QzN4 {
	0% {
		top: 8px;
		height: 64px;
	}
	50%,
	100% {
		top: 24px;
		height: 32px;
	}
}

.Loading_lds-ring__2lIkZ {
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
}
.Loading_lds-ring__2lIkZ div {
	box-sizing: border-box;
	display: block;
	position: absolute;
	width: 64px;
	height: 64px;
	margin: 8px;
	border: 8px solid #fff;
	border-radius: 50%;
	-webkit-animation: Loading_lds-ring__2lIkZ 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	        animation: Loading_lds-ring__2lIkZ 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	border-color: #fff transparent transparent transparent;
}
.Loading_lds-ring__2lIkZ div:nth-child(1) {
	-webkit-animation-delay: -0.45s;
	        animation-delay: -0.45s;
}
.Loading_lds-ring__2lIkZ div:nth-child(2) {
	-webkit-animation-delay: -0.3s;
	        animation-delay: -0.3s;
}
.Loading_lds-ring__2lIkZ div:nth-child(3) {
	-webkit-animation-delay: -0.15s;
	        animation-delay: -0.15s;
}
@-webkit-keyframes Loading_lds-ring__2lIkZ {
	0% {
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		        transform: rotate(360deg);
	}
}
@keyframes Loading_lds-ring__2lIkZ {
	0% {
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		        transform: rotate(360deg);
	}
}

.Loading_lds-dual-ring__2U6OC {
	display: inline-block;
	width: 80px;
	height: 80px;
}
.Loading_lds-dual-ring__2U6OC:after {
	content: ' ';
	display: block;
	width: 64px;
	height: 64px;
	margin: 8px;
	border-radius: 50%;
	border: 6px solid #fff;
	border-color: #fff transparent #fff transparent;
	-webkit-animation: Loading_lds-dual-ring__2U6OC 1.2s linear infinite;
	        animation: Loading_lds-dual-ring__2U6OC 1.2s linear infinite;
}
@-webkit-keyframes Loading_lds-dual-ring__2U6OC {
	0% {
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		        transform: rotate(360deg);
	}
}
@keyframes Loading_lds-dual-ring__2U6OC {
	0% {
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		        transform: rotate(360deg);
	}
}

.Loading_lds-roller__1TFO4 {
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
}
.Loading_lds-roller__1TFO4 div {
	-webkit-animation: Loading_lds-roller__1TFO4 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	        animation: Loading_lds-roller__1TFO4 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	-webkit-transform-origin: 40px 40px;
	        transform-origin: 40px 40px;
}
.Loading_lds-roller__1TFO4 div:after {
	content: ' ';
	display: block;
	position: absolute;
	width: 7px;
	height: 7px;
	border-radius: 50%;
	background: #fff;
	margin: -4px 0 0 -4px;
}
.Loading_lds-roller__1TFO4 div:nth-child(1) {
	-webkit-animation-delay: -0.036s;
	        animation-delay: -0.036s;
}
.Loading_lds-roller__1TFO4 div:nth-child(1):after {
	top: 63px;
	left: 63px;
}
.Loading_lds-roller__1TFO4 div:nth-child(2) {
	-webkit-animation-delay: -0.072s;
	        animation-delay: -0.072s;
}
.Loading_lds-roller__1TFO4 div:nth-child(2):after {
	top: 68px;
	left: 56px;
}
.Loading_lds-roller__1TFO4 div:nth-child(3) {
	-webkit-animation-delay: -0.108s;
	        animation-delay: -0.108s;
}
.Loading_lds-roller__1TFO4 div:nth-child(3):after {
	top: 71px;
	left: 48px;
}
.Loading_lds-roller__1TFO4 div:nth-child(4) {
	-webkit-animation-delay: -0.144s;
	        animation-delay: -0.144s;
}
.Loading_lds-roller__1TFO4 div:nth-child(4):after {
	top: 72px;
	left: 40px;
}
.Loading_lds-roller__1TFO4 div:nth-child(5) {
	-webkit-animation-delay: -0.18s;
	        animation-delay: -0.18s;
}
.Loading_lds-roller__1TFO4 div:nth-child(5):after {
	top: 71px;
	left: 32px;
}
.Loading_lds-roller__1TFO4 div:nth-child(6) {
	-webkit-animation-delay: -0.216s;
	        animation-delay: -0.216s;
}
.Loading_lds-roller__1TFO4 div:nth-child(6):after {
	top: 68px;
	left: 24px;
}
.Loading_lds-roller__1TFO4 div:nth-child(7) {
	-webkit-animation-delay: -0.252s;
	        animation-delay: -0.252s;
}
.Loading_lds-roller__1TFO4 div:nth-child(7):after {
	top: 63px;
	left: 17px;
}
.Loading_lds-roller__1TFO4 div:nth-child(8) {
	-webkit-animation-delay: -0.288s;
	        animation-delay: -0.288s;
}
.Loading_lds-roller__1TFO4 div:nth-child(8):after {
	top: 56px;
	left: 12px;
}
@-webkit-keyframes Loading_lds-roller__1TFO4 {
	0% {
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		        transform: rotate(360deg);
	}
}
@keyframes Loading_lds-roller__1TFO4 {
	0% {
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		        transform: rotate(360deg);
	}
}
.Loading_lds-default__piDTr {
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
}
.Loading_lds-default__piDTr div {
	position: absolute;
	width: 6px;
	height: 6px;
	background: #fff;
	border-radius: 50%;
	-webkit-animation: Loading_lds-default__piDTr 1.2s linear infinite;
	        animation: Loading_lds-default__piDTr 1.2s linear infinite;
}
.Loading_lds-default__piDTr div:nth-child(1) {
	-webkit-animation-delay: 0s;
	        animation-delay: 0s;
	top: 37px;
	left: 66px;
}
.Loading_lds-default__piDTr div:nth-child(2) {
	-webkit-animation-delay: -0.1s;
	        animation-delay: -0.1s;
	top: 22px;
	left: 62px;
}
.Loading_lds-default__piDTr div:nth-child(3) {
	-webkit-animation-delay: -0.2s;
	        animation-delay: -0.2s;
	top: 11px;
	left: 52px;
}
.Loading_lds-default__piDTr div:nth-child(4) {
	-webkit-animation-delay: -0.3s;
	        animation-delay: -0.3s;
	top: 7px;
	left: 37px;
}
.Loading_lds-default__piDTr div:nth-child(5) {
	-webkit-animation-delay: -0.4s;
	        animation-delay: -0.4s;
	top: 11px;
	left: 22px;
}
.Loading_lds-default__piDTr div:nth-child(6) {
	-webkit-animation-delay: -0.5s;
	        animation-delay: -0.5s;
	top: 22px;
	left: 11px;
}
.Loading_lds-default__piDTr div:nth-child(7) {
	-webkit-animation-delay: -0.6s;
	        animation-delay: -0.6s;
	top: 37px;
	left: 7px;
}
.Loading_lds-default__piDTr div:nth-child(8) {
	-webkit-animation-delay: -0.7s;
	        animation-delay: -0.7s;
	top: 52px;
	left: 11px;
}
.Loading_lds-default__piDTr div:nth-child(9) {
	-webkit-animation-delay: -0.8s;
	        animation-delay: -0.8s;
	top: 62px;
	left: 22px;
}
.Loading_lds-default__piDTr div:nth-child(10) {
	-webkit-animation-delay: -0.9s;
	        animation-delay: -0.9s;
	top: 66px;
	left: 37px;
}
.Loading_lds-default__piDTr div:nth-child(11) {
	-webkit-animation-delay: -1s;
	        animation-delay: -1s;
	top: 62px;
	left: 52px;
}
.Loading_lds-default__piDTr div:nth-child(12) {
	-webkit-animation-delay: -1.1s;
	        animation-delay: -1.1s;
	top: 52px;
	left: 62px;
}
@-webkit-keyframes Loading_lds-default__piDTr {
	0%,
	20%,
	80%,
	100% {
		-webkit-transform: scale(1);
		        transform: scale(1);
	}
	50% {
		-webkit-transform: scale(1.5);
		        transform: scale(1.5);
	}
}
@keyframes Loading_lds-default__piDTr {
	0%,
	20%,
	80%,
	100% {
		-webkit-transform: scale(1);
		        transform: scale(1);
	}
	50% {
		-webkit-transform: scale(1.5);
		        transform: scale(1.5);
	}
}

.Loading_lds-grid__3OiLi {
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
}
.Loading_lds-grid__3OiLi div {
	position: absolute;
	width: 16px;
	height: 16px;
	border-radius: 50%;
	background: #fff;
	-webkit-animation: Loading_lds-grid__3OiLi 1.2s linear infinite;
	        animation: Loading_lds-grid__3OiLi 1.2s linear infinite;
}
.Loading_lds-grid__3OiLi div:nth-child(1) {
	top: 8px;
	left: 8px;
	-webkit-animation-delay: 0s;
	        animation-delay: 0s;
}
.Loading_lds-grid__3OiLi div:nth-child(2) {
	top: 8px;
	left: 32px;
	-webkit-animation-delay: -0.4s;
	        animation-delay: -0.4s;
}
.Loading_lds-grid__3OiLi div:nth-child(3) {
	top: 8px;
	left: 56px;
	-webkit-animation-delay: -0.8s;
	        animation-delay: -0.8s;
}
.Loading_lds-grid__3OiLi div:nth-child(4) {
	top: 32px;
	left: 8px;
	-webkit-animation-delay: -0.4s;
	        animation-delay: -0.4s;
}
.Loading_lds-grid__3OiLi div:nth-child(5) {
	top: 32px;
	left: 32px;
	-webkit-animation-delay: -0.8s;
	        animation-delay: -0.8s;
}
.Loading_lds-grid__3OiLi div:nth-child(6) {
	top: 32px;
	left: 56px;
	-webkit-animation-delay: -1.2s;
	        animation-delay: -1.2s;
}
.Loading_lds-grid__3OiLi div:nth-child(7) {
	top: 56px;
	left: 8px;
	-webkit-animation-delay: -0.8s;
	        animation-delay: -0.8s;
}
.Loading_lds-grid__3OiLi div:nth-child(8) {
	top: 56px;
	left: 32px;
	-webkit-animation-delay: -1.2s;
	        animation-delay: -1.2s;
}
.Loading_lds-grid__3OiLi div:nth-child(9) {
	top: 56px;
	left: 56px;
	-webkit-animation-delay: -1.6s;
	        animation-delay: -1.6s;
}
@-webkit-keyframes Loading_lds-grid__3OiLi {
	0%,
	100% {
		opacity: 1;
	}
	50% {
		opacity: 0.5;
	}
}
@keyframes Loading_lds-grid__3OiLi {
	0%,
	100% {
		opacity: 1;
	}
	50% {
		opacity: 0.5;
	}
}

.Loading_lds-hourglass__3YvJo {
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
}
.Loading_lds-hourglass__3YvJo:after {
	content: ' ';
	display: block;
	border-radius: 50%;
	width: 0;
	height: 0;
	margin: 8px;
	box-sizing: border-box;
	border: 32px solid #fff;
	border-color: #fff transparent #fff transparent;
	-webkit-animation: Loading_lds-hourglass__3YvJo 1.2s infinite;
	        animation: Loading_lds-hourglass__3YvJo 1.2s infinite;
}
@-webkit-keyframes Loading_lds-hourglass__3YvJo {
	0% {
		-webkit-transform: rotate(0);
		        transform: rotate(0);
		-webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
		        animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
	}
	50% {
		-webkit-transform: rotate(900deg);
		        transform: rotate(900deg);
		-webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
		        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
	}
	100% {
		-webkit-transform: rotate(1800deg);
		        transform: rotate(1800deg);
	}
}
@keyframes Loading_lds-hourglass__3YvJo {
	0% {
		-webkit-transform: rotate(0);
		        transform: rotate(0);
		-webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
		        animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
	}
	50% {
		-webkit-transform: rotate(900deg);
		        transform: rotate(900deg);
		-webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
		        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
	}
	100% {
		-webkit-transform: rotate(1800deg);
		        transform: rotate(1800deg);
	}
}

.Loading_lds-spinner__2VSKJ {
	color: official;
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
}
.Loading_lds-spinner__2VSKJ div {
	-webkit-transform-origin: 40px 40px;
	        transform-origin: 40px 40px;
	-webkit-animation: Loading_lds-spinner__2VSKJ 1.2s linear infinite;
	        animation: Loading_lds-spinner__2VSKJ 1.2s linear infinite;
}
.Loading_lds-spinner__2VSKJ div:after {
	content: ' ';
	display: block;
	position: absolute;
	top: 3px;
	left: 37px;
	width: 6px;
	height: 18px;
	border-radius: 20%;
	background: #fff;
}
.Loading_lds-spinner__2VSKJ div:nth-child(1) {
	-webkit-transform: rotate(0deg);
	        transform: rotate(0deg);
	-webkit-animation-delay: -1.1s;
	        animation-delay: -1.1s;
}
.Loading_lds-spinner__2VSKJ div:nth-child(2) {
	-webkit-transform: rotate(30deg);
	        transform: rotate(30deg);
	-webkit-animation-delay: -1s;
	        animation-delay: -1s;
}
.Loading_lds-spinner__2VSKJ div:nth-child(3) {
	-webkit-transform: rotate(60deg);
	        transform: rotate(60deg);
	-webkit-animation-delay: -0.9s;
	        animation-delay: -0.9s;
}
.Loading_lds-spinner__2VSKJ div:nth-child(4) {
	-webkit-transform: rotate(90deg);
	        transform: rotate(90deg);
	-webkit-animation-delay: -0.8s;
	        animation-delay: -0.8s;
}
.Loading_lds-spinner__2VSKJ div:nth-child(5) {
	-webkit-transform: rotate(120deg);
	        transform: rotate(120deg);
	-webkit-animation-delay: -0.7s;
	        animation-delay: -0.7s;
}
.Loading_lds-spinner__2VSKJ div:nth-child(6) {
	-webkit-transform: rotate(150deg);
	        transform: rotate(150deg);
	-webkit-animation-delay: -0.6s;
	        animation-delay: -0.6s;
}
.Loading_lds-spinner__2VSKJ div:nth-child(7) {
	-webkit-transform: rotate(180deg);
	        transform: rotate(180deg);
	-webkit-animation-delay: -0.5s;
	        animation-delay: -0.5s;
}
.Loading_lds-spinner__2VSKJ div:nth-child(8) {
	-webkit-transform: rotate(210deg);
	        transform: rotate(210deg);
	-webkit-animation-delay: -0.4s;
	        animation-delay: -0.4s;
}
.Loading_lds-spinner__2VSKJ div:nth-child(9) {
	-webkit-transform: rotate(240deg);
	        transform: rotate(240deg);
	-webkit-animation-delay: -0.3s;
	        animation-delay: -0.3s;
}
.Loading_lds-spinner__2VSKJ div:nth-child(10) {
	-webkit-transform: rotate(270deg);
	        transform: rotate(270deg);
	-webkit-animation-delay: -0.2s;
	        animation-delay: -0.2s;
}
.Loading_lds-spinner__2VSKJ div:nth-child(11) {
	-webkit-transform: rotate(300deg);
	        transform: rotate(300deg);
	-webkit-animation-delay: -0.1s;
	        animation-delay: -0.1s;
}
.Loading_lds-spinner__2VSKJ div:nth-child(12) {
	-webkit-transform: rotate(330deg);
	        transform: rotate(330deg);
	-webkit-animation-delay: 0s;
	        animation-delay: 0s;
}
@-webkit-keyframes Loading_lds-spinner__2VSKJ {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
@keyframes Loading_lds-spinner__2VSKJ {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

.Navbar_navbar__gfN6T {
	position: absolute;
	display: flex;
	flex-direction: column;

	width: 100vw;
	font-size: 16px;
	z-index: 250;

	/*-webkit-box-shadow: 0px 4px 5px -3px rgba(0, 0, 0, 0.35);
    -moz-box-shadow: 0px 4px 5px -3px rgba(0, 0, 0, 0.35);
    box-shadow: 0px 4px 5px -3px rgba(0, 0, 0, 0.35);*/

	/*0 1px 2px rgba(0,0,0,.18),0 1px 1px rgba(0,0,0,.03)!important*/
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.18), 0 1px 1px rgba(0, 0, 0, 0.03) !important;

	transition: height 600ms ease-out;
	transition: margin 400ms ease-in;

	-webkit-user-select: none;
	user-select: none;
}

.Navbar_navbar__gfN6T > :first-child {
	align-self: initial;
	display: flex;

	/*justify-content:space-between;*/

	/*FIXME This change is only for 5thwave*/
	justify-content: center;
	position: relative;
}

.Navbar_navbar__gfN6T > .Navbar_toc__3jZjP {
	width: 100vw;

	height: 0;
	transition: height 400ms ease-out;
	overflow: auto;

	-webkit-user-select: none;
	user-select: none;
}

.Navbar_navbar__gfN6T > .Navbar_toc__3jZjP.Navbar_open__3wg_q {
	height: calc(100vh - var(--navbar-height));
	transition: height 400ms ease-in;
	list-style-position: inside;
}

.Navbar_navbar__gfN6T > .Navbar_toc__3jZjP > li {
	cursor: pointer;
	margin: 0 auto;
	width: 95%;
	text-align: center;
	height: 56px;
	align-items: center;
	text-transform: uppercase;
	border-top: 1px solid rgba(0, 0, 0, 0.1);
	display: list-item;
	padding-top: 1em;
}

.Navbar_navbar__gfN6T > .Navbar_toc__3jZjP > * > * {
	display: table;
	height: 100%;
}

.Navbar_navbar__gfN6T.Navbar_not-display__3MfKz {
	margin-top: calc(var(--navbar-height) * -1);
}

.Navbar_navbar-left__3nbrh {
	display: flex;
	flex-direction: row;
	padding-left: 10px;
	height: 100%;
	text-align: left;
	align-items: center;
	position: absolute;
}

.Navbar_navbar-title__3QyqE {
	text-align: center;
	align-self: center;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.Navbar_navbar-logo__1cAzI {
	text-align: center;
	align-self: center;
	-webkit-user-select: none;
	user-select: none;
}

.Navbar_navbar-logo__1cAzI > * > img {
	cursor: pointer;
}

.Navbar_navbar-right__2yaf0 {
	display: flex;
	flex-direction: row;
	padding-right: 10px;
	text-align: right;
	align-self: center;
	right: 0;
	height: 30px;
}

.Navbar_navbar-right__2yaf0 > span {
	margin-left: 6px;
}

.Navbar_navbar-right__2yaf0 > span > img {
	padding: 5px;
}

.Navbar_language-container__2Gb5r {
	width: 100vw;
	height: 100vh;
	z-index: 999999999;
	background: rgba(0, 0, 0, 0.5);
	position: fixed;

	display: flex;
	align-items: center;
	justify-content: center;
}

.Navbar_language-container__2Gb5r > .Navbar_language-modal__GbGiA {
	width: 90vw;
	height: 70vh;
	max-width: 500px;
	max-height: 70vw;
	background: #fff;
	border-radius: 16px;
	display: flex;
	flex-direction: column;
	overflow: hidden;
}

.Navbar_language-modal__GbGiA > .Navbar_header__3D21M {
	width: 100%;
	height: 56px;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-template-rows: 1fr;
	grid-gap: 0px 0px;
	gap: 0px 0px;
	grid-template-areas: '. . .';
	border-bottom: 1px solid #c4c4c4;
}

.Navbar_language-modal__GbGiA > .Navbar_header__3D21M > .Navbar_title__2Xa5Q {
	display: flex;
	justify-content: center;
	align-items: center;
}

.Navbar_language-modal__GbGiA > .Navbar_header__3D21M > .Navbar_close__2UIMW {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.Navbar_language-modal__GbGiA > .Navbar_header__3D21M > .Navbar_close__2UIMW > button {
	-webkit-mask: url(/static/media/icon-close.53c9f4b6.svg);
	        mask: url(/static/media/icon-close.53c9f4b6.svg);
	margin: auto;
	height: 24px;
	width: 24px;
	margin-right: 15%;
}

.Navbar_language-modal__GbGiA > .Navbar_content__1uD4A {
	width: 100%;
	height: 100%;
	overflow-x: hidden;
	overflow-y: auto;
	background: #fafafa;

	display: flex;
	flex-direction: column;
}

.Navbar_language-modal__GbGiA > .Navbar_content__1uD4A > ul {
	list-style-type: none;
	width: 100%;
	padding: 0px;
}

.Navbar_language-modal__GbGiA > .Navbar_content__1uD4A > ul > :first-child {
	border-top: 1px solid rgb(227, 227, 227);
}

.Navbar_language-modal__GbGiA > .Navbar_content__1uD4A > ul > li {
	width: 100%;
	background: #fff;
	border-bottom: 1px solid rgb(227, 227, 227);
	height: 42px;
	display: flex;
	padding-left: 20px;
	padding-right: 20px;
	cursor: pointer;
	align-items: center;
	justify-content: space-between;
}

.Navbar_icon-offline__2iyhF {
	-webkit-mask: url(/static/media/icon-offline.f11564a5.svg);
	        mask: url(/static/media/icon-offline.f11564a5.svg);
	margin: auto;
	height: 24px;
	width: 24px;
}

.Navbar_icon-check__pPxEI {
	-webkit-mask: url(/static/media/icon-check.3202d9db.svg);
	        mask: url(/static/media/icon-check.3202d9db.svg);
	margin: auto;
	height: 24px;
	width: 24px;
}

.Navbar_icon-language__3XP2a {
	-webkit-mask: url(/static/media/icon-language.e63c86bb.svg);
	        mask: url(/static/media/icon-language.e63c86bb.svg);
	margin: auto;
	height: 24px;
	width: 24px;
}

.Navbar_icon-menu__3ViS4 {
	display: none;
	-webkit-mask: url(/static/media/icon-menu.2a903b08.svg);
	        mask: url(/static/media/icon-menu.2a903b08.svg);
	margin: auto;
	height: 24px;
	width: 24px;
}

.Navbar_icon-menu__3ViS4.Navbar_selected__1at3M {
	-webkit-mask: url(/static/media/icon-menu-selected.a08b4366.svg);
	        mask: url(/static/media/icon-menu-selected.a08b4366.svg);
}

.Navbar_icon-player__3qXGG {
	-webkit-mask: url(/static/media/icon-player.6fd9f684.svg);
	        mask: url(/static/media/icon-player.6fd9f684.svg);
	margin: auto;
	height: 24px;
	width: 24px;
}

.Navbar_icon-player__3qXGG.Navbar_selected__1at3M {
	-webkit-mask: url(/static/media/icon-player-selected.3581efba.svg);
	        mask: url(/static/media/icon-player-selected.3581efba.svg);
}

.Navbar_icon-download__HoCn9 {
	-webkit-mask: url(/static/media/icon-download.a495c88f.svg);
	        mask: url(/static/media/icon-download.a495c88f.svg);
	margin: auto;
	height: 30px;
	-webkit-mask-repeat: no-repeat;
	mask-repeat: no-repeat;
	-webkit-mask-position: center;
	mask-position: center;

	width: 30px;
}
.Navbar_icon-download__HoCn9.Navbar_selected__1at3M {
	-webkit-mask: url(/static/media/icon-download-selected.e2d32798.svg);
	        mask: url(/static/media/icon-download-selected.e2d32798.svg);
	margin: auto;
	height: 30px;
	-webkit-mask-repeat: no-repeat;
	mask-repeat: no-repeat;
	-webkit-mask-position: center;
	mask-position: center;

	width: 30px;
}

.Navbar_nav-bar-icon__WFLz2 {
	width: 50px;
	margin: auto;
	padding: 6px;
	-webkit-mask-size: cover;
	mask-size: cover;
	display: inline-block;
}

.Navbar_nav-bar-icon__WFLz2.Navbar_icon-back__259Oq > button {
	-webkit-mask: url(/static/media/arrow-left.74e104f0.svg);
	        mask: url(/static/media/arrow-left.74e104f0.svg);
	margin: auto;
	height: 24px;
	width: 24px;
}

.Navbar_icon-play__3Krhl > button {
	-webkit-mask: url(/static/media/icon-play.f303159b.svg);
	        mask: url(/static/media/icon-play.f303159b.svg);
	margin: auto;
	height: 24px;
	width: 24px;
}

.Navbar_setting-options__q_7b7 {
	min-height: var(--navbar-height);
	display: flex;
}

@media screen and (max-width: 767px) {
	.Navbar_setting-options__q_7b7 {
		min-height: 0;
		display: none;
	}
	.Navbar_icon-menu__3ViS4 {
		display: initial;
	}
}

.Navbar_setting-options__q_7b7 > div {
	min-height: var(--navbar-height);
	width: var(--navbar-height);
	-webkit-mask-size: cover;
	mask-size: cover;
	display: flex;
	justify-content: center;
	align-items: center;
}

.Navbar_share-button__QQWaH {
	-webkit-mask: url(/static/media/icon-share.50a1309b.svg);
	        mask: url(/static/media/icon-share.50a1309b.svg);
	margin: auto;
	height: 24px;
	width: 24px;
}

.Navbar_reader-mode-button__362Zh {
	-webkit-mask: url(/static/media/icon-reader-mode.e7d76ffb.svg);
	        mask: url(/static/media/icon-reader-mode.e7d76ffb.svg);
	margin: auto;
	height: 24px;
	width: 24px;
}
.Navbar_reader-mode-button__362Zh.Navbar_selected__1at3M {
	-webkit-mask: url(/static/media/icon-reader-mode-selected.94859d88.svg);
	        mask: url(/static/media/icon-reader-mode-selected.94859d88.svg);
}

.Navbar_image-mode-button__30jp_ {
	-webkit-mask: url(/static/media/icon-image-mode.fd3fb891.svg);
	        mask: url(/static/media/icon-image-mode.fd3fb891.svg);
	margin: auto;
	height: 24px;
	width: 24px;
}
.Navbar_image-mode-button__30jp_.Navbar_selected__1at3M {
	-webkit-mask: url(/static/media/icon-image-mode-selected.87c34a54.svg);
	        mask: url(/static/media/icon-image-mode-selected.87c34a54.svg);
}

.Navbar_bookmark-button__3vUWQ {
	-webkit-mask: url(/static/media/icon-bookmark.857b1589.svg);
	        mask: url(/static/media/icon-bookmark.857b1589.svg);
	margin: auto;
	height: 24px;
	width: 24px;
}
.Navbar_bookmark-button__3vUWQ.Navbar_selected__1at3M {
	-webkit-mask: url(/static/media/icon-bookmark-selected.ec789b31.svg);
	        mask: url(/static/media/icon-bookmark-selected.ec789b31.svg);
}

.Navbar_font-size-button__1uPtT {
	-webkit-mask: url(/static/media/icon-font.97353b8d.svg);
	        mask: url(/static/media/icon-font.97353b8d.svg);
	margin: auto;
	height: 24px;
	width: 24px;
}

.Navbar_font-size-button__1uPtT.Navbar_selected__1at3M {
	-webkit-mask: url(/static/media/icon-font-selected.6c54a040.svg);
	        mask: url(/static/media/icon-font-selected.6c54a040.svg);
}

.Navbar_playbar__3IdXo {
	position: absolute;
	display: flex;
	flex-direction: row;
	margin-top: var(--navbar-height);
	min-height: var(--navbar-height);

	width: 100vw;
	font-size: 16px;
	z-index: 250;
	border-top: 1px solid black;

	background-color: black;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.18), 0 1px 1px rgba(0, 0, 0, 0.03) !important;
}

.Navbar_playbar__3IdXo > div {
	height: var(--navbar-height);
	display: flex;
	justify-content: center;
	align-items: center;
}

.Navbar_playbar__3IdXo > :first-child {
	width: 20%;
}

.Navbar_playbar__3IdXo > :nth-child(2) {
	width: 60%;
	/*background: green;*/
}

.Navbar_playbar__3IdXo > :last-child {
	width: 20%;
}

.Search_sm-search-container__MLtOh {
	width: 0px;
	display: none;
}

.Search_sm-search-box__xvksv {
	height: 0px;
	width: 100%;
	display: none;
	align-items: center;
}

.Search_sm-search-box__xvksv > input {
	width: 100%;
	height: 100%;
	background: rgba(250, 250, 250, 1);
	border-radius: 4px;
	padding-left: 32px;
	outline: none;
	box-shadow: none;
	font-size: 0.9rem;
	color: #3d3d3d;
	border: 1px solid rgba(219, 219, 219, 1);
}

.Search_sm-search-box__xvksv > input::-webkit-input-placeholder {
	color: #c4c4c4;
	font-size: 0.9rem;
}

.Search_sm-search-box__xvksv > input::placeholder {
	color: #c4c4c4;
	font-size: 0.9rem;
}

.Search_search-icon__1YRlO {
	-webkit-mask: url(/static/media/icon-search.bc2178ae.svg);
	        mask: url(/static/media/icon-search.bc2178ae.svg);
	-webkit-mask-repeat: no-repeat;
	        mask-repeat: no-repeat;
	-webkit-mask-position: center;
	        mask-position: center;
	-webkit-mask-size: 120%;
	        mask-size: 120%;
	background: #c4c4c4;
	position: absolute;
	margin-left: 8px;
	height: 16px;
	width: 16px;
}

.Search_clear-icon__NiKDJ {
	-webkit-mask: url(/static/media/icon-close.53c9f4b6.svg);
	        mask: url(/static/media/icon-close.53c9f4b6.svg);
	-webkit-mask-repeat: no-repeat;
	        mask-repeat: no-repeat;
	-webkit-mask-position: center;
	        mask-position: center;
	-webkit-mask-size: 120%;
	        mask-size: 120%;
	background: #c4c4c4;
	position: absolute;
	margin-right: 18px;
	height: 16px;
	width: 16px;
	right: 0;
}

.Search_spinner__23BQh {
	display: inline-block;
	position: absolute;
	width: 16px;
	height: 16px;
	border: 1px solid #c4c4c4;
	border-radius: 50%;
	border-top-color: #3d3d3d;
	animation: Search_spin__22Pjw 1s ease-in-out infinite;
	-webkit-animation: Search_spin__22Pjw 1s ease-in-out infinite;
	margin-right: 18px;
	right: 0;
  }

.Search_sm-result-list__SGly5 {
	max-height: 400px;
	overflow-y: auto;
	width: 100%;
	margin-top: 14px;
	background: #fff;
	border: 1px solid #c3c3c3;
	border-radius: 4px;
	display: flex;
	flex-direction: column;
}

.Search_sm-result-not-found__1mpFG {
	width: 100%;
	min-height: 40px;
	max-height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-style: italic;
	color: #c3c3c3;
	font-size: 0.9rem;
}

.Search_sm-result-item__2-yii {
	width: 100%;
	min-height: 60px;
	max-height: 60px;
	border-bottom: 1px solid #c3c3c3;
	display: grid;
	grid-template-columns: 0.45fr 1.55fr;
	grid-template-rows: 1fr;
	grid-gap: 0px 0px;
	gap: 0px 0px;
	grid-template-areas: '. .';
}

.Search_sm-result-item__2-yii:last-of-type {
	border-bottom: 0px;
}

.Search_sm-result-item__2-yii > .Search_image__fmDZ7 {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.Search_sm-result-item__2-yii > .Search_image__fmDZ7 > img {
	height: 42px;
	width: 42px;
	border-radius: 4px;
	object-fit: cover;
}

/*.sm-result-item > .content {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr 1fr;
	gap: 0px 0px;
	grid-template-areas:
		'.'
		'.';
}*/

.Search_sm-result-item__2-yii > .Search_content__18NSF {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.Search_sm-result-item__2-yii > .Search_content__18NSF > * {
	margin: 0;
}

.Search_sm-result-item__2-yii > .Search_content__18NSF h3 {
	font-size: 0.85rem;
	width: 200px;
	text-align: left;
	overflow: hidden;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.Search_sm-result-item__2-yii > .Search_content__18NSF p {
	margin-top: 4px;
	font-size: 0.65rem;
	color: #3d3d3d;
	
	width: 200px;
	text-align: left;
	overflow: hidden;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.Search_sm-result-item__2-yii > .Search_content__18NSF small {
	margin-top: 4px;
	display: block;
	font-size: 0.6rem;
	color: #c4c4c4;
	font-style: italic;
	text-align: left;
	margin-bottom: 4px;
}

@media screen and (min-width: 1024px) {
	.Search_sm-search-container__MLtOh {
		width: 50px;
		transition: width ease-out 220ms;
	}
	.Search_sm-search-container__MLtOh.Search_close__1_ind button{
		width: 32px;
		height: 32px;
		border: none;
		margin: auto;
		background: none;
		outline: none;
	}

	.Search_sm-search-box__xvksv {
		display: flex;
		height: 100%;
	}

	.Search_sm-search-container__MLtOh.Search_open__tF3sp {
		width: 300px;
	}
}

@keyframes Search_spin__22Pjw {
	to {
		-webkit-transform: rotate(360deg);
	}
}
@-webkit-keyframes Search_spin__22Pjw {
	to {
		-webkit-transform: rotate(360deg);
	}
}

.Icon_apple-icon__3_TOK {
	-webkit-mask: url(/static/media/apple-icon.4e342371.svg);
	        mask: url(/static/media/apple-icon.4e342371.svg);
}

.Icon_arrow-up__1LwFu {
	-webkit-mask: url(/static/media/arrow-up.018f82c5.svg);
	        mask: url(/static/media/arrow-up.018f82c5.svg);
}

.Icon_arrow-down__3h-er {
	-webkit-mask: url(/static/media/arrow-down.7bdcd639.svg);
	        mask: url(/static/media/arrow-down.7bdcd639.svg);
}

.Icon_arrow-right__2ARLC {
	-webkit-mask: url(/static/media/arrow-right.e1da7cbe.svg);
	        mask: url(/static/media/arrow-right.e1da7cbe.svg);
}

.Icon_arrow-left___GVfw {
	-webkit-mask: url(/static/media/arrow-left.842e761b.svg);
	        mask: url(/static/media/arrow-left.842e761b.svg);
}

.Icon_bookmark__13Vze {
	-webkit-mask: url(/static/media/bookmark.857b1589.svg);
	        mask: url(/static/media/bookmark.857b1589.svg);
}

.Icon_bookmark-selected__2Kbu- {
	-webkit-mask: url(/static/media/bookmark-selected.ec789b31.svg);
	        mask: url(/static/media/bookmark-selected.ec789b31.svg);
}

.Icon_bookmarks__2551m {
	-webkit-mask: url(/static/media/bookmarks.06fc4612.svg);
	        mask: url(/static/media/bookmarks.06fc4612.svg);
}

.Icon_done__uP9KP {
	-webkit-mask: url(/static/media/done.08cbfb78.svg);
	        mask: url(/static/media/done.08cbfb78.svg);
}

.Icon_email__2mWZ4 {
	-webkit-mask: url(/static/media/email.eee1baa7.svg);
	        mask: url(/static/media/email.eee1baa7.svg);
}

.Icon_font__2Qe2Y {
	-webkit-mask: url(/static/media/font.97353b8d.svg);
	        mask: url(/static/media/font.97353b8d.svg);
}

.Icon_font-selected__3yjod {
	-webkit-mask: url(/static/media/font-selected.6c54a040.svg);
	        mask: url(/static/media/font-selected.6c54a040.svg);
}

.Icon_font-increase__1RJg3 {
	-webkit-mask: url(/static/media/font-increase.9d647265.svg);
	        mask: url(/static/media/font-increase.9d647265.svg);
}

.Icon_font-decrease__3sr2E {
	-webkit-mask: url(/static/media/font-decrease.98fb8cbd.svg);
	        mask: url(/static/media/font-decrease.98fb8cbd.svg);
}

.Icon_toc__36YSD {
	-webkit-mask: url(/static/media/toc.a8224ff1.svg);
	        mask: url(/static/media/toc.a8224ff1.svg);
}

.Icon_horizontal-split__1tHvc {
	-webkit-mask: url(/static/media/horizontal-split.934d1bad.svg);
	        mask: url(/static/media/horizontal-split.934d1bad.svg);
}

.Icon_download__uWQ0i {
	-webkit-mask: url(/static/media/download.a3498030.svg);
	        mask: url(/static/media/download.a3498030.svg);
}

.Icon_downloaded__1TOve {
	-webkit-mask: url(/static/media/downloaded.1f4e6b8a.svg);
	        mask: url(/static/media/downloaded.1f4e6b8a.svg);
}

.Icon_search__2lk_3 {
	-webkit-mask: url(/static/media/search.d3bc4be3.svg);
	        mask: url(/static/media/search.d3bc4be3.svg);
}

.Icon_search-alt__2D85v {
	-webkit-mask: url(/static/media/search-alt.0ffaf6c2.svg);
	        mask: url(/static/media/search-alt.0ffaf6c2.svg);
}

.Icon_cloud-off__y2RaP {
	-webkit-mask: url(/static/media/cloud-off.8903183b.svg);
	        mask: url(/static/media/cloud-off.8903183b.svg);
}

.Icon_home__2HdiT {
	-webkit-mask: url(/static/media/home.4070b609.svg);
	        mask: url(/static/media/home.4070b609.svg);
}

.Icon_home-selected__3B7VQ {
	-webkit-mask: url(/static/media/home-selected.66b71da2.svg);
	        mask: url(/static/media/home-selected.66b71da2.svg);
}

.Icon_player__1fqHN {
	-webkit-mask: url(/static/media/player.6fd9f684.svg);
	        mask: url(/static/media/player.6fd9f684.svg);
}

.Icon_article__3qRVD {
	-webkit-mask: url(/static/media/article.12440c92.svg);
	        mask: url(/static/media/article.12440c92.svg);
}

.Icon_share__3Hjzt {
	-webkit-mask: url(/static/media/share.32f1d748.svg);
	        mask: url(/static/media/share.32f1d748.svg);
}

.Icon_play-arrow__4WBsZ {
	-webkit-mask: url(/static/media/play-arrow.222a0afb.svg);
	        mask: url(/static/media/play-arrow.222a0afb.svg);
}

.Icon_pause__A-htI {
	-webkit-mask: url(/static/media/pause.b52642a6.svg);
	        mask: url(/static/media/pause.b52642a6.svg);
}

.Icon_forward-5__3KVjv {
	-webkit-mask: url(/static/media/forward-5.328aa8a9.svg);
	        mask: url(/static/media/forward-5.328aa8a9.svg);
}

.Icon_replay-5__3B7N- {
	-webkit-mask: url(/static/media/replay-5.5a2b4882.svg);
	        mask: url(/static/media/replay-5.5a2b4882.svg);
}

.Icon_cancel__ogqeh {
	-webkit-mask: url(/static/media/cancel.f4921acd.svg);
	        mask: url(/static/media/cancel.f4921acd.svg);
}

.Icon_chevron-left__2GOYU {
	-webkit-mask: url(/static/media/chevron-left.c21db314.svg);
	        mask: url(/static/media/chevron-left.c21db314.svg);
}

.Icon_chevron-right__qIUTu {
	-webkit-mask: url(/static/media/chevron-right.e9dec063.svg);
	        mask: url(/static/media/chevron-right.e9dec063.svg);
}

.Icon_close__uXk1T {
	-webkit-mask: url(/static/media/close.244b6687.svg);
	        mask: url(/static/media/close.244b6687.svg);
}

.Icon_cloud__2zSmJ {
	-webkit-mask: url(/static/media/cloud.d61e7b67.svg);
	        mask: url(/static/media/cloud.d61e7b67.svg);
}

.Icon_cloud-queue__IO_QT {
	-webkit-mask: url(/static/media/cloud-queue.f3d58761.svg);
	        mask: url(/static/media/cloud-queue.f3d58761.svg);
}

.Icon_add-link__1qxHr {
	-webkit-mask: url(/static/media/add-link.43b31dd0.svg);
	        mask: url(/static/media/add-link.43b31dd0.svg);
}

.Icon_attach-email__2i6-U {
	-webkit-mask: url(/static/media/attach-email.03965d57.svg);
	        mask: url(/static/media/attach-email.03965d57.svg);
}

.Icon_pending__30z-W {
	-webkit-mask: url(/static/media/pending.de6c323e.svg);
	        mask: url(/static/media/pending.de6c323e.svg);
}

.Icon_cloud-done__HjVrT {
	-webkit-mask: url(/static/media/cloud-done.81c11575.svg);
	        mask: url(/static/media/cloud-done.81c11575.svg);
}

.Icon_single-page-spread__3e4B1 {
	-webkit-mask: url(/static/media/single-page-spread.cb094fae.svg);
	        mask: url(/static/media/single-page-spread.cb094fae.svg);
}

.Icon_double-page-spread__256Lw {
	-webkit-mask: url(/static/media/double-page-spread.67a128a7.svg);
	        mask: url(/static/media/double-page-spread.67a128a7.svg);
}

.Icon_responsive-mode__2ag1R {
	-webkit-mask: url(/static/media/responsive-mode.c98e345c.svg);
	        mask: url(/static/media/responsive-mode.c98e345c.svg);
}

.Icon_replica-mode__2h3TN {
	-webkit-mask: url(/static/media/replica-mode.f5cc0096.svg);
	        mask: url(/static/media/replica-mode.f5cc0096.svg);
}

.Icon_external-link__2TD0y {
	-webkit-mask: url(/static/media/external-link.90577cce.svg);
	        mask: url(/static/media/external-link.90577cce.svg);
}

.Icon_printer__2S4fi {
	-webkit-mask: url(/static/media/printer.64cd3316.svg);
	        mask: url(/static/media/printer.64cd3316.svg);
}

.Icon_zoom-in__2q_SU {
	-webkit-mask: url(/static/media/zoom-in.6608bc98.svg);
	        mask: url(/static/media/zoom-in.6608bc98.svg);
}

.Icon_icon__nBaQ0 {
	width: 100%;
	height: 100%;
	-webkit-mask-position: center;
	mask-position: center;
	-webkit-mask-repeat: no-repeat;
	mask-repeat: no-repeat;
}

.Sidebar_sidebar__3sAiT {
	background: #fff;
	width: var(--bottom-bar-height);
	display: flex;
	flex-direction: column;
	padding-bottom: 20px;
}

.Sidebar_sidebar__3sAiT > div {
	width: 100%;
	height: calc(
		100vh / 2 - var(--bottom-bar-height) / 2 -
			env(safe-area-inset-bottom, var(--safe-area-inset)) / 2
	);
}

.Sidebar_sidebar__3sAiT > :first-child {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
}

.Sidebar_sidebar__3sAiT > :nth-child(2) {
	display: flex;
	flex-direction: column-reverse;
	justify-content: flex-start;
	align-items: center;
	padding-bottom: calc(var(--bottom-bar-height) + 20px);
}

/*@media screen and (min-height: 767px) {
    .sidebar > :nth-child(2) {
        padding-bottom: 0;
    }
}*/

/*Varios hijos de tu class hasta que llegues al select que quieres*/
.Sidebar_your-class__dOyRX > div > div > div {
	border: 1ox;
}

.Sidebar_sidebar-icon__223ev {
	width: 100%;
	margin: auto;
	padding: 6px;
	-webkit-mask-size: cover;
	        mask-size: cover;
	display: flex;
	justify-content: center;
	align-items: center;
}

.Sidebar_sidebar-icon__223ev > * {
	margin: auto;
}

.Sidebar_sidebar-icon__223ev:hover {
	cursor: pointer;
}

.Sidebar_sidebar-icon-container__yO9Yy {
	width: 100%;
	margin-top: 24px;
}

/*ICONS*/
.Sidebar_sidebar-icon__223ev.Sidebar_icon-home__2ZhQ_ > button {
	-webkit-mask: url(/static/media/icon-home.4070b609.svg);
	        mask: url(/static/media/icon-home.4070b609.svg);
	margin: auto;
	height: 24px;
	width: 24px;
	background: #000;
}

.Sidebar_sidebar-icon__223ev.Sidebar_icon-home__2ZhQ_.Sidebar_selected__1cixF > button {
	-webkit-mask: url(/static/media/icon-home-selected.66b71da2.svg);
	        mask: url(/static/media/icon-home-selected.66b71da2.svg);
}

.Sidebar_sidebar-icon__223ev.Sidebar_icon-home__2ZhQ_:hover button {
	background: var(--hover-color);
}

.Sidebar_sidebar-icon__223ev.Sidebar_icon-feed__1ixJ2 > button {
	-webkit-mask: url(/static/media/icon-feed.cad9f7f8.svg);
	        mask: url(/static/media/icon-feed.cad9f7f8.svg);
	margin: auto;
	height: 24px;
	width: 24px;
	background: #000;
}

.Sidebar_sidebar-icon__223ev.Sidebar_icon-feed__1ixJ2.Sidebar_selected__1cixF > button {
	-webkit-mask: url(/static/media/icon-feed-selected.cad9f7f8.svg);
	        mask: url(/static/media/icon-feed-selected.cad9f7f8.svg);
}

.Sidebar_sidebar-icon__223ev.Sidebar_icon-feed__1ixJ2:hover button {
	background: var(--hover-color);
}

.Sidebar_sidebar-icon__223ev.Sidebar_icon-purchases__1V54V > button {
	-webkit-mask: url(/static/media/icon-purchases.29a1a4d4.svg);
	        mask: url(/static/media/icon-purchases.29a1a4d4.svg);
	margin: auto;
	height: 24px;
	width: 24px;
	background: #000;
}
.Sidebar_sidebar-icon__223ev.Sidebar_icon-purchases__1V54V.Sidebar_selected__1cixF > button {
	-webkit-mask: url(/static/media/icon-purchases-selected.4e5e2fa8.svg);
	        mask: url(/static/media/icon-purchases-selected.4e5e2fa8.svg);
}

.Sidebar_sidebar-icon__223ev.Sidebar_icon-purchases__1V54V:hover button {
	background: var(--hover-color);
}

.Sidebar_sidebar-icon__223ev.Sidebar_icon-bookmarks__sFT0J > button {
	-webkit-mask: url(/static/media/icon-bookmarks.06fc4612.svg);
	        mask: url(/static/media/icon-bookmarks.06fc4612.svg);
	margin: auto;
	height: 24px;
	width: 24px;
	background: #000;
}

.Sidebar_sidebar-icon__223ev.Sidebar_icon-bookmarks__sFT0J.Sidebar_selected__1cixF > button {
	-webkit-mask: url(/static/media/icon-bookmarks-selected.6704c082.svg);
	        mask: url(/static/media/icon-bookmarks-selected.6704c082.svg);
}

.Sidebar_sidebar-icon__223ev.Sidebar_icon-bookmarks__sFT0J:hover button {
	background: var(--hover-color);
}

.Sidebar_sidebar-icon__223ev.Sidebar_icon-setting__M2HHO > button {
	-webkit-mask: url(/static/media/icon-setting.5e9f2c57.svg);
	        mask: url(/static/media/icon-setting.5e9f2c57.svg);
	margin: auto;
	height: 24px;
	width: 24px;
	background: #000;
}

.Sidebar_sidebar-icon__223ev.Sidebar_icon-setting__M2HHO.Sidebar_selected__1cixF > button {
	-webkit-mask: url(/static/media/icon-setting-selected.bcc657b9.svg);
	        mask: url(/static/media/icon-setting-selected.bcc657b9.svg);
}

.Sidebar_sidebar-icon__223ev.Sidebar_icon-setting__M2HHO:hover button {
	background: var(--hover-color);
}

.Sidebar_sidebar-icon__223ev.Sidebar_icon-font__ByRZS > button {
	-webkit-mask: url(/static/media/icon-font.97353b8d.svg);
	        mask: url(/static/media/icon-font.97353b8d.svg);
	margin: auto;
	height: 24px;
	width: 24px;
	background: #000;
}

.Sidebar_sidebar-icon__223ev.Sidebar_icon-font__ByRZS:hover button {
	background: var(--hover-color);
}

.Sidebar_sidebar-icon__223ev.Sidebar_icon-font-increase__3vXd7 > button {
	-webkit-mask: url(/static/media/icon-font-increase.9d647265.svg);
	        mask: url(/static/media/icon-font-increase.9d647265.svg);
	margin: auto;
	height: 24px;
	width: 24px;
	background: #000;
}

.Sidebar_sidebar-icon__223ev.Sidebar_icon-font-increase__3vXd7:hover button {
	background: var(--hover-color);
}

.Sidebar_sidebar-icon__223ev.Sidebar_icon-font-decrease__3_LCF > button {
	-webkit-mask: url(/static/media/icon-font-decrease.98fb8cbd.svg);
	        mask: url(/static/media/icon-font-decrease.98fb8cbd.svg);
	margin: auto;
	height: 24px;
	width: 24px;
	background: #000;
}

.Sidebar_sidebar-icon__223ev.Sidebar_icon-font-decrease__3_LCF:hover button {
	background: var(--hover-color);
}

.Sidebar_sidebar-icon__223ev.Sidebar_icon-menu__3rH1h > button {
	-webkit-mask: url(/static/media/icon-menu.2a903b08.svg);
	        mask: url(/static/media/icon-menu.2a903b08.svg);
	margin: auto;
	height: 24px;
	width: 24px;
	background: #000;
}

.Sidebar_sidebar-icon__223ev.Sidebar_icon-menu__3rH1h.Sidebar_selected__1cixF > button {
	-webkit-mask: url(/static/media/icon-menu-selected.a08b4366.svg);
	        mask: url(/static/media/icon-menu-selected.a08b4366.svg);
}

.Sidebar_sidebar-icon__223ev.Sidebar_icon-menu__3rH1h:hover button {
	background: var(--hover-color);
}

.Sidebar_sidebar-icon__223ev.Sidebar_icon-reader__2smjz > button {
	-webkit-mask: url(/static/media/icon-reader-mode.e7d76ffb.svg);
	        mask: url(/static/media/icon-reader-mode.e7d76ffb.svg);
	margin: auto;
	height: 24px;
	width: 24px;
	background: #000;
}

.Sidebar_sidebar-icon__223ev.Sidebar_icon-reader__2smjz.Sidebar_selected__1cixF > button {
	-webkit-mask: url(/static/media/icon-reader-mode-selected.94859d88.svg);
	        mask: url(/static/media/icon-reader-mode-selected.94859d88.svg);
}

.Sidebar_sidebar-icon__223ev.Sidebar_icon-reader__2smjz:hover button {
	background: var(--hover-color);
}

.Sidebar_sidebar-icon__223ev.Sidebar_icon-bookmark__2OHPj > button {
	-webkit-mask: url(/static/media/icon-bookmark.857b1589.svg);
	        mask: url(/static/media/icon-bookmark.857b1589.svg);
	margin: auto;
	height: 24px;
	width: 24px;
	background: #000;
}

.Sidebar_sidebar-icon__223ev.Sidebar_icon-bookmark__2OHPj.Sidebar_selected__1cixF > button {
	-webkit-mask: url(/static/media/icon-bookmark-selected.ec789b31.svg);
	        mask: url(/static/media/icon-bookmark-selected.ec789b31.svg);
}

.Sidebar_sidebar-icon__223ev.Sidebar_icon-bookmark__2OHPj:hover button {
	background: var(--hover-color);
}

.Sidebar_sidebar-icon__223ev.Sidebar_icon-bookmark__2OHPj.Sidebar_selected__1cixF > button {
	-webkit-mask: url(/static/media/icon-bookmark-selected.ec789b31.svg);
	        mask: url(/static/media/icon-bookmark-selected.ec789b31.svg);
}

.Sidebar_sidebar-icon__223ev > button {
	-webkit-mask-repeat: no-repeat !important;
	        mask-repeat: no-repeat !important;
}

@media screen and (min-width: 767px) {
	.Sidebar_sidebar__3sAiT {
		display: flex;
		position: fixed;
		left: 0;
		z-index: 500;
	}
}

@media screen and (max-width: 767px) {
	.Sidebar_sidebar__3sAiT {
		display: none;
	}
}

.EmptyState_empty-state-container__A_3A0 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.EmptyState_empty-state-container__A_3A0 > div {
    width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.EmptyState_empty-state-image__3iyGq {
    padding-top: 50px;
    text-align: center;
    height: 150px;
    margin: auto;
    width: var(--empty-state-width);
    background-color: gray;

    -webkit-user-select: none;
	user-select: none;
    margin-bottom: 20px;
}

.EmptyState_empty-state-image__3iyGq.EmptyState_bookmark__3wTT8 {
    -webkit-mask: url(/static/media/icon-empty-bookmark.af562032.svg);
            mask: url(/static/media/icon-empty-bookmark.af562032.svg);
    -webkit-mask-size: var(--empty-state-width) auto;
            mask-size: var(--empty-state-width) auto;
    -webkit-mask-repeat: no-repeat;
            mask-repeat: no-repeat;
}

.EmptyState_empty-state-image__3iyGq.EmptyState_publication__26q3h {
    -webkit-mask: url(/static/media/icon-empty-publication.df484026.svg);
            mask: url(/static/media/icon-empty-publication.df484026.svg);
    -webkit-mask-size: var(--empty-state-width) auto;
            mask-size: var(--empty-state-width) auto;
    -webkit-mask-repeat: no-repeat;
            mask-repeat: no-repeat;
}

.EmptyState_empty-state-image__3iyGq.EmptyState_purchases__1SA4y {
    -webkit-mask: url(/static/media/icon-empty-purchase.df3338c2.svg);
            mask: url(/static/media/icon-empty-purchase.df3338c2.svg);
    -webkit-mask-size: var(--empty-state-width) auto;
            mask-size: var(--empty-state-width) auto;
    -webkit-mask-repeat: no-repeat;
            mask-repeat: no-repeat;
}

.EmptyState_empty-state-image__3iyGq.EmptyState_issue__3cJNO {
    -webkit-mask: url(/static/media/icon-empty-issue.49cda4da.svg);
            mask: url(/static/media/icon-empty-issue.49cda4da.svg);
    -webkit-mask-size: var(--empty-state-width) auto;
            mask-size: var(--empty-state-width) auto;
    -webkit-mask-repeat: no-repeat;
            mask-repeat: no-repeat;
}

.EmptyState_empty-state-image__3iyGq.EmptyState_feeds__3tbyi {
    -webkit-mask: url(/static/media/icon-empty-feeds.521a3fc4.svg);
            mask: url(/static/media/icon-empty-feeds.521a3fc4.svg);
    -webkit-mask-size: var(--empty-state-width) auto;
            mask-size: var(--empty-state-width) auto;
    -webkit-mask-repeat: no-repeat;
            mask-repeat: no-repeat;
}

.EmptyState_empty-state-image__3iyGq > img {
    stroke: red;
}

.EmptyState_empty-state-content__1tIOV {
    color: gray;
    text-align: center;
}

.EmptyState_empty-state-content__1tIOV > h3 {
    font-weight: 500;
    font-size: 1.05rem;
    margin-bottom: 5px;
}
.BottomBar_bottom-bar__2Bj8y {
	display: flex;
	flex-direction: row;
	align-content: center;
	align-items: center;
	justify-content: center;
	height: calc(
		var(--bottom-bar-height) +
			env(safe-area-inset-bottom, var(--safe-area-inset))
	);
	text-align: center;
	z-index: 100;
	padding-bottom: env(safe-area-inset-bottom, var(--safe-area-inset));

	border-radius: 0px;
	background: #ffffff;
	border-top: 1px solid #e0e4ea;
	position: absolute;

	transition: bottom 240ms ease-out;
}

.BottomBar_bottom-bar-icon-container__3dcHn {
	flex-basis: 100%;
	flex: 1 1;
	align-self: center;
}

.BottomBar_bottom-bar-icon__3Tj_u {
	width: 50%;
	margin: auto;
	padding: 6px;
	-webkit-mask-size: cover;
	        mask-size: cover;
	display: inline-block;

	border-radius: 0px;
}

.BottomBar_bottom-bar-icon__3Tj_u:hover {
	cursor: pointer;
}

/*ICONS*/
.BottomBar_bottom-bar-icon__3Tj_u.BottomBar_icon-home__uA8cD > button {
	-webkit-mask: url(/static/media/icon-home.4070b609.svg);
	        mask: url(/static/media/icon-home.4070b609.svg);
	margin: auto;
	height: 24px;
	width: 24px;
	background: #000;
}

.BottomBar_bottom-bar-icon__3Tj_u.BottomBar_icon-home__uA8cD.BottomBar_selected__2D_Pj > button {
	-webkit-mask: url(/static/media/icon-home-selected.66b71da2.svg);
	        mask: url(/static/media/icon-home-selected.66b71da2.svg);
}

.BottomBar_bottom-bar-icon__3Tj_u.BottomBar_icon-home__uA8cD:hover button {
	background: var(--hover-color);
}

.BottomBar_bottom-bar-icon__3Tj_u.BottomBar_icon-purchases__2iQ8S > button {
	-webkit-mask: url(/static/media/icon-purchases.29a1a4d4.svg);
	        mask: url(/static/media/icon-purchases.29a1a4d4.svg);
	margin: auto;
	height: 24px;
	width: 24px;
	background: #000;
}
.BottomBar_bottom-bar-icon__3Tj_u.BottomBar_icon-purchases__2iQ8S.BottomBar_selected__2D_Pj > button {
	-webkit-mask: url(/static/media/icon-purchases-selected.4e5e2fa8.svg);
	        mask: url(/static/media/icon-purchases-selected.4e5e2fa8.svg);
}

.BottomBar_bottom-bar-icon__3Tj_u.BottomBar_icon-purchases__2iQ8S:hover button {
	background: var(--hover-color);
}

.BottomBar_bottom-bar-icon__3Tj_u.BottomBar_icon-bookmarks__18nPa > button {
	-webkit-mask: url(/static/media/icon-bookmarks.06fc4612.svg);
	        mask: url(/static/media/icon-bookmarks.06fc4612.svg);
	margin: auto;
	height: 24px;
	width: 24px;
	background: #000;
}

.BottomBar_bottom-bar-icon__3Tj_u.BottomBar_icon-bookmarks__18nPa.BottomBar_selected__2D_Pj > button {
	-webkit-mask: url(/static/media/icon-bookmarks-selected.6704c082.svg);
	        mask: url(/static/media/icon-bookmarks-selected.6704c082.svg);
}

.BottomBar_bottom-bar-icon__3Tj_u.BottomBar_icon-bookmarks__18nPa:hover button {
	background: var(--hover-color);
}

.BottomBar_bottom-bar-icon__3Tj_u.BottomBar_icon-setting__1Wg-p > button {
	-webkit-mask: url(/static/media/icon-setting.5e9f2c57.svg);
	        mask: url(/static/media/icon-setting.5e9f2c57.svg);
	margin: auto;
	height: 24px;
	width: 24px;
	background: #000;
}

.BottomBar_bottom-bar-icon__3Tj_u.BottomBar_icon-setting__1Wg-p.BottomBar_selected__2D_Pj > button {
	-webkit-mask: url(/static/media/icon-setting-selected.bcc657b9.svg);
	        mask: url(/static/media/icon-setting-selected.bcc657b9.svg);
}

.BottomBar_bottom-bar-icon__3Tj_u.BottomBar_icon-setting__1Wg-p:hover button {
	background: var(--hover-color);
}

.BottomBar_bottom-bar-icon__3Tj_u.BottomBar_icon-font__3I2LK > button {
	-webkit-mask: url(/static/media/icon-font.97353b8d.svg);
	        mask: url(/static/media/icon-font.97353b8d.svg);
	margin: auto;
	height: 24px;
	width: 24px;
	background: #000;
}

.BottomBar_bottom-bar-icon__3Tj_u.BottomBar_icon-font__3I2LK:hover button {
	background: var(--hover-color);
}

.BottomBar_bottom-bar-icon__3Tj_u.BottomBar_icon-font-increase__1W0JA > button {
	-webkit-mask: url(/static/media/icon-font-increase.9d647265.svg);
	        mask: url(/static/media/icon-font-increase.9d647265.svg);
	margin: auto;
	height: 24px;
	width: 24px;
	background: #000;
}

.BottomBar_bottom-bar-icon__3Tj_u.BottomBar_icon-font-increase__1W0JA:hover button {
	background: var(--hover-color);
}

.BottomBar_bottom-bar-icon__3Tj_u.BottomBar_icon-font-decrease__6U11W > button {
	-webkit-mask: url(/static/media/icon-font-decrease.98fb8cbd.svg);
	        mask: url(/static/media/icon-font-decrease.98fb8cbd.svg);
	margin: auto;
	height: 24px;
	width: 24px;
	background: #000;
}

.BottomBar_bottom-bar-icon__3Tj_u.BottomBar_icon-font-decrease__6U11W:hover button {
	background: var(--hover-color);
}

.BottomBar_bottom-bar-icon__3Tj_u.BottomBar_icon-menu__NskSx > button {
	-webkit-mask: url(/static/media/icon-menu.2a903b08.svg);
	        mask: url(/static/media/icon-menu.2a903b08.svg);
	margin: auto;
	height: 24px;
	width: 24px;
	background: #000;
}

.BottomBar_bottom-bar-icon__3Tj_u.BottomBar_icon-menu__NskSx.BottomBar_selected__2D_Pj > button {
	-webkit-mask: url(/static/media/icon-menu-selected.a08b4366.svg);
	        mask: url(/static/media/icon-menu-selected.a08b4366.svg);
}

.BottomBar_bottom-bar-icon__3Tj_u.BottomBar_icon-menu__NskSx:hover button {
	background: var(--hover-color);
}

.BottomBar_bottom-bar-icon__3Tj_u.BottomBar_icon-reader__188bx > button {
	-webkit-mask: url(/static/media/icon-reader-mode.e7d76ffb.svg);
	        mask: url(/static/media/icon-reader-mode.e7d76ffb.svg);
	margin: auto;
	height: 24px;
	width: 24px;
	background: #000;
}

.BottomBar_bottom-bar-icon__3Tj_u.BottomBar_icon-reader__188bx.BottomBar_selected__2D_Pj > button {
	-webkit-mask: url(/static/media/icon-reader-mode-selected.94859d88.svg);
	        mask: url(/static/media/icon-reader-mode-selected.94859d88.svg);
}

.BottomBar_bottom-bar-icon__3Tj_u.BottomBar_icon-reader__188bx:hover button {
	background: var(--hover-color);
}

.BottomBar_bottom-bar-icon__3Tj_u.BottomBar_icon-bookmark__2cEEj > button {
	-webkit-mask: url(/static/media/icon-bookmark.857b1589.svg);
	        mask: url(/static/media/icon-bookmark.857b1589.svg);
	margin: auto;
	height: 24px;
	width: 24px;
	background: #000;
}

.BottomBar_bottom-bar-icon__3Tj_u.BottomBar_icon-bookmark__2cEEj.BottomBar_selected__2D_Pj > button {
	-webkit-mask: url(/static/media/icon-bookmark-selected.ec789b31.svg);
	        mask: url(/static/media/icon-bookmark-selected.ec789b31.svg);
}

.BottomBar_bottom-bar-icon__3Tj_u.BottomBar_icon-bookmark__2cEEj:hover button {
	background: var(--hover-color);
}

.BottomBar_bottom-bar-icon__3Tj_u.BottomBar_icon-bookmark__2cEEj.BottomBar_selected__2D_Pj > button {
	-webkit-mask: url(/static/media/icon-bookmark-selected.ec789b31.svg);
	        mask: url(/static/media/icon-bookmark-selected.ec789b31.svg);
}

.BottomBar_bottom-bar-icon__3Tj_u.BottomBar_icon-feed__3fAyf > button {
	-webkit-mask: url(/static/media/icon-feed.cad9f7f8.svg);
	        mask: url(/static/media/icon-feed.cad9f7f8.svg);
	margin: auto;
	height: 24px;
	width: 24px;
	background: #000;
}

.BottomBar_bottom-bar-icon__3Tj_u.BottomBar_icon-feed__3fAyf.BottomBar_selected__2D_Pj > button {
	-webkit-mask: url(/static/media/icon-feed-selected.cad9f7f8.svg);
	        mask: url(/static/media/icon-feed-selected.cad9f7f8.svg);
}

.BottomBar_bottom-bar-icon__3Tj_u.BottomBar_icon-feed__3fAyf:hover button {
	background: var(--hover-color);
}

.BottomBar_bottom-bar-icon__3Tj_u.BottomBar_icon-feed__3fAyf.BottomBar_selected__2D_Pj > button {
	-webkit-mask: url(/static/media/icon-feed-selected.cad9f7f8.svg);
	        mask: url(/static/media/icon-feed-selected.cad9f7f8.svg);
}

.BottomBar_bottom-bar-icon__3Tj_u > button {
	-webkit-mask-repeat: no-repeat !important;
	        mask-repeat: no-repeat !important;
}

.BottomBar_font-size-option-holder__2tjy4 {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	position: absolute;
	height: var(--bottom-bar-height);
	bottom: calc(
		var(--bottom-bar-height) +
			env(safe-area-inset-bottom, var(--safe-area-inset))
	);

	width: 100%;
	background: #ffffff;
	box-shadow: 1px -1px 3px #b5b5b5, -1px 1px 3px #ffffff;

	transition: bottom 400ms ease-out;
	z-index: 100;
}

footer.BottomBar_not-display__4hiOX {
	--not-display-bottom-bar-height: calc(var(--bottom-bar-height) * -1);
	--not-display-safe-inset: calc(
		env(safe-area-inset-bottom, var(--safe-area-inset)) * -1
	);
	bottom: calc(
		var(--not-display-bottom-bar-height) + var(--not-display-safe-inset)
	);
}

.BottomBar_font-slider-container__h7TW- {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: calc(
		var(--bottom-bar-height) +
			env(safe-area-inset-bottom, var(--safe-area-inset))
	);
	margin-left: 20px;
	margin-right: 20px;
}

.BottomBar_font-icon-container__1Npnn {
	display: flex;
	flex-direction: column;
	width: 60px;
}

@media screen and (min-width: 767px) {
	.BottomBar_bottom-bar__2Bj8y {
		display: none !important;
	}
}
.InstallationOption_installation-option__1_f4S {
	height: 100vh;
	width: 100vw;
	background: rgba(46, 46, 46, 0.75);
	opacity: 1;
	position: fixed;
	z-index: 300;
}

.InstallationOption_installation-option-ios__32vAY {
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: flex-end;
	flex-direction: column;
	align-items: center;
}

.InstallationOption_installation-option-ios__32vAY > :first-child {
	height: 250px;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 110px;
}

.InstallationOption_installation-option-ios__32vAY > :first-child > .InstallationOption_caret__3HSH9 {
	width: 90%;
	height: 20px;
	display: flex;
	justify-content: center;
}

.InstallationOption_installation-option-ios__32vAY > :first-child > .InstallationOption_caret__3HSH9 > div {
	width: 20px;
	height: 20px;
	position: absolute;

	border-left: 20px solid transparent;
	border-right: 20px solid transparent;

	border-top: 20px solid #fff;
}

.InstallationOption_ios-content__dWawc {
	background: #fff;
	display: flex;
	position: relative;
	flex-direction: column;
	width: 90%;
	height: 90%;
	border-radius: 8px;
	box-shadow: 1px 3px 21px -4px rgba(0, 0, 0, 0.75);
}

.InstallationOption_ios-content__dWawc > div {
	width: 100%;

	margin-bottom: 5px;
}

.InstallationOption_ios-content__dWawc > .InstallationOption_title__2I_LC {
	margin-top: 30px;
	width: 100%;
	display: flex;
	justify-content: center;
}

.InstallationOption_ios-content__dWawc > div > .InstallationOption_container__17d4w {
	width: 90%;
	display: flex;

	justify-content: flex-start;
}

.InstallationOption_container__17d4w > .InstallationOption_icon__3w_wN {
	margin-left: 15px;
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

.InstallationOption_container__17d4w > h1 {
	font-size: 14pt;
	color: #3d3d3d;
	margin-left: 20px;
}

.InstallationOption_ios-content__dWawc > .InstallationOption_message__TNzoL {
	width: 100%;
	height: 40px;
	font-size: 12pt;
	display: flex;
	justify-content: center;
}

.InstallationOption_message__TNzoL > .InstallationOption_container__17d4w > p {
	padding-left: 20px;
	padding-right: 20px;
}

.InstallationOption_ios-content__dWawc > .InstallationOption_close__ZXJYh {
	width: 20px;
	height: 20px;
	top: 0;
	right: 0;
	cursor: pointer;
	position: absolute;
	margin-top: 10px;
	margin-right: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
}

@media only screen and (min-width: 767px) {
	.InstallationOption_installation-option-ios__32vAY {
		margin-top: 25px;
		justify-content: flex-start;
	}

	.InstallationOption_installation-option-ios__32vAY > :first-child {
		align-items: flex-end;
		margin-right: 50px;
	}

	.InstallationOption_ios-content__dWawc {
		width: 400px;
		height: 200px;
	}

	.InstallationOption_installation-option-ios__32vAY > :first-child > .InstallationOption_caret__3HSH9 {
		opacity: 0;
	}
}

.Feed_container__8xAHU {
	display: grid;
	width: 100%;
	height: 100%;
	grid-template-columns: 1fr;
	grid-template-rows: var(--navbar-height) calc(100% - var(--navbar-height));
	grid-gap: 0px 0px;
	gap: 0px 0px;
	grid-template-areas:
		'.'
		'.';
}

.Feed_container__8xAHU > nav {
	width: 100%;
	height: var(--navbar-height);
	display: grid;
	grid-template-columns: 1fr 2fr 1fr;
	grid-template-rows: 1fr;
	grid-gap: 0px 0px;
	gap: 0px 0px;
	grid-template-areas: '. . .';

	/*-webkit-box-shadow: 0px 4px 5px -3px rgba(0, 0, 0, 0.35);
    -moz-box-shadow: 0px 4px 5px -3px rgba(0, 0, 0, 0.35);
    box-shadow: 0px 4px 5px -3px rgba(0, 0, 0, 0.35);*/

	/*0 1px 2px rgba(0,0,0,.18),0 1px 1px rgba(0,0,0,.03)!important*/
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.18), 0 1px 1px rgba(0, 0, 0, 0.03) !important;
}

.Feed_container__8xAHU > nav > :first-child {
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: flex-start;
	padding-left: 15px;
}

.Feed_container__8xAHU > nav > :first-child > button {
	-webkit-mask: url(/static/media/arrow-left.74e104f0.svg);
	        mask: url(/static/media/arrow-left.74e104f0.svg);
	height: 24px;
	width: 24px;
	background: #000;
}

.Feed_container__8xAHU > nav > .Feed_logo__13WD2 {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
}

.Feed_container__8xAHU > nav > .Feed_logo__13WD2 > img {
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.Feed_container__8xAHU > main {
	width: 100%;
	height: 100%;
	display: grid;
	grid-template-columns: 0.3fr 0.7fr;
	grid-template-rows: 1fr;
	grid-gap: 0px 0px;
	gap: 0px 0px;
	grid-template-areas: '. .';
}

.Feed_items__2Kl4w {
	height: 100%;
	width: 100%;
	overflow-y: auto;
	overflow-x: hidden;
	border-right: 1px solid #eee;
	transition: width 2s;
}

.Feed_item__1QjoV {
	width: 100%;
	cursor: pointer;
	height: 15vh;
	border-bottom: 1px solid #eee;
	display: grid;
	grid-template-columns: 30% 70%;
	grid-template-rows: 1fr;
	grid-gap: 0px 0px;
	gap: 0px 0px;
	grid-template-areas: '. .';
	padding: 0px 10px;
}

.Feed_item__1QjoV.Feed_no-image__2Of9Y {
	grid-template-columns: 0% 100%;
	padding: 0px 20px;
}

.Feed_item__1QjoV > .Feed_image__2LOC8 {
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
	overflow: hidden;
}

.Feed_item__1QjoV > .Feed_image__2LOC8 > :first-child {
	background: #c4c4c4;
	border-radius: 4px;
	border: 1px solid rgba(196, 196, 196, 0.8);
	overflow: hidden;
	width: 80%;
	height: 60%;
	object-fit: cover;
}

.Feed_item__1QjoV > .Feed_content__1CtuO {
	width: 100%;
	height: 80%;
	max-height: 80%;
	margin: auto;
	display: flex;
	flex-direction: column;
	overflow: hidden;
}

.Feed_item__1QjoV > .Feed_content__1CtuO > .Feed_title__1aM9E {
	min-height: 20px;
	width: 100%;
	overflow: hidden;
}

.Feed_item__1QjoV > .Feed_content__1CtuO > .Feed_title__1aM9E > h3 {
	width: 100%;
	margin: 0;
	text-overflow: ellipsis;
	white-space: pre;
	overflow: hidden;
	font-size: 0.9rem;
	color: #3d3d3d;
}

.Feed_item__1QjoV > .Feed_content__1CtuO > small {
	margin: 0;
	font-size: 0.7rem;
	font-weight: bold;
	color: #888;
}

.Feed_item__1QjoV > .Feed_content__1CtuO > .Feed_description__1deeW {
	margin: 0;
	margin-top: 6px;
	width: 100%;
	display: inline;
	font-size: 0.75rem;
	text-transform: lowercase;
	line-height: 1.6;
	font-weight: normal !important;
	color: #777;
	overflow: hidden;
}

.Feed_item__1QjoV > .Feed_content__1CtuO > .Feed_description__1deeW > p {
	display: inline;
	margin-right: 1px;
	text-align: justify;
}
.Feed_item__1QjoV > .Feed_content__1CtuO > .Feed_description__1deeW > p::first-letter {
	text-transform: uppercase;
}

.Feed_item__1QjoV > .Feed_content__1CtuO > .Feed_description__1deeW > * {
	margin: 0;
}

.Feed_item__1QjoV > .Feed_content__1CtuO > .Feed_description__1deeW > * > b {
	margin: 0;
	font-weight: normal;
}

.Feed_item__1QjoV > .Feed_content__1CtuO > .Feed_description__1deeW > * > b::first-letter {
	text-transform: uppercase;
}

.Feed_item__1QjoV > .Feed_content__1CtuO > .Feed_description__1deeW > * > img {
	display: hidden;
}

.Feed_article-container__JVuwA {
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: auto;
}

.Feed_article-container__JVuwA > article {
	width: 84%;
	height: 100%;
	margin-bottom: 20px;
	padding-top: 40px;
	position: relative;
}

.Feed_article-container__JVuwA > article > h1 {
	margin: 0;
	margin-bottom: 6px;
}

.Feed_article-container__JVuwA > article > small {
	color: #888;
	display: block;
	font-size: 0.9rem;
	margin-bottom: 20px;
}

.Feed_article-container__JVuwA > article > section {
	width: 100%;
}

.Feed_article-container__JVuwA > article > section > p {
	line-height: 1.7rem;
	font-size: 1.1rem;
	color: #3d3d3d;
	text-align: justify;
	/*word-spacing: 0.05rem;*/
	margin-bottom: 1.1em;
}

.Feed_article-container__JVuwA > article > section > p > b {
	color: #000;
	display: block;
	text-align: initial;
}

.Feed_article-container__JVuwA > article > section > p > img {
	max-width: 100%;
	/*width: 100%;*/
	object-fit: cover;
	margin: auto;
	display: none !important;
}

.Feed_article-container__JVuwA > article > .Feed_link__14cSw {
	border-top: 1px solid #c4c4c4;
	height: 20px;
	width: 100%;
	padding-top: 20px;
	margin-bottom: 50px;
	font-weight: bold;
	color: #0074d9;
	text-align: center;
}

.Feed_article-container__JVuwA > article > .Feed_thumbnail__3CMjB {
	width: 120px;
	height: 164px;
	position: fixed;
	cursor: pointer;
	bottom: 0;
	right: 20px;
	background: white;
	padding: 5px;

	border: 1px solid #eee;
	box-shadow: 0 1px 1px rgb(0 0 0 / 12%), 0 2px 2px rgb(0 0 0 / 12%),
		0 4px 4px rgb(0 0 0 / 12%);
	margin-bottom: 20px;
	overflow: hidden;
	transition: box-shadow 150ms ease;
}

.Feed_article-container__JVuwA > article > .Feed_thumbnail__3CMjB:hover {
	box-shadow: 0 7px 7px rgb(0 0 0 / 12%), 0 7px 7px rgb(0 0 0 / 12%),
		0 5px 5px rgb(0 0 0 / 12%);
}

.Feed_article-container__JVuwA > article > .Feed_thumbnail__3CMjB > img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

@media screen and (max-width: 767px) {
	.Feed_container__8xAHU > main {
		grid-template-columns: 1fr 0fr;
	}
	.Feed_container__8xAHU > main.Feed_selected__1zbKr {
		grid-template-columns: 0fr 1fr;
	}
	.Feed_item__1QjoV {
		grid-template-columns: 30% 70%;
		height: 15vh;
	}

	.Feed_article-container__JVuwA > article {
		width: 88%;
	}
}

@media screen and (min-width: 769px) {
	.Feed_container__8xAHU > main {
		grid-template-columns: 0.35fr 0.65fr;
	}
	.Feed_item__1QjoV {
		height: 15vh;
		grid-template-columns: 30% 70%;
	}
}

@media screen and (min-width: 1024px) {
	.Feed_item__1QjoV {
		height: 100px;
	}
}

@media screen and (min-width: 1024px) and (orientation: portrait) {
	.Feed_container__8xAHU > main {
		grid-template-columns: 0.35fr 0.65fr;
	}
}

@media screen and (min-width: 1024px) and (orientation: landscape) {
	.Feed_container__8xAHU > main {
		grid-template-columns: 0.35fr 0.65fr;
	}
}

.Bookmarks_bookmarks__1BsPv {
	height: calc(
		100vh - var(--bottom-bar-height) -
			env(safe-area-inset-bottom, var(--safe-area-inset))
	);
	overflow: auto;
	background: #fafafa;
	padding-top: var(--navbar-height);

	-webkit-user-select: none;
	user-select: none;
}

.Bookmarks_container__2q1Tx {
	display: flex;
	flex-direction: column;
	padding: var(--card--item-gutter) 20px;
}

.Bookmarks_bookmark__1tf8h {
	cursor: pointer;
	display: flex;
	min-height: var(--card-item-height);
	flex-direction: column;
	flex-wrap: nowrap;
	padding: 15px 20px;
	margin: var(--card-item-gutter) 0px;
	border-radius: 8px;

	box-shadow: 5px 5px 10px #f0f0f0, -5px -5px 10px #ffffff;
	border: 1px solid #e0e4ea;
	margin: 10px;
	color: black;
	background: #fff;
}

.Bookmarks_bookmark__1tf8h:hover {
	border-radius: 3px;
	box-shadow: 5px 5px 10px #cfcfcf, -5px -5px 10px #ffffff;
}

.Bookmarks_bookmark__1tf8h > div {
	width: 100%;
	min-width: 0;
	overflow: hidden;
	text-overflow: clip;
	white-space: normal;
}

.Bookmarks_title__11ZeM {
	font-size: 14px;
}

.Bookmarks_description__1nhcw {
	margin-top: auto;
	font-size: 11px;
}

.Bookmarks_article-container__2v_4a {
	height: calc(
		100vh - var(--bottom-bar-height) -
			env(safe-area-inset-bottom, var(--safe-area-inset))
	);
	overflow: auto;
}

.Bookmarks_image-modal__185ai {
	position: fixed;
	top: 0;
	width: 100vw;
	height: 100vh;
	z-index: 800;
	background: black;
	display: flex;
	flex-direction: column;
}
.Bookmarks_image-modal__185ai > div {
	display: flex;
	width: 100%;
}

.Bookmarks_image-modal__185ai > :first-child {
	justify-content: flex-end;
	align-items: center;
	height: var(--navbar-height);
	padding-right: 10px;
}

.Bookmarks_image-modal__185ai > .Bookmarks_container__2q1Tx {
	display: flex;
	justify-content: center;
	align-items: center;
	height: calc(
		100vh - var(--navbar-height) - var(--bottom-bar-height) -
			env(safe-area-inset-bottom, var(--safe-area-inset))
	);
}

.Bookmarks_image-modal__185ai > .Bookmarks_container__2q1Tx > div {
	width: 100%;
	max-height: calc(
		100vh - var(--navbar-height) - var(--bottom-bar-height) -
			env(safe-area-inset-bottom, var(--safe-area-inset))
	);
	overflow: auto;
}

.Bookmarks_image-modal-x-icon__3p2EE > button {
	-webkit-mask: url(/static/media/x-icon.6c9a4ba7.svg);
	        mask: url(/static/media/x-icon.6c9a4ba7.svg);
	background: white;
	margin: auto;
	height: 24px;
	width: 24px;
}

.Bookmarks_image-modal-content__1SY9e {
	width: 100%;
	max-height: calc(
		100vh - var(--navbar-height) - var(--bottom-bar-height) -
			env(safe-area-inset-bottom, var(--safe-area-inset))
	);
	object-fit: contain;
}

@media screen and (min-width: 769px) {
	.Bookmarks_bookmarks__1BsPv {
		display: flex;
		height: calc(
			100vh - env(safe-area-inset-bottom, var(--safe-area-inset))
		);
	}

	.Bookmarks_container__2q1Tx {
		width: calc(100% - var(--bottom-bar-height));
		margin-left: var(--bottom-bar-height);
	}

	.Bookmarks_image-modal__185ai > .Bookmarks_container__2q1Tx {
		display: flex;
		justify-content: center;
		align-items: center;
		height: calc(
			100vh - var(--navbar-height)- env(safe-area-inset-bottom, var(--safe-area-inset))
		);
	}

	.Bookmarks_image-modal__185ai > .Bookmarks_container__2q1Tx > div {
		width: 100%;
		max-height: calc(
			100vh - var(--navbar-height)- env(safe-area-inset-bottom, var(--safe-area-inset))
		);
		overflow: auto;
	}

	.Bookmarks_article-container__2v_4a {
		height: calc(
			100vh - env(safe-area-inset-bottom, var(--safe-area-inset))
		);
		overflow: auto;
	}
}

.Fontbar_font-size-option-holder__2QJ9q {
	display: none;
	justify-content: center;
	align-items: center;
	width: 40vw;
	max-width: 350px;
	z-index: 200;

	margin-top: var(--navbar-height);
	position: fixed;
	height: var(--navbar-height);

	box-shadow: -2px 0px 5px 0px rgba(0, 0, 0, 0.4);
	-webkit-box-shadow: -2px 0px 5px 0px rgba(0, 0, 0, 0.4);
	-moz-box-shadow: -2px 0px 5px 0px rgba(0, 0, 0, 0.4);

	transition: bottom 400ms ease-out;
	z-index: 100;

	-webkit-user-select: none;
	user-select: none;
}

.Fontbar_font-size-option-holder__2QJ9q > div {
	display: flex;
	width: 100%;
	flex-direction: row;
	justify-content: space-between;
}

.Fontbar_font-size-option-holder__2QJ9q > div > :first-child {
	margin-left: 10px;
}

.Fontbar_font-size-option-holder__2QJ9q > div > :last-child {
	margin-right: 10px;
}

.Fontbar_fontbar-icon__2Bmq7 {
	width: 50%;
	margin: auto;
	padding: 6px;
	-webkit-mask-size: cover;
	        mask-size: cover;
	display: inline-block;

	border-radius: 0px;
}

.Fontbar_fontbar-icon__2Bmq7:hover {
	cursor: pointer;
}

.Fontbar_fontbar-icon__2Bmq7.Fontbar_icon-font-increase__3wYIj > button {
	-webkit-mask: url(/static/media/icon-font-increase.9d647265.svg);
	        mask: url(/static/media/icon-font-increase.9d647265.svg);
	margin: auto;
	height: 24px;
	width: 24px;
	background: #000;
}

.Fontbar_fontbar-icon__2Bmq7.Fontbar_icon-font-decrease__2oUwB > button {
	-webkit-mask: url(/static/media/icon-font-decrease.98fb8cbd.svg);
	        mask: url(/static/media/icon-font-decrease.98fb8cbd.svg);
	margin: auto;
	height: 24px;
	width: 24px;
	background: #000;
}

@media screen and (min-width: 767px) {
	.Fontbar_font-size-option-holder__2QJ9q {
		display: flex;
	}
}

.ArticleSettings_bottom-bar__28TbT {
	display: flex;
	flex-direction: row;
	align-content: center;
	align-items: center;
	justify-content: center;
	height: calc(
		var(--bottom-bar-height) +
			env(safe-area-inset-bottom, var(--safe-area-inset))
	);
	text-align: center;
	z-index: 150;
	padding-bottom: env(safe-area-inset-bottom, var(--safe-area-inset));

	border-radius: 0px;
	background: #ffffff;
	border-top: 1px solid #e0e4ea;
	position: absolute;
	box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.75);

	transition: bottom 240ms ease-out;
}

.ArticleSettings_bottom-bar-icon-container__1IvnK {
	flex-basis: 100%;
	flex: 1 1;
	align-self: center;
}

.ArticleSettings_bottom-bar-icon__1y8c4 {
	width: 50%;
	margin: auto;
	padding: 6px;
	-webkit-mask-size: cover;
	        mask-size: cover;
	display: inline-block;

	border-radius: 0px;
}

.ArticleSettings_bottom-bar-icon__1y8c4:hover {
	cursor: pointer;
}

.ArticleSettings_bottom-bar-icon__1y8c4.ArticleSettings_icon-menu__2DA_L > button {
	-webkit-mask: url(/static/media/icon-menu.2a903b08.svg);
	        mask: url(/static/media/icon-menu.2a903b08.svg);
	margin: auto;
	height: 24px;
	width: 24px;
	background: #000;
}

.ArticleSettings_bottom-bar-icon__1y8c4.ArticleSettings_icon-menu__2DA_L.ArticleSettings_selected__1YC5i > button {
	-webkit-mask: url(/static/media/icon-menu-selected.a08b4366.svg);
	        mask: url(/static/media/icon-menu-selected.a08b4366.svg);
}

.ArticleSettings_bottom-bar-icon__1y8c4.ArticleSettings_icon-menu__2DA_L:hover button {
	background: #000;
}

.ArticleSettings_bottom-bar-icon__1y8c4.ArticleSettings_icon-reader__j2kZ8 > button {
	-webkit-mask: url(/static/media/icon-reader-mode.e7d76ffb.svg);
	        mask: url(/static/media/icon-reader-mode.e7d76ffb.svg);
	margin: auto;
	height: 24px;
	width: 24px;
	background: #000;
}

.ArticleSettings_bottom-bar-icon__1y8c4.ArticleSettings_icon-reader__j2kZ8.ArticleSettings_selected__1YC5i > button {
	-webkit-mask: url(/static/media/icon-reader-mode-selected.94859d88.svg);
	        mask: url(/static/media/icon-reader-mode-selected.94859d88.svg);
}

.ArticleSettings_bottom-bar-icon__1y8c4.ArticleSettings_icon-reader__j2kZ8:hover button {
	background: #000;
}


.ArticleSettings_bottom-bar-icon__1y8c4.ArticleSettings_icon-image-mode__20bdk > button {
	-webkit-mask: url(/static/media/icon-image-mode.fd3fb891.svg);
	        mask: url(/static/media/icon-image-mode.fd3fb891.svg);
	margin: auto;
	height: 24px;
	width: 24px;
	background: #000;
}

.ArticleSettings_bottom-bar-icon__1y8c4.ArticleSettings_icon-image-mode__20bdk.ArticleSettings_selected__1YC5i > button {
	-webkit-mask: url(/static/media/icon-image-mode-selected.87c34a54.svg);
	        mask: url(/static/media/icon-image-mode-selected.87c34a54.svg);
}

.ArticleSettings_bottom-bar-icon__1y8c4.ArticleSettings_icon-image-mode__20bdk:hover button {
	background: #000;
}

.ArticleSettings_bottom-bar-icon__1y8c4.ArticleSettings_icon-font__sWyaP > button {
	-webkit-mask: url(/static/media/icon-font.97353b8d.svg);
	        mask: url(/static/media/icon-font.97353b8d.svg);
	margin: auto;
	height: 24px;
	width: 24px;
	background: #000;
}

.ArticleSettings_bottom-bar-icon__1y8c4.ArticleSettings_icon-font__sWyaP.ArticleSettings_selected__1YC5i > button {
	-webkit-mask: url(/static/media/icon-font-selected.6c54a040.svg);
	        mask: url(/static/media/icon-font-selected.6c54a040.svg);
}

.ArticleSettings_bottom-bar-icon__1y8c4.ArticleSettings_icon-font__sWyaP:hover button {
	background: #000;
}

.ArticleSettings_bottom-bar-icon__1y8c4.ArticleSettings_icon-font-increase__22_tW > button {
	-webkit-mask: url(/static/media/icon-font-increase.9d647265.svg);
	        mask: url(/static/media/icon-font-increase.9d647265.svg);
	margin: auto;
	height: 24px;
	width: 24px;
	background: #000;
}

.ArticleSettings_font-size-option-holder__39q7g {
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	height: var(--bottom-bar-height);
	bottom: calc(
		var(--bottom-bar-height) +
			env(safe-area-inset-bottom, var(--safe-area-inset))
	);

	width: 100%;
	background: #ffffff;
	box-shadow: 1px -1px 3px #b5b5b5, -1px 1px 3px #ffffff;

	transition: bottom 400ms ease-out;
	z-index: 100;
}

.ArticleSettings_font-size-option-holder__39q7g > div {
	display: flex;
	width: 100%;
	flex-direction: row;
	justify-content: space-between;
}

.ArticleSettings_font-size-option-holder__39q7g > div > :first-child {
	margin-left: 10px;
}

.ArticleSettings_font-size-option-holder__39q7g > div > :last-child {
	margin-right: 10px;
}

.ArticleSettings_bottom-bar-icon__1y8c4.ArticleSettings_icon-font-increase__22_tW:hover button {
	background: #000;
}

.ArticleSettings_bottom-bar-icon__1y8c4.ArticleSettings_icon-font-decrease__3TgtL > button {
	-webkit-mask: url(/static/media/icon-font-decrease.98fb8cbd.svg);
	        mask: url(/static/media/icon-font-decrease.98fb8cbd.svg);
	margin: auto;
	height: 24px;
	width: 24px;
	background: #000;
}

.ArticleSettings_bottom-bar-icon__1y8c4.ArticleSettings_icon-share___SYlW > button {
	-webkit-mask: url(/static/media/icon-share.50a1309b.svg);
	        mask: url(/static/media/icon-share.50a1309b.svg);
	margin: auto;
	height: 24px;
	width: 24px;
	background: #000;
}

.ArticleSettings_bottom-bar-icon__1y8c4.ArticleSettings_icon-bookmark__Xeu0W > button {
	-webkit-mask: url(/static/media/icon-bookmark.857b1589.svg);
	        mask: url(/static/media/icon-bookmark.857b1589.svg);
	margin: auto;
	height: 24px;
	width: 24px;
	background: #000;
}

.ArticleSettings_bottom-bar-icon__1y8c4.ArticleSettings_icon-bookmark__Xeu0W.ArticleSettings_selected__1YC5i > button {
	-webkit-mask: url(/static/media/icon-bookmark-selected.ec789b31.svg);
	        mask: url(/static/media/icon-bookmark-selected.ec789b31.svg);
}

.ArticleSettings_bottom-bar-icon__1y8c4.ArticleSettings_icon-bookmark__Xeu0W:hover button {
	background: #000;
}

.ArticleSettings_bottom-bar-icon__1y8c4.ArticleSettings_icon-font-decrease__3TgtL:hover button {
	background: #000;
}

.ArticleSettings_article-settings__R37sO {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-content: center;
	height: 500px;
	max-height: 500px;

	z-index: 100;
	padding: 0px 20px;

	border-radius: 12px;
	background: #ffffff;
	box-shadow: 1px -1px 3px #b5b5b5, -1px 1px 3px #ffffff;
	transition: var(--transition-animation);
}

.ArticleSettings_article-settings__R37sO > div {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	height: 60px;
	margin-top: 5px;
	margin-bottom: 5px;
	padding: 0px 0px;

	border-radius: 12px;
	/*background: #ffffff;
     box-shadow: 10px 10px 20px #cfcfcf, -10px -10px 20px #ffffff;*/
}

.ArticleSettings_article-settings-option__3U5kx {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	transition: var(--transition-animation);
}

.ArticleSettings_article-settings-option__3U5kx > .ArticleSettings_title__1eAWo {
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-left: var(--article-settings-options-padding);
}

.ArticleSettings_article-settings-option__3U5kx > .ArticleSettings_actions__MSMRx {
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-right: var(--article-settings-options-padding);
}

.ArticleSettings_font-size-container__2CNaI {
	display: flex;
	flex-direction: row;
	width: 110px;
	min-width: 110px;
	height: 40px;

	justify-content: space-between;

	border-radius: 12px;
}

.ArticleSettings_font-size-decrease__2zdlj {
	cursor: pointer;
	height: 100%;
	width: 45%;

	display: flex;
	flex-direction: column;
	align-content: center;
	justify-content: center;
	border-radius: 12px;
	background: var(--font-size-background);
	box-shadow: var(--font-size-box-shadow);
}

.ArticleSettings_font-size-decrease__2zdlj:active {
	background: var(--font-size-background-pressed);
	box-shadow: var(--font-size-box-shadow-pressed);
}

.ArticleSettings_font-size-decrease__2zdlj.ArticleSettings_disable__1j-4u {
	cursor: not-allowed;
	background: #fff;
	box-shadow: none;
}

.ArticleSettings_font-size-decrease__2zdlj.ArticleSettings_disable__1j-4u > svg {
	stroke: gray;
}

.ArticleSettings_font-size-increase__1D95M {
	cursor: pointer;
	height: 100%;
	width: 45%;

	display: flex;
	flex-direction: column;
	align-content: center;
	justify-content: center;

	border-radius: 12px;
	background: var(--font-size-background);
	box-shadow: var(--font-size-box-shadow);
	/*background-color: chartreuse;*/
}

.ArticleSettings_font-size-increase__1D95M:active {
	background: var(--font-size-background-pressed);
	box-shadow: var(--font-size-box-shadow-pressed);
}

.ArticleSettings_font-size-increase__1D95M.ArticleSettings_disable__1j-4u {
	cursor: not-allowed;
	background: #fff;
	box-shadow: none;
}

.ArticleSettings_font-size-increase__1D95M.ArticleSettings_disable__1j-4u > svg {
	fill: gray;
}

.ArticleSettings_font-size-increase__1D95M > svg {
	margin: auto;
}

.ArticleSettings_font-size-decrease__2zdlj > svg {
	margin: auto;
}

/*Handles toggle button*/

/* Hide default input */
.ArticleSettings_toggle__2w01n input {
	display: none;
}

/* The container and background */
.ArticleSettings_toggle__2w01n {
	position: relative;
	display: inline-block;
	width: 55px; /*18px*/
	height: 33px;

	border-radius: 30px;
	/*background: linear-gradient(145deg, #ffffff, #e6e6e6);
    box-shadow:  20px 20px 60px #d9d9d9, 
             -20px -20px 60px #ffffff;*/
	background: #bbbbbb;
	box-shadow: inset 5px 5px 10px #aaaaaa, inset -5px -5px 10px #cccccc;
}

.ArticleSettings_slider__3gs9Z {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	/*background-color: #ffffff;*/
	/*border: 1px solid #aaa;*/
	border-radius: 30px;
	transition: all 0.4s;
}

/* The sliding button */
.ArticleSettings_slider__3gs9Z:before {
	position: absolute;
	content: '';
	width: 25px;
	height: 25px;
	left: 3px;
	top: 4px;
	background-color: #fff; /*Button color*/
	border-radius: 24px;
	transition: all 0.4s;
}

/* On checked */
input:checked + .ArticleSettings_slider__3gs9Z {
	background: #161625;
	box-shadow: inset 5px 5px 10px #12121f, inset -5px -5px 10px #1a1a2b;
}

input:checked + .ArticleSettings_slider__3gs9Z:before {
	-webkit-transform: translateX(23px);
	        transform: translateX(23px);
}

@media screen and (min-width: 767px) {
	.ArticleSettings_bottom-bar__28TbT {
		display: none;
		height: 0;
	}

	.ArticleSettings_font-size-option-holder__39q7g {
		display: none;
		height: 0;
	}
}

.article_article__160td {
	position: relative;
	width: 100%;
	max-width: 100%;
	height: 100%;
	overflow-x: hidden;
	box-sizing: border-box;
}

.article_article__160td ul {
	display: block;
	list-style-type: disc;
	-webkit-margin-before: 1em;
	        margin-block-start: 1em;
	-webkit-margin-after: 1em;
	        margin-block-end: 1em;
	-webkit-margin-start: 0px;
	        margin-inline-start: 0px;
	-webkit-margin-end: 0px;
	        margin-inline-end: 0px;
	-webkit-padding-start: 40px;
	        padding-inline-start: 40px;
}

.article_article__160td ul,
.article_article__160td ul li {
	width: 100%;
}

.article_article__160td button:disabled {
	opacity: 0.5;
	cursor: not-allowed !important;
}

.article_article__160td figure {
	margin: 0;
	-webkit-margin-before: 0em;
	        margin-block-start: 0em;
	-webkit-margin-after: 0em;
	        margin-block-end: 0em;
	-webkit-margin-start: 0px;
	        margin-inline-start: 0px;
	-webkit-margin-end: 0px;
	        margin-inline-end: 0px;
}

.article_canvas__2QPdB {
	display: block;
	height: 100%;
	max-width: 1024px;
	box-sizing: border-box;
	margin-left: auto;
	margin-right: auto;
}

.article_canvas__2QPdB.article_ad__jFLu- {
	overflow: hidden;
	height: 100%;
}

.article_canvas__2QPdB.article_ad__jFLu- > div {
	height: 100%;
}

.article_image__2J57g {
	color: #000;
	display: flex;
	flex-direction: column;
}

.article_image__2J57g > img,
.article_image__2J57g > a > img {
	height: auto;
	max-width: 100%;
	object-fit: cover;
	text-align: center;
}

.article_image__2J57g > figcaption {
	text-align: center;
	/*padding: 2% 20px;*/
	padding-left: 10px;
	width: 100%;
}

.article_image__2J57g > cite {
	display: block;
	text-align: center;
}

.article_infogram__36FUW {
	display: block;
}

.article_map__8dIgD {
	width: 100%;
	height: 45vh;
	overflow: hidden;
}

.article_map__8dIgD > :first-child {
	height: 100%;
	width: 100%;
}

.article_gallery__2Uvp0 {
	height: auto;
}

.article_gallery__2Uvp0 > figcaption {
	margin-top: 5px;
	text-align: center;
}

.article_gallery-slider__2l18M {
	margin: auto 0px;
}

.article_gallery-slider__2l18M.article_hide__3zZbt > .article_gallery-image__2v2sl {
	opacity: 0;
}

.article_gallery-image__2v2sl {
	width: 100%;
	max-width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	margin-bottom: 20px;
}

.article_gallery-image__2v2sl img {
	object-fit: cover;
	width: 100%;
	max-width: 100% !important;
}

.article_gallery-image__2v2sl figcaption {
	margin-bottom: 20px;
}

/*.video:not(.auto) {
	position: relative;
	height: 0;
	padding-bottom: 56.25%;
	max-width: 100%;
}*/
.article_video__3JW9X iframe,
.article_video__3JW9X object,
.article_video__3JW9X embed,
.article_video__3JW9X video {
	top: 0;
	left: 0;
}

.article_video__3JW9X .article_brightcove__2BXFW {
	width: 100%;
	height: 496px;
}

.article_video__3JW9X video {
	max-width: 100%;
	margin: 0 auto;
	display: block;
}

.article_video__3JW9X.article_auto__2U58F {
	padding-bottom: unset;
	height: unset;
	overflow: unset;
}

.article_video__3JW9X.article_auto__2U58F iframe,
.article_video__3JW9X.article_auto__2U58F object,
.article_video__3JW9X.article_auto__2U58F embed,
.article_video__3JW9X.article_auto__2U58F video {
	position: unset;
	top: unset;
	left: unset;
	width: unset;
	height: unset;
}

.article_anchor__3rSf0 {
	width: 0;
	height: 0;
	opacity: 0;
}

.article_video__3JW9X {
	display: block;
	position: relative;
}

.article_video__3JW9X > figcaption {
	text-align: left;
	padding: 2% 20px;
	color: #000;
}

.article_video__3JW9X > cite {
	text-align: left;
	padding: 0px 20px;
	color: #000;
}

.article_twitter__35prM {
	overflow: auto;
}

.article_twitter__35prM > div {
	width: 100%;
	height: 100%;
}

.article_twitter__35prM > div > div {
	width: 100%;
	height: 100%;
}

.article_columns__wSI-Z {
	display: grid;
	grid-template-rows: 1fr;
	grid-column-gap: 15px;
	margin: 0px;
	max-width: 100%;
	padding: 0px;
	background-size: cover !important;
}

.article_columns__wSI-Z > div {
	width: 100%;
}

.article_columns__wSI-Z .article_gallery-slider__2l18M {
	width: 100% !important;
}

.article_columns__wSI-Z > div > figure.article_float-left__32lE8 {
	float: left;
	margin-bottom: 0px !important;
}

.article_columns__wSI-Z > div > figure.article_float-right__EfXwh {
	float: right;
	margin-bottom: 0px !important;
}

.article_columns__wSI-Z > div > * {
	max-width: 100% !important;
}

.article_column-1__3xf5B {
	display: grid;
	grid-template-rows: 1fr;
	grid-gap: 0px 0px;
	gap: 0px 0px;
	grid-template-areas: '.';
}

.article_column-2__3gBtz {
	grid-template-areas: '. .';
}

.article_column-3__2ToAQ {
	grid-template-areas: '. . .';
}

.article_column-4__3NXoQ {
	grid-template-areas: '. . . .';
}

.article_collapse__1xJtA {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: stretch;
}

.article_advert__KpV4N {
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
}

.article_advert__KpV4N img {
	object-fit: contain;
	min-height: 100%;
	overflow: hidden;
}

.article_tabs__3hyah {
	padding: 0px;
}

.article_tabs__3hyah > div {
	display: flex;
	flex-direction: column;
}

.article_tabs__3hyah .article_tab__2b5bj {
	height: 100%;
	min-width: 100px;
	background: white;
	border: none;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	z-index: 100;
	border: 1px solid transparent;
}

.article_tab__2b5bj.article_selected__1_IML {
	cursor: unset;
	border-color: #eaeaea;
}

.article_tabs__3hyah nav {
	min-height: unset;
	display: flex;
}

.article_tabs__3hyah nav > div {
	padding: 5px 25px;
}

.article_tabs__3hyah .article_content__23xl0 {
	min-height: 100px;
}

/*.multicol .component p,
.multicol figure {
	page-break-inside: avoid;
	break-inside: avoid-column;
	display: inline-table;
	overflow: hidden;
}*/

.article_multicol__1QeAM figure {
	display: contents;
}

.article_multicol__1QeAM .article_component__UtfVR p {
}

@media screen and (max-width: 767px) {
	.article_advert__KpV4N {
		min-height: 100%;
		max-height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

@media screen and (min-width: 767px) {
	.article_advert__KpV4N {
		min-height: 100%;
		max-height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

@media print {
	.article_canvas__2QPdB {
		display: block;
		overflow-y: visible;
	}
}

.lightbox_lightbox__2ojta {
	position: fixed;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 0;
	background: rgba(0, 0, 0, 0.9);
	z-index: 500;
	width: 100%;
	max-width: 100%;
	height: 100%;
	opacity: 1;
	padding: 0;
	transition: opacity ease-out 220ms, z-index ease-out 250ms;
}

.lightbox_lightbox__2ojta.lightbox_hide__3glqk {
	z-index: -100;
	opacity: 0;
}

.lightbox_lightbox__2ojta .lightbox_container__2ppsD {
	width: 90%;
	height: 90%;
	max-height: 90%;
	min-height: 90%;
	max-width: 90%;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 30px calc(100% - 30px);
	grid-gap: 0px 0px;
	gap: 0px 0px;
	overflow: hidden;
	grid-template-areas:
		'.'
		'.';
}

.lightbox_lightbox__2ojta .lightbox_container__2ppsD > div {
	padding: 0px 20px;
}

.lightbox_lightbox__2ojta .lightbox_container__2ppsD > :first-child {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.lightbox_lightbox__2ojta .lightbox_container__2ppsD > :first-child button {
	width: 24px;
	height: 24px;
	padding: 0;
	border: none;
	background: none;
}

.lightbox_lightbox__2ojta .lightbox_container__2ppsD .lightbox_component__29Y43 {
	position: relative;
	max-height: 100%;
	max-width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.lightbox_arrow__1tIIs {
	position: absolute;
	top: 50%;
	margin: 0 auto;
	width: 24px;
	height: 24px;
	padding: 0;
	border: none;
	background: none;
}

.lightbox_arrow__1tIIs.lightbox_left__aHZhw {
	left: 0;
	margin-left: 10px;
}

.lightbox_arrow__1tIIs.lightbox_right__3I37N {
	right: 0;
	margin-right: 10px;
}

.lightbox_component__29Y43 .lightbox_image__G-BCf {
	max-width: 500px;

	overflow: hidden;
}

.lightbox_component__29Y43 .lightbox_image__G-BCf img {
	display: block;
	object-fit: contain;
	width: auto;
	max-width: 100%;
	height: auto;
	margin: auto;
}

.lightbox_component__29Y43 .lightbox_image__G-BCf figcaption {
	color: #fff;
	font-size: 0.9rem;
	max-width: 100%;
	max-height: 100%;
	margin-top: 8px;
}

.lightbox_component__29Y43 .lightbox_image__G-BCf small {
	color: #fff;
	font-size: 0.8rem;
	font-style: italic;
	max-width: 100%;
	max-height: 100%;
	margin-top: 8px;
}

.Authenticate_authenticate__3cxHd {
	width: 100vw;
	height: 100vh;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	align-content: space-between;
	max-width: 350px;
	margin: 0 auto;
}

.Authenticate_authenticate__3cxHd > img {
	height: 7.5vh;
}

.Authenticate_authenticate__3cxHd > input {
	border-radius: 0px;
	border: none;
	border-bottom: solid rgba(24, 24, 24, 0.5) 2px;
	height: 50px;
	width: 90%;
	margin-bottom: 35px;
	font-size: 12pt;
	color: #000;
}

.Authenticate_authenticate__3cxHd > input::-webkit-input-placeholder {
	color: rgba(24, 24, 24, 0.9);
	font-size: 13pt;
}

.Authenticate_authenticate__3cxHd > input::placeholder {
	color: rgba(24, 24, 24, 0.9);
	font-size: 13pt;
}

.Authenticate_authenticate__3cxHd > input:invalid {
	border: none;
	border-color: #fff;
	box-shadow: none;
	background: none;
	border-bottom: solid rgba(255, 0, 0, 0.8) 2px;
}

.Authenticate_close-btn__3qJHH {
	width: 40px;
	height: 40px;
	position: absolute;
	right: 5px;
	top: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.Authenticate_close-btn__3qJHH > img {
	cursor: pointer;
}

.Authenticate_authenticate__3cxHd > .Authenticate_header__2d8zb {
	font-family: Georgia, 'Times New Roman', Times, serif;

	font-size: 1.3rem;
	line-height: 32px;
	font-weight: 500;
}

.Authenticate_authenticate__3cxHd > .Authenticate_header__2d8zb > h2 {
	text-align: center;
}

.Authenticate_authenticate__3cxHd > .Authenticate_subheader__18WCJ {
	font-family: Arial, Helvetica, sans-serif;

	font-size: 1.2rem;
	line-height: 22px;
	font-weight: 200;
	margin: 0px 10%;
	text-align: center;
	color: rgb(41, 41, 41);
}

.Authenticate_authenticate__3cxHd > .Authenticate_message__1o-uR {
	font-family: Arial, Helvetica, sans-serif;
	margin-top: 40px;
	width: 100%;
	display: flex;

	justify-content: center;
}

.Authenticate_authenticate__3cxHd > .Authenticate_message__1o-uR > a {
	color: #000;
	text-decoration: none;
	font-weight: 800;
}

.Authenticate_authenticate__3cxHd > .Authenticate_sign-up__jrEoB {
	margin-top: 60px;
	min-height: 40px;
	height: 40px;
	font-size: 16px;
}

.Authenticate_sign-up__jrEoB {
	width: 100%;
	height: 100%;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	align-content: space-between;
}

.Authenticate_sign-up__jrEoB > input {
	border-radius: 0px;
	border: none;
	border-bottom: solid rgba(24, 24, 24, 0.5) 2px;
	height: 50px;
	width: 90%;
	margin-bottom: 35px;
	font-size: 12pt;
	color: #000;
}

.Authenticate_sign-up__jrEoB > input::-webkit-input-placeholder {
	color: rgba(24, 24, 24, 0.9);
	font-size: 13pt;
}

.Authenticate_sign-up__jrEoB > input::placeholder {
	color: rgba(24, 24, 24, 0.9);
	font-size: 13pt;
}

.Authenticate_sign-up__jrEoB > input:invalid {
	border: none;
	border-color: #fff;
	box-shadow: none;
	background: none;
	border-bottom: solid rgba(255, 0, 0, 0.8) 2px;
}

.Authenticate_sign-up__jrEoB > button {
	border: none;

	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	flex-wrap: nowrap;

	cursor: pointer;
	min-height: 50px;
	height: 50px;
	width: 90%;

	margin: 0px 50px;
	padding: 0px 20px;

	font-size: 18px;
	border-radius: 6px;
	background: linear-gradient(145deg, #e6e6e6, #ffffff);
	box-shadow: 6px 6px 12px #d9d9d9, -6px -6px 12px #ffffff;
}

.Authenticate_provider-btn__1f02a {
	cursor: pointer;
	width: 60%;
	min-height: 40px;
	height: 40px;

	border-radius: 4px;
	border: 1px solid rgb(168, 168, 168);
	margin: 10px 0px;

	display: flex;
	flex-direction: row;
	align-items: center;

	background: rgb(255, 255, 255) none repeat scroll 0% 0%;
}

.Authenticate_provider-btn__1f02a > :first-child {
	width: 35px;
	height: 35px;
	margin: 5px;

	border-radius: 2px;
	background-color: #fff;

	display: flex;
	align-items: center;
	justify-content: center;
}

.Authenticate_provider-btn__1f02a > p {
	display: flex;
	width: calc(100% - 40px);
	align-items: center;
	margin-left: 5px;
	letter-spacing: 0.2px;
	font-size: 14px;
	font-weight: 200;
	color: rgb(41, 41, 41);
	font-family: 'Roboto';
}

.Authenticate_provider-btn__1f02a > :first-child > img {
	width: 18px;
	height: 18px;
}

.Authenticate_authenticate-email__3s90_ {
	width: 100vw;
	height: 100vh;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	align-content: space-between;
}

.Authenticate_authenticate-email__3s90_ > img {
	height: 7.5vh;
}

.Authenticate_authenticate-email__3s90_ > h2 {
	font-family: Georgia, 'Times New Roman', Times, serif;

	font-size: 28px;
	line-height: 32px;
	font-weight: 500;
	margin-bottom: 50px;
}

.Authenticate_authenticate-email__3s90_ > .Authenticate_subheader__18WCJ {
	font-family: Arial, Helvetica, sans-serif;

	font-size: 16px;
	line-height: 22px;
	margin: 0px 10%;
	text-align: center;
	color: rgb(41, 41, 41);
}

.Authenticate_authenticate-email__3s90_ > label {
	font-family: Arial, Helvetica, sans-serif;

	font-size: 16px;
	margin-bottom: 50px;

	font-weight: 200;
}

.Authenticate_authenticate-email__3s90_ > input {
	background: transparent;
	border: none;
	width: 60%;
	border-bottom: 1px solid rgba(0, 0, 0, 0.2);

	text-align: center;
}

.Authenticate_authenticate-email__3s90_ > input:focus {
	border-bottom: 1px solid rgba(0, 0, 0, 1);
}

.Authenticate_authenticate-email__3s90_ > input:invalid {
	border: none;
	border-bottom: 2px solid red;
	box-shadow: none;
}

.Authenticate_authenticate-email__3s90_ > .Authenticate_invalid__3swVW {
	border: none;
	border-bottom: 2px solid red;
	box-shadow: none;
}

.Authenticate_authenticate-email__3s90_ > small {
	color: red;
	margin-top: 8px;
}

.Authenticate_authenticate-email__3s90_ > button {
	margin-top: 40px;
	margin-bottom: 40px;
	border: none;
	background-color: #000;
	border-radius: 6px;
	width: 120px;
	height: 40px;
	color: #fff;
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

.Authenticate_authenticate-email__3s90_ > .Authenticate_back__1_H5H {
	font-family: 'Roboto';
	font-size: 14px;
	cursor: pointer;
}

.Authenticate_authenticate-email__3s90_.Authenticate_sign-up__jrEoB > img {
	margin-bottom: 20px;
}

.Authenticate_authenticate-email__3s90_.Authenticate_sign-up__jrEoB > button:disabled {
	opacity: 0.5;
	background: #3d3d3d !important;
}

.Authenticate_authenticate-email__3s90_.Authenticate_sign-up__jrEoB > input {
	background: transparent;
	width: 80%;
	max-width: 400px;
	border: 1px solid rgba(0, 0, 0, 0.2);
	border-radius: 8px;
	padding-left: 24px;
}

.Authenticate_authenticate-email__3s90_.Authenticate_sign-up__jrEoB > input.Authenticate_invalid__3swVW {
	border: none;
	border: 2px solid red;
	box-shadow: none;
}

.Authenticate_authenticate-email__3s90_.Authenticate_sign-up__jrEoB > input::-webkit-input-placeholder {
	color: rgba(0, 0, 0, 0.2);
}

.Authenticate_authenticate-email__3s90_.Authenticate_sign-up__jrEoB > input::placeholder {
	color: rgba(0, 0, 0, 0.2);
}

.Purchases_purchases__3R-0c {
	height: calc(
		100vh - var(--bottom-bar-height) - var(--navbar-height) -
			env(safe-area-inset-bottom, var(--safe-area-inset))
	);
	overflow: auto;
	padding-top: var(--navbar-height);
}

.Purchases_card-list-container__-WWOZ {
	display: flex;
	flex-direction: column;
	padding: 10px 20px;
}

.Purchases_card-item__3dNSc {
	cursor: pointer;
	display: flex;
	min-height: 50px;
	flex-direction: column;
	flex-wrap: nowrap;
	padding: 15px 20px;
	margin: 10px 0px;
	border-radius: 8px;
	background: #ffffff;
	box-shadow: 5px 5px 10px #f0f0f0, -5px -5px 10px #ffffff;
	border: 1px solid #e0e4ea;
	margin: 10px;
}

.Purchases_card-item__3dNSc:hover {
	box-shadow: 5px 5px 10px #cfcfcf, -5px -5px 10px #ffffff;
}

.Purchases_card-item__3dNSc > div {
	width: 100%;
	min-width: 0;
	overflow: hidden;
	text-overflow: clip;
	white-space: wrap;
}

.Purchases_card-item__3dNSc > .Purchases_title__3JIGs {
	font-size: 14px;
}

.Purchases_card-item__3dNSc > .Purchases_description__1fc8M {
	margin-top: auto;
	font-size: 11px;
}

@media screen and (min-width: 769px) {
	.Purchases_purchases__3R-0c {
		display: flex;
		height: calc(
			100vh - env(safe-area-inset-bottom, var(--safe-area-inset))
		);
		overflow: none;
	}

	.Purchases_purchases__3R-0c > div {
		width: 100%;
	}

	.Purchases_purchases__3R-0c > footer {
		display: none !important;
	}
}

@media screen and (max-width: 767px) {
	.Purchases_purchases__3R-0c {
		height: calc(
			100vh - var(--bottom-bar-height) -
				env(safe-area-inset-bottom, var(--safe-area-inset))
		);
		overflow: auto;
	}

	.Purchases_home__2BK02 > footer {
		display: flex !important;
	}
}

.Purchase_purchase-issue__eoi8L {
	height: 100%;
	width: 100vw;

	overflow: auto;

	display: flex;
	flex-direction: column;
}

.Purchase_purchase-issue-loading__Y1tKc {
	height: 100vh;
	width: 100vw;

	overflow: auto;

	display: flex;
	flex-direction: column;
}

.Purchase_cover-container__1DIQ4 {
	position: relative;
	height: 60%;
	overflow: hidden;
}

.Purchase_cover-blur__3-dtB {
	z-index: -1;
	position: absolute;
	filter: blur(8px);
	-webkit-filter: blur(8px);

	/* Full height */
	width: 100%;
}

.Purchase_cover-container__1DIQ4 > div {
	display: inline-block;
	width: 100%;
	height: 100%;
}

.Purchase_cover-container__1DIQ4 > div {
	display: flex;
	width: 100%;
	height: 100%;
}

.Purchase_checkout-container__2EUJS {
	z-index: 999;
	position: fixed;
	top: 0;
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	background: rgba(0, 0, 0, 0.5);
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

.Purchase_checkout__2MsxS {
	width: 90%;
	max-width: 500px;
	padding: 20px;
	background-color: rgb(247, 250, 252);
	border-radius: 8px;
}

.Purchase_checkout__2MsxS > label {
	margin-bottom: 20px;
}

.Purchase_checkout__2MsxS > label > h1 {
	font-size: 16pt;
	margin-bottom: 10px;
}

.Purchase_checkout__2MsxS > button {
	margin-top: 20px;
	border: none;
	font-weight: bold;
	min-height: 50px;
	height: 50px;
	background: black;
	color: white;
	width: 100%;
	border-radius: 6px;
}

.Purchase_cover__2hIdN {
	width: 30vh;
	margin: auto;
	border-radius: 3px;

	background-color: transparent;
	background-color: #ffffff;
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-position: center;
	background-size: cover;
	box-shadow: 3px 4px 28px -1px rgba(0, 0, 0, 0.75);
}

.Purchase_cover-blur__3-dtB > img {
	height: 100%;
	width: 100%;

	/* Center and scale the image nicely */
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.Purchase_purchase-issue__eoi8L > .Purchase_details__1C5PG {
	height: 40%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.Purchase_purchase-button-container__6qnhn {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	justify-content: center;
}

.Purchase_purchase-issue__eoi8L > .Purchase_details__1C5PG > h1 {
	font-size: 15pt;
	margin-top: 20px;
	margin-bottom: 10px;
}

.Purchase_purchase-issue__eoi8L > .Purchase_details__1C5PG > p {
	font-size: 11pt;
	margin: 10px 0px;
	color: gray;
}

.Purchase_purchase-issue__eoi8L > .Purchase_details__1C5PG > :last-child {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	justify-content: center;
	width: 80%;
	max-width: 300px;
	margin-top: 20px;
}

.Purchase_purchase-issue__eoi8L > .Purchase_details__1C5PG > :last-child > div,
.Purchase_purchase-issue__eoi8L > .Purchase_details__1C5PG > :last-child > a {
	width: 100%;
	margin-bottom: 20px;
}

.Purchase_purchase-issue__eoi8L > .Purchase_details__1C5PG > :last-child > div > :first-child,
.Purchase_purchase-issue__eoi8L > .Purchase_details__1C5PG > :last-child > a > :first-child {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: row;
	flex-wrap: nowrap;
	text-decoration: none;
	color: #fff;

	cursor: pointer;
	min-height: 50px;
	height: 50px;

	font-size: 18px;
	border-radius: 6px;

	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

.Purchase_purchase-button__3ofKX > :first-child {
	background: black;
}

.Purchase_subscribe-button__2wJGJ > :first-child {
	background: #3d3d3d;
}

.Purchase_subscription__26T-l {
	height: 100vh;
	width: 100vw;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	padding-top: 60px;
}

.Purchase_subscription__26T-l > img {
	height: 7.5vh;
}

.Purchase_subscription__26T-l > .Purchase_header__1tjm_ {
	width: 90%;
	display: flex;

	align-items: center;
	justify-content: center;

	font-family: Georgia, 'Times New Roman', Times, serif;
	font-size: 28px;
	line-height: 32px;
	font-weight: 500;
}

.Purchase_subscription__26T-l > .Purchase_subheader__2G-dk {
	width: 90%;
	display: flex;

	align-items: center;
	justify-content: center;

	font-family: Arial, Helvetica, sans-serif;
	font-size: 16px;
	line-height: 22px;
	font-weight: 200;
	text-align: center;
	color: rgb(41, 41, 41);
	margin-bottom: 20px;
}

.Purchase_subscription-item__3OZwg {
	border-radius: 8px;
	height: 80px;
	width: 90%;
	max-width: 500px;
	cursor: pointer;
	display: flex;
	flex-direction: row;
	margin-bottom: 20px;
	border: 1px solid rgb(168, 168, 168);
}

.Purchase_subscription-item__3OZwg > :first-child {
	width: 70%;
	height: 100%;
	display: flex;
	flex-direction: column;
}

.Purchase_subscription-item__3OZwg > :first-child > div {
	width: 100%;
	flex: 1 1;
	height: 50%;
	display: flex;
	align-items: center;
}

.Purchase_name__ppXO8 {
	padding: 0px 20px;
	font-size: 12pt;
}

.Purchase_description__jfMpW {
	padding: 0px 20px;
	font-size: 10pt;
}

.Purchase_subscription-item__3OZwg > :last-child {
	width: 30%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.Purchase_close-btn__3Zs74 {
	width: 40px;
	height: 40px;
	position: absolute;
	right: 5px;
	top: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.Purchase_close-btn__3Zs74 > img {
	cursor: pointer;
}


.Purchase_loader__1ZIVX {
	border-radius: 80%;
	width: 30px;
	height:30px;
	-webkit-animation: Purchase_spin__7tswd 1s linear infinite;
	        animation: Purchase_spin__7tswd 1s linear infinite;
  }
  
  @-webkit-keyframes Purchase_spin__7tswd {
	0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
	100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
  }
  
  @keyframes Purchase_spin__7tswd {
	0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
	100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
  }
.Issue_article-container__1LxHK {
	display: flex;
	flex-direction: column;

	height: 100vh;
	max-width: 1024px;
	margin: auto;
}

.Issue_article-container__1LxHK > div {
	width: 100%;
}

.Issue_issue-articles__2PJen {
	height: calc(
		100vh - var(--bottom-bar-height) -
			env(safe-area-inset-bottom, var(--safe-area-inset))
	);
}

.Issue_image-modal__oEsnv {
	position: fixed;
	top: 0;
	width: 100vw;
	height: 100vh;
	z-index: 800;
	background: black;
	display: flex;
	flex-direction: column;
}
.Issue_image-modal__oEsnv > div {
	display: flex;
	width: 100%;
}

.Issue_image-modal__oEsnv > :first-child {
	justify-content: flex-end;
	align-items: center;
	height: var(--navbar-height);
	padding-right: 10px;
}

.Issue_image-modal__oEsnv > .Issue_container__zwKdw {
	display: flex;
	justify-content: center;
	align-items: center;
	height: calc(
		100vh - var(--navbar-height) - var(--bottom-bar-height) -
			env(safe-area-inset-bottom, var(--safe-area-inset))
	);
}

.Issue_image-modal__oEsnv > .Issue_container__zwKdw > div {
	width: 100%;
	max-height: calc(
		100vh - var(--navbar-height) - var(--bottom-bar-height) -
			env(safe-area-inset-bottom, var(--safe-area-inset))
	);
	overflow: auto;
}

.Issue_issue-navigator-component__3H3z0 {
	width: 40px;
	height: calc(100% - var(--navbar-height));
	z-index: 500;
	position: absolute;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	background: rgb(255, 255, 255, 0.5);
	transition: background ease-out 200ms;
}

.Issue_issue-navigator-component__3H3z0 > div {
	background: rgb(0, 0, 0, 0.5);
	height: 24px;
	width: 24px;
	margin: auto;
}

.Issue_issue-navigator-component__3H3z0.Issue_left__1siwo {
	left: 0;
	margin-left: 0vw;
}

.Issue_issue-navigator-component__3H3z0.Issue_left__1siwo > div {
	-webkit-mask: url(/static/media/arrow-left.842e761b.svg);
	        mask: url(/static/media/arrow-left.842e761b.svg);
	-webkit-mask-size: cover;
	        mask-size: cover;
}

.Issue_issue-navigator-component__3H3z0.Issue_right__3Z11P {
	right: 0;
}

.Issue_issue-navigator-component__3H3z0.Issue_right__3Z11P > div {
	-webkit-mask: url(/static/media/arrow-right.e1da7cbe.svg);
	        mask: url(/static/media/arrow-right.e1da7cbe.svg);
	-webkit-mask-size: cover;
	        mask-size: cover;
}

.Issue_issue-navigator-component__3H3z0:hover {
	background: rgb(0, 0, 0, 1);
}

.Issue_issue-navigator-component__3H3z0:hover > div {
	background: #fff;
}

.Issue_image-modal-content__1FHxi {
	width: 100%;
	max-height: calc(
		100vh - var(--navbar-height) - var(--bottom-bar-height) -
			env(safe-area-inset-bottom, var(--safe-area-inset))
	);
	object-fit: contain;
}

.Issue_image-modal-x-icon__3obVq > button {
	-webkit-mask: url(/static/media/x-icon.6c9a4ba7.svg);
	        mask: url(/static/media/x-icon.6c9a4ba7.svg);
	background: white;
	margin: auto;
	height: 24px;
	width: 24px;
}

.Issue_progress-bar-container__2Ziqb {
	width: 100%;
	height: 5px;
	background: #000;
	box-shadow: 9px 10px 6px -6px rgba(0, 0, 0, 0.77);
	border-bottom: 1px solid #eee;
}

.Issue_progress-bar__1D0RZ {
	width: 50%;
	height: 100%;
	background: #000;
	transition: width 350ms;
}

.Issue_issue-toc__2FRbI {
	/*display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: flex-start;*/

	left: 0px;
	position: absolute;
	height: 100%;
	width: 80%;
	overflow: auto;
	font-size: 10pt;
	z-index: 10;
	box-shadow: 0px 4px 5px -1px rgba(0, 0, 0, 0.49);

	-webkit-transform: translateX(-100%);

	        transform: translateX(-100%);

	transition: -webkit-transform 300ms ease-out;

	transition: transform 300ms ease-out;

	transition: transform 300ms ease-out, -webkit-transform 300ms ease-out;

	background-color: rgb(255, 255, 255, var(--toc-opacity));
}

.Issue_issue-toc__2FRbI.Issue_open__1g55G {
	-webkit-transform: translateX(0%);
	        transform: translateX(0%);
}

.Issue_issue-toc__2FRbI > div {
	cursor: pointer;
	display: flex;
	width: 100%;
	min-height: 60px;
	align-items: center;
	border-top: 1px solid rgba(0, 0, 0, 0.1);
	background-color: rgba(255, 255, 255, var(--toc-opacity));

	margin-bottom: 0px;
}

.Issue_issue-toc__2FRbI > .Issue_pressed__wxM9b {
	cursor: default;
	border-radius: 0px;
	background-color: rgba(255, 255, 255, var(--toc-opacity));
	box-shadow: inset 3px 3px 6px #d4d4d4, inset -3px -3px 6px #ffffff;
}

.Issue_issue-toc__2FRbI > div > p {
	/*margin: auto;*/
	text-align: left;
	padding-left: 10px;
	padding-right: 10px;
}

.Issue_articles-container__36aoa {
	position: relative;
	display: grid;
	height: 100%;
	width: 100%;
	z-index: 2;
	grid-template-columns: 0.2fr 0.8fr;
	grid-template-rows: 1fr;
	grid-gap: 0px 0px;
	gap: 0px 0px;
	grid-template-areas: '. .';
}

.Issue_issue-toc__2FRbI > :last-child {
	height: 15vh;
}

.Issue_not-available-image-mode___j3Vf {
	height: 100%;
	width: 100%;
	display: flex;
	background: white;
	justify-content: center;
}

.Issue_not-available-image-mode___j3Vf > div {
	background: white;
	height: 100%;
	width: 100%;
	max-width: 1024px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.Issue_not-available-image-mode___j3Vf > div > div {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.Issue_not-available-image-mode___j3Vf > div > div > h3 {
	font-size: 2rem;
	text-align: center;
}

.Issue_not-available-image-mode___j3Vf > div > div > h4 {
	text-align: center;
	font-size: 1rem;
}

.Issue_toc__NMMa1 {
	width: 100%;
	height: 100%;
	overflow: auto;
	border-right: 1px solid #eee;
	list-style-position: inside;
}

.Issue_toc__NMMa1 > li {
	display: block;
	display: list-item;
	padding: 1.4em;
	font-size: 0.9rem;
	line-height: 1.5;
}

.Issue_toc__NMMa1 > .Issue_item__HMaFe {
	cursor: pointer;
	transition: all ease-out 200ms;
}

/*.toc > .item > h4 {
	font-size: 0.9rem;
	line-height: 1.5;
}

.toc > .item > small {
	padding-top: 5px;
	font-size: 0.6rem;
}*/

/* smartphones, touchscreens */
/*@media (hover: none) and (pointer: coarse) {
	.issue-navigator-component {
		display: none;
		z-index: 0;
	}
}*/

@media screen and (max-width: 767px) {
	.Issue_articles-container__36aoa {
		grid-template-columns: 0fr 1fr;
	}
	.Issue_issue-navigator-component__3H3z0.Issue_left__1siwo {
		margin-left: 0vw;
	}
}

@media screen and (min-width: 769px) {
	.Issue_issue-navigator-component__3H3z0.Issue_left__1siwo {
		margin-left: 20vw;
	}
}

@media screen and (min-width: 767px) {
	.Issue_issue-articles__2PJen {
		height: 100vh;
	}
}

@media screen and (min-width: 1200px) {
	.Issue_issue-navigator-component__3H3z0 {
		display: flex;
	}
}

@media (pointer: coarse) {
	.Issue_issue-navigator-component__3H3z0 {
		display: none;
		z-index: 0;
	}
}

@media screen and (min-width: 1700px) {
	.Issue_articles-container__36aoa {
		/*grid-template-columns: 0.2fr 0.8fr;*/
		grid-template-columns: 332px calc(100% - 332px);
	}
}

.ReplicaMode_replica-mode__1Q3WU {
	--toc-width: 240px;
}

.ReplicaMode_replica-mode__1Q3WU {
	width: 100%;
	height: 100%;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: calc(var(--navbar-height) + 5px) calc(
			100% - var(--navbar-height) - 5px
		);
	grid-gap: 0px 0px;
	gap: 0px 0px;
	grid-template-areas:
		'.'
		'.';
}

.ReplicaMode_replica-mode__1Q3WU.ReplicaMode_no-toolbar__13i2Y {
	grid-template-rows: 100%;
}

.ReplicaMode_navbar-container__3Abwx {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr 5px;
	grid-gap: 0px 0px;
	gap: 0px 0px;
	height: 55px;
	grid-template-areas:
		'.'
		'.';
}

.ReplicaMode_progress__eTqpN {
	height: var(--progress-bar-height);
	position: relative;
	width: 100%;
	border-bottom: 1px solid #eaeaea;
}

.ReplicaMode_progress__eTqpN .ReplicaMode_bar__Kvgrk {
	height: 100%;
	min-height: var(--progress-bar-height);
	transition: width ease-out 220ms;
}

.ReplicaMode_navbar__1QppY {
	width: 100vw;
	display: grid;
	height: 50px;
	grid-template-rows: 1fr;
	grid-gap: 0px 0px;
	gap: 0px 0px;
	z-index: 9999;
	grid-template-areas: '. .';
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.18), 0 1px 1px rgba(0, 0, 0, 0.03) !important;
}

.ReplicaMode_navbar__1QppY .ReplicaMode_logo__1vORg {
	height: 100%;
	max-width: 100%;
	object-fit: cover;
}

.ReplicaMode_navbar__1QppY > div {
	display: flex;
	max-height: calc(var(--navbar-height) - var(--progress-bar-height));
}

.ReplicaMode_navbar__1QppY > div:last-of-type {
	justify-content: left;
}

.ReplicaMode_navbar__1QppY > div:last-of-type {
	justify-content: right;
}

.ReplicaMode_navbar__1QppY .ReplicaMode_actions__hL0hB {
	height: 100%;
	display: flex;
	align-items: center;
}

.ReplicaMode_navbar__1QppY .ReplicaMode_actions__hL0hB button {
	width: 24px;
	height: 24px;
	margin: 0px 15px;
	padding: 0;
	border: none;
	background: none;
}

.ReplicaMode_content__1hUJH {
	height: 100%;
	display: flex;
	transition: width ease-out 220ms;
	position: relative;
	background: #2e3441;
}

.ReplicaMode_content__1hUJH .ReplicaMode_navigation-arrow__228kq {
	position: absolute;
	width: 50px;
	height: 50px;
	cursor: pointer;
	background: #fff;
	z-index: 200;
	top: 0;
	bottom: 0;
	margin: auto 0;
	cursor: pointer;
	border-radius: 50%;
	display: flex;
	opacity: 0.5;
	justify-content: center;
	align-items: center;
	border: 1px solid #eaeaea;
	transition: opacity ease-out 200ms;
}

.ReplicaMode_content__1hUJH .ReplicaMode_navigation-arrow__228kq:hover {
	opacity: 1;
}

.ReplicaMode_content__1hUJH .ReplicaMode_navigation-arrow__228kq.ReplicaMode_disabled__YlJsa {
	opacity: 0;
	display: none;
}

.ReplicaMode_content__1hUJH .ReplicaMode_navigation-arrow__228kq.ReplicaMode_left__1UZad {
	left: 0;
	margin-left: 20px;
}

.ReplicaMode_content__1hUJH .ReplicaMode_navigation-arrow__228kq.ReplicaMode_right__2BgCe {
	right: 0;
	margin-right: 20px;
}

.ReplicaMode_toc__15uL_ {
	height: 100%;
	width: var(--toc-width);
	background: white;
	transition: width 220ms;
	border-right: 1px solid rgba(195, 195, 195, 0.5);
}

.ReplicaMode_toc__15uL_ ol {
	height: 100%;
	overflow: auto;
}

.ReplicaMode_toc__15uL_.ReplicaMode_closed__3YJ2W {
	width: 0px;
	transition: width ease-out 220ms;
}

.ReplicaMode_toc__15uL_ ~ .ReplicaMode_content__1hUJH {
	width: calc(100% - var(--toc-width));
}

.ReplicaMode_toc__15uL_.ReplicaMode_closed__3YJ2W ~ .ReplicaMode_content__1hUJH {
	width: 100%;
}

.ReplicaMode_toc-item__mBwmI {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 20px 0px;
	cursor: pointer;
}

.ReplicaMode_toc-item__mBwmI span {
	padding: 6px;
	transition: background ease-out 220ms;
}

.ReplicaMode_toc-item__mBwmI figure {
	transition: background ease-out 220ms;
}

.ReplicaMode_toc-item__mBwmI:first-of-type {
	padding-top: 40px;
}

.ReplicaMode_toc-item__mBwmI img {
	width: 100%;
}

.ReplicaMode_toc-item__mBwmI.ReplicaMode_single__1yEAf {
	width: 100%;
	display: flex;
	align-items: center;
}

.ReplicaMode_toc-item__mBwmI.ReplicaMode_single__1yEAf.ReplicaMode_selected__2ksEs figure {
	background: #c4c4c4;
}

.ReplicaMode_toc-item__mBwmI.ReplicaMode_selected__2ksEs span {
	width: 25%;
	border-radius: 4px;
	background: rgba(195, 195, 195, 0.2);
	text-align: center;
}

.ReplicaMode_toc-item__mBwmI.ReplicaMode_single__1yEAf figure {
	width: 60%;
	margin-bottom: 15px;
}

.ReplicaMode_toc-item__mBwmI.ReplicaMode_single__1yEAf figure img {
	border: 1px solid rgba(195, 195, 195, 0.2);
	box-shadow: 0 1px 1px rgb(0 0 0 / 12%), 0 2px 2px rgb(0 0 0 / 12%),
		0 4px 4px rgb(0 0 0 / 12%);
}

.ReplicaMode_toc-item__mBwmI.ReplicaMode_double__2yNgf figure {
	max-width: 80%;
	display: flex;
	justify-content: center;
	border: 1px solid rgba(195, 195, 195, 0.2);
	box-shadow: 0 1px 1px rgb(0 0 0 / 12%), 0 2px 2px rgb(0 0 0 / 12%),
		0 4px 4px rgb(0 0 0 / 12%);
	margin-bottom: 15px;
}

.ReplicaMode_toc-item__mBwmI.ReplicaMode_double__2yNgf figure img {
	border: 0px solid rgba(195, 195, 195, 0.5);
}

.ReplicaMode_toc-item__mBwmI.ReplicaMode_double__2yNgf img {
	width: 50%;
}

.ReplicaMode_toc-item__mBwmI.ReplicaMode_double__2yNgf .ReplicaMode_single__1yEAf {
	box-shadow: none;
}

.ReplicaMode_toc-item__mBwmI.ReplicaMode_double__2yNgf .ReplicaMode_single__1yEAf > img {
	width: 100%;
	box-shadow: 0 1px 1px rgb(0 0 0 / 12%), 0 2px 2px rgb(0 0 0 / 12%),
		0 4px 4px rgb(0 0 0 / 12%);
}

/*.pages img {
	max-height: 100%;
	max-width: 100%;
	object-fit: cover;
}*/

/*Single page*/
.ReplicaMode_single-page__38KMv {
	width: 100vw;
	height: 100%;
}

.ReplicaMode_single-page__38KMv > :first-child {
	width: 100%;
	height: 100%;
}

.ReplicaMode_single-page__38KMv .ReplicaMode_page__1NT-f {
	cursor: grab;
	display: flex;
	width: 100%;
	height: 100%;
	justify-content: center;
	align-items: center;
}

/*
figure {
  width: 600px;
  background-repeat: no-repeat;
}

figure:hover img {
  opacity: 0;
}

img {
  display: block;
  width: 100%;
  pointer-events: none;
}

*/

.ReplicaMode_single-page__38KMv img {
	display: block;
	max-height: 100%;
	max-width: 100%;
	object-fit: cover;
	pointer-events: none;
}

.ReplicaMode_page__1NT-f a {
	position: absolute;
	width: 40px;
	height: 40px;
	margin-bottom: 20px;
	border-radius: 50%;
	background: #fff;
	border: 0px;
	bottom: 0;
	opacity: 0.2;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: opacity ease-out 220ms;
}

.ReplicaMode_page__1NT-f a > div {
	width: 20px;
	height: 20px;
}

.ReplicaMode_page__1NT-f:hover a {
	opacity: 1;
}

.ReplicaMode_page__1NT-f a.ReplicaMode_left__1UZad {
	left: 0;
	margin-left: 20px;
}

.ReplicaMode_page__1NT-f a.ReplicaMode_right__2BgCe {
	right: 0;
	margin-right: 20px;
}

/*Double page*/
.ReplicaMode_double-page-container__j7w4f {
	width: 100vw;
	height: 100%;
	border: 1px solid rgba(195, 195, 195, 0.2);
	margin: 0px auto;
}

.ReplicaMode_double-page-handler__1GLCf {
	/*display: flex;
	justify-content: center;
	align-items: center;*/
	height: 100%;
	max-height: 100%;
}

.ReplicaMode_double-page-handler__1GLCf .ReplicaMode_left__1UZad figure > :first-child,
.ReplicaMode_double-page-handler__1GLCf .ReplicaMode_right__2BgCe figure > :first-child {
	margin-top: auto;
	margin-bottom: auto;
}

.ReplicaMode_double-page-handler__1GLCf .ReplicaMode_left__1UZad figure {
	justify-content: flex-end;
}

.ReplicaMode_double-page-handler__1GLCf .ReplicaMode_left__1UZad .ReplicaMode_action-bar__3c_P- {
	margin-right: 20vw;
}

.ReplicaMode_double-page-handler__1GLCf .ReplicaMode_left__1UZad figure img {
	margin-left: auto;
}

.ReplicaMode_double-page-handler__1GLCf .ReplicaMode_right__2BgCe figure {
	justify-content: flex-start;
}

.ReplicaMode_double-page-handler__1GLCf .ReplicaMode_right__2BgCe .ReplicaMode_action-bar__3c_P- {
	margin-left: 20vw;
}

.ReplicaMode_double-page-handler__1GLCf .ReplicaMode_right__2BgCe figure img {
	margin-right: auto;
}

.ReplicaMode_double-page-handler__1GLCf > :first-child {
	margin-top: auto;
	margin-bottom: auto;
	height: 100%;
}

.ReplicaMode_double-page-container__j7w4f > :first-child {
	height: 100%;
	width: 100%;
}

.ReplicaMode_double-page__fHYtf {
	display: grid;
	grid-auto-columns: 1fr;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1fr;
	grid-gap: 0px 0px;
	gap: 0px 0px;
	max-height: 100%;
	grid-template-areas: '. .';
}

.ReplicaMode_double-page__fHYtf > div {
	overflow: hidden;
	cursor: grab;
	width: 50vw;
	height: 100%;
	display: flex;
}

.ReplicaMode_double-page__fHYtf > :first-child {
	justify-content: flex-end;
}

.ReplicaMode_double-page__fHYtf figure {
	display: flex;
	position: relative;
}

.ReplicaMode_double-page__fHYtf img {
	object-fit: contain;
	max-height: 100%;
	max-width: 100%;
}

.ReplicaMode_double-page__fHYtf a {
	position: absolute;
	width: 40px;
	height: 40px;
	margin-bottom: 20px;
	border-radius: 50%;
	background: #fff;
	border: 1px solid #c3c3c3;
	bottom: 0;
	opacity: 0.2;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: opacity ease-out 220ms;
}

.ReplicaMode_double-page__fHYtf a:hover {
	opacity: 1;
}

.ReplicaMode_double-page__fHYtf a > div {
	width: 20px;
	height: 20px;
}

.ReplicaMode_double-page__fHYtf figure:hover a {
	opacity: 1;
}

.ReplicaMode_double-page__fHYtf a.ReplicaMode_left__1UZad {
	left: 0;
	margin-left: 20px;
}

.ReplicaMode_double-page__fHYtf a.ReplicaMode_right__2BgCe {
	right: 0;
	margin-right: 20px;
}

.ReplicaMode_double-page__fHYtf figure,
.ReplicaMode_single-page__38KMv figure {
	display: flex;
	width: 100%;
	height: 100%;
	justify-content: center;
	/*align-items: center;*/
	background-repeat: no-repeat;
	position: relative;
}

.ReplicaMode_double-page__fHYtf figure img,
.ReplicaMode_single-page__38KMv figure img {
	transition: opacity ease-out 220ms;
}

figure.ReplicaMode_image-container__1kxFG {
	cursor: zoom-in;
	transition: background-image ease-out 220ms;
}

.ReplicaMode_double-page__fHYtf figure.ReplicaMode_image-container__1kxFG:hover img,
.ReplicaMode_single-page__38KMv figure.ReplicaMode_image-container__1kxFG:hover img {
	opacity: 0;
}

.ReplicaMode_single-page__38KMv figure.ReplicaMode_clickable__LY7VC:hover,
.ReplicaMode_double-page__fHYtf img.ReplicaMode_clickable__LY7VC:hover {
	cursor: pointer;
}

.ReplicaMode_double-page__fHYtf .ReplicaMode_test__o4sEY {
	background: red;
}

.ReplicaMode_action-bar__3c_P- {
	background: rgba(0, 0, 0, 0.75);
	min-width: 60px;
	height: 50px;
	position: absolute;
	bottom: 0;
	margin-bottom: 5%;
	color: #fff;
	border-radius: 9px;
	display: flex;
	justify-content: center;
	align-items: center;
	opacity: 0;
	transition: opacity ease-out 220ms;
}

.ReplicaMode_action-bar__3c_P- button {
	width: 40px;
	height: 40px;
	background: transparent;
	border: none;
	border-radius: 5px;
	transition: background ease-out 220ms;
	margin: 0px 5px;
}

.ReplicaMode_action-bar__3c_P- button:hover,
.ReplicaMode_action-bar__3c_P- button.ReplicaMode_active__r8Wey {
	background: rgb(97, 97, 97);
}

.ReplicaMode_single-page__38KMv figure:hover .ReplicaMode_action-bar__3c_P-,
.ReplicaMode_double-page__fHYtf figure:hover .ReplicaMode_action-bar__3c_P- {
	opacity: 1;
}

.ReplicaMode_double-page__fHYtf figure:not(:hover) {
	background-image: none;
}

@media (hover: none) and (pointer: coarse) {
	.ReplicaMode_action-bar__3c_P- {
		opacity: 1;
		min-width: 35px;
		height: 35px;
		border-radius: 5px;
		bottom: 0;
		margin-bottom: 2%;
	}

	.ReplicaMode_action-bar__3c_P-.ReplicaMode_left__1UZad {
		left: 0;
		margin-left: 2%;
	}

	.ReplicaMode_action-bar__3c_P-.ReplicaMode_right__2BgCe {
		right: 0;
		margin-right: 2%;
	}

	.ReplicaMode_action-bar__3c_P- button {
		width: 35px;
		height: 35px;
		border: none;
		border-radius: 5px;
	}

	.ReplicaMode_action-bar__3c_P- button div {
		-webkit-mask-size: 24px 24px;
		mask-size: 24px 24px;
		-webkit-mask-repeat: no-repeat;
		mask-repeat: no-repeat;
	}
}

@media only screen and (max-width: 480px) {
	.ReplicaMode_navbar__1QppY {
		grid-template-columns: unset;
	}

	.ReplicaMode_toc__15uL_ {
		height: 100%;
		width: 100vw;
	}

	.ReplicaMode_toc__15uL_.ReplicaMode_closed__3YJ2W {
		width: 0px;
	}

	.ReplicaMode_toc__15uL_ ~ .ReplicaMode_content__1hUJH {
		width: 0px;
	}

	.ReplicaMode_toc__15uL_.ReplicaMode_closed__3YJ2W ~ .ReplicaMode_content__1hUJH {
		width: 100vw;
	}

	.ReplicaMode_page__1NT-f figure > :first-child {
		margin-top: auto !important;
		margin-bottom: auto !important;
	}
}

@media only screen and (min-width: 1400px) {
	.ReplicaMode_double-page-handler__1GLCf .ReplicaMode_left__1UZad figure > :first-child,
	.ReplicaMode_double-page-handler__1GLCf .ReplicaMode_right__2BgCe figure > :first-child {
		margin-top: unset;
		margin-bottom: unset;
	}
}

.replica_replica-page__3BAlS {
	display: block;

	/*width: 100%;*/
	position: relative;
}

.replica_replica-page__3BAlS img {
	display: block;
	/*position: absolute;*/
	object-fit: cover;
	opacity: 1;
	margin: auto;

	/*max-width: 100%;
    max-height: 100%;*/
}

.replica_replica-page__3BAlS canvas {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	opacity: 1;
	margin-left: auto;
	margin-right: auto;
	/*max-width: 100%;
    max-height: 100%;*/
}

.replica_replica-page__3BAlS svg {
	top: 0;
	left: 0;
	position: absolute;
	opacity: 0.5;
	/*visibility: hidden;*/
}

.replica_replica-page__3BAlS svg rect {
	cursor: pointer;
	fill: transparent;
	transition: fill ease-out 320ms;
}

.replica_replica-page__3BAlS svg rect:hover {
	cursor: pointer;
	fill: var(--hotspot-color-fill);
}

.ResponsiveMode_desktop_issue__TWE-4 {
	width: 100%;
	height: 100%;
}

.ResponsiveMode_desktop_issue__TWE-4 .ResponsiveMode_desktop_container__2o_X5 {
	position: relative;
	min-height: calc(100% - 5px - var(--navbar-height));
	height: calc(100% - 5px - var(--navbar-height));
	width: 100%;
}

.ResponsiveMode_desktop_issue__TWE-4 .ResponsiveMode_desktop_container__2o_X5 aside {
	transition: tranform ease-out 200ms;
}

.ResponsiveMode_desktop_issue__TWE-4 .ResponsiveMode_desktop_container__2o_X5 main {
	min-width: calc(100% - 300px);
	width: calc(100% - 300px);
	max-width: calc(100% - 300px);
	margin-left: 300px;
	transition: margin ease-out 200ms, width ease-out 200ms;
}

.ResponsiveMode_desktop_issue__TWE-4.ResponsiveMode_desktop_toc-close__7QhQc .ResponsiveMode_desktop_container__2o_X5 aside {
	-webkit-transform: translateX(-300px);
	        transform: translateX(-300px);
	transition: tranform ease-in 250ms;
}

.ResponsiveMode_desktop_issue__TWE-4.ResponsiveMode_desktop_toc-close__7QhQc .ResponsiveMode_desktop_container__2o_X5 main {
	min-width: 100%;
	width: 100%;
	margin-left: 0px;
}

.ResponsiveMode_desktop_navbar__2ou95 {
	grid-area: navbar;
	display: grid;
	background: black;
	z-index: 20;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1fr;
	grid-gap: 0px 0px;
	gap: 0px 0px;
	grid-template-areas: 'logo actions';
	padding: 0px 0px;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.18), 0 1px 1px rgba(0, 0, 0, 0.03) !important;
}

.ResponsiveMode_desktop_navbar__2ou95 button {
	width: 24px;
	height: 24px;
	margin: 0px 15px;
	padding: 0;
	border: none;
	background: none;
}

.ResponsiveMode_desktop_navbar__2ou95 .ResponsiveMode_desktop_logo__1fahB {
	grid-area: logo;
	display: flex;
	justify-content: center;
	align-items: center;
}

.ResponsiveMode_desktop_navbar__2ou95 .ResponsiveMode_desktop_actions__3KAhq {
	grid-area: actions;
}

.ResponsiveMode_desktop_navbar__2ou95 > :first-child {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.ResponsiveMode_desktop_navbar__2ou95 > :first-child img {
	cursor: pointer;
}

.ResponsiveMode_desktop_navbar__2ou95 > :last-child {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	padding-right: 15px;
}

.ResponsiveMode_desktop_navbar__2ou95 .ResponsiveMode_desktop_loading__CxSeI {
	width: 24px;
	height: 24px;
	cursor: wait;
}

.ResponsiveMode_desktop_navbar__2ou95 .ResponsiveMode_desktop_loading__CxSeI svg {
	display: flex;
	justify-content: center;
	align-items: center;
}

.ResponsiveMode_desktop_offline__21EuR {
	display: flex;
	justify-content: center;
	width: 24px;
	height: 100%;
}

.ResponsiveMode_desktop_offline__21EuR > * {
	width: 24px;
	height: 24px;
	margin: auto;
}

.ResponsiveMode_desktop_search-bar__2vn4L {
	display: flex;
	position: relative;
	height: 32px;
	margin-left: 7px;
	width: 30px;
	transition: width ease-out 220ms;
}

.ResponsiveMode_desktop_share-button__1OHd9 > div {
	-webkit-mask-size: 95%;
	        mask-size: 95%;
}

.ResponsiveMode_desktop_search-bar__2vn4L.ResponsiveMode_desktop_expanded__1cdm7 {
	width: 200px;
}

.ResponsiveMode_desktop_search-results__1hmuk {
	position: absolute;
	width: 200px;
	overflow-x: hidden;
	overflow-y: auto;
	top: 0;
	min-height: 32px;
	max-height: 300px;
	z-index: 100;
	background: white;
	right: 0;
	margin-right: 15px;
	border-radius: 2px;
	box-shadow: -2px 0 5px 0 rgb(0 0 0 / 40%);
}

.ResponsiveMode_desktop_search-results__1hmuk.ResponsiveMode_desktop_empty__1ji1d {
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
}

.ResponsiveMode_desktop_search-results__1hmuk.ResponsiveMode_desktop_empty__1ji1d > small {
	font-style: italic;
	font-size: 0.8rem;
	opacity: 0.5;
}

.ResponsiveMode_desktop_search-results__1hmuk ul {
	width: 100%;
}

.ResponsiveMode_desktop_search-results__1hmuk ul > :not(:last-child) {
	border-bottom: 1px solid #eaeaea;
}

.ResponsiveMode_desktop_search-results__1hmuk ul li {
	overflow: hidden;
	width: 100%;
	cursor: pointer;
}

.ResponsiveMode_desktop_search-results__1hmuk .ResponsiveMode_desktop_item__11HET {
	height: 60px;
	display: grid;
	grid-template-columns: 60px 1fr;
	grid-template-rows: 1fr;
	grid-gap: 0px 0px;
	gap: 0px 0px;
	grid-template-areas: '. .';
}

.ResponsiveMode_desktop_search-results__1hmuk .ResponsiveMode_desktop_image__28CDR {
	overflow: hidden;
	width: 60px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.ResponsiveMode_desktop_search-results__1hmuk .ResponsiveMode_desktop_image__28CDR img {
	object-fit: cover;
	width: 40px;
	height: 40px;
	overflow: hidden;
	border-radius: 4px;
}

.ResponsiveMode_desktop_search-results__1hmuk .ResponsiveMode_desktop_description__3M7eF {
	overflow: hidden;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	padding-right: 12px;
}

.ResponsiveMode_desktop_search-results__1hmuk .ResponsiveMode_desktop_description__3M7eF h4 {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	font-size: 0.8rem;
	max-width: calc(100%);
}

.ResponsiveMode_desktop_search-results__1hmuk .ResponsiveMode_desktop_description__3M7eF small {
	overflow: hidden;
	text-overflow: ellipsis;
	font-size: 0.7rem;
	font-style: italic;
	color: #c4c4c4;
	white-space: nowrap;
	margin-top: 4px;
}

.ResponsiveMode_desktop_search-bar__2vn4L .ResponsiveMode_desktop_icon__3XyZR {
	position: absolute;
	height: 100%;
	width: 18px;
	margin-left: 8px;
	display: flex;
	justify-content: center;
}

.ResponsiveMode_desktop_search-bar__2vn4L .ResponsiveMode_desktop_icon__3XyZR.ResponsiveMode_desktop_close__3Xh_U {
	margin-right: 8px;
	width: 18px;
	right: 0;
}

.ResponsiveMode_desktop_search-bar__2vn4L .ResponsiveMode_desktop_icon__3XyZR.ResponsiveMode_desktop_close__3Xh_U > div {
	-webkit-mask-size: 18px 18px;
	mask-size: 18px 18px;
}

.ResponsiveMode_desktop_search-bar__2vn4L input {
	width: 100%;
	height: 100%;
	padding-left: 32px;
	padding-right: 32px;
	border-radius: 4px;
	outline: none;
	border: 1px solid #c4c4c4;
	font-size: 0.9rem;
	color: #3d3d3d;
}

.ResponsiveMode_desktop_progress-bar__3amaU {
	height: 5px;
	border-bottom: 1px solid #eaeaea;
}

.ResponsiveMode_desktop_progress-bar__3amaU > div {
	height: 100%;
	transition: all ease-out 200ms;
}

.ResponsiveMode_desktop_toc__3C44r {
	min-width: 300px;
	width: 300px;
	height: 100%;
	position: absolute;
	overflow: auto;
	border-right: 1px solid #eee;
	list-style-position: inside;
}

.ResponsiveMode_desktop_toc__3C44r > li {
	display: block;
	display: list-item;
	padding: 1.4em;
	font-size: 0.9rem;
	line-height: 1.5;
}

.ResponsiveMode_desktop_toc__3C44r .ResponsiveMode_desktop_item__11HET {
	display: grid;
	grid-template-columns: 60px 1fr;
	grid-template-rows: 1fr;
	grid-gap: 0px 0px;
	gap: 0px 0px;
	grid-template-areas: '. .';
	cursor: pointer;
	transition: color ease-out 200ms, border ease-out 200ms;
	padding: 1rem;
}

.ResponsiveMode_desktop_toc__3C44r .ResponsiveMode_desktop_item__11HET .ResponsiveMode_desktop_item-content__1WkZB {
	height: 100%;
	padding-left: 8px;
	max-width: 100%;
	padding-right: 8px;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
}

.ResponsiveMode_desktop_toc__3C44r .ResponsiveMode_desktop_item__11HET p {
	font-size: 0.8rem;
	color: #c4c4c4;
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.ResponsiveMode_desktop_toc__3C44r .ResponsiveMode_desktop_item__11HET span {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.ResponsiveMode_desktop_toc__3C44r .ResponsiveMode_desktop_item__11HET small {
	font-size: 0.7rem;
	color: #c4c4c4;
	font-style: italic;
}

.ResponsiveMode_desktop_toc__3C44r .ResponsiveMode_desktop_image__28CDR {
	overflow: hidden;
	width: 60px;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.ResponsiveMode_desktop_toc__3C44r .ResponsiveMode_desktop_image__28CDR img {
	object-fit: cover;
	width: 40px;
	height: 40px;
	overflow: hidden;
	border-radius: 4px;
}

.ResponsiveMode_desktop_content__2FH9T {
	position: absolute;
	transition: width ease-out 200ms;
}

.ResponsiveMode_desktop_content__2FH9T .ResponsiveMode_desktop_toc-toggle__3dYtT {
	position: absolute;
	bottom: 0;
	margin-bottom: 50px;
	margin-left: 20px;
	width: 50px;
	height: 50px;
	border-radius: 50%;
	z-index: 100;
	border: 0px;
	background: #fff;
	box-shadow: -2px 0 5px 0 rgb(0 0 0 / 40%);
}

.ResponsiveMode_desktop_font-slider__jW4ry {
	float: left;
	position: fixed;
	min-width: 325px;
	width: 25%;
	max-width: 400px;
	height: var(--navbar-height);
	background: white;
	z-index: 200;
	right: 0;
	margin-top: calc(var(--navbar-height) + var(--progress-bar-height));
	box-shadow: -2px 0 5px 0 rgb(0 0 0 / 40%);
	display: grid;
	grid-template-columns: 32px 1fr 32px;
	grid-template-rows: 1fr;
	grid-gap: 0px 0px;
	gap: 0px 0px;
	grid-template-areas: '. . .';
	padding: 0px 10px;
	transition: all ease-out 200ms;
}

.ResponsiveMode_desktop_font-slider__jW4ry.ResponsiveMode_desktop_hidden__1fkCn {
	height: 0px !important;
	grid-template-rows: 0fr;
}

.ResponsiveMode_desktop_font-slider__jW4ry.ResponsiveMode_desktop_hidden__1fkCn button,
.ResponsiveMode_desktop_font-slider__jW4ry.ResponsiveMode_desktop_hidden__1fkCn div {
	height: 0px;
	display: none;
	transition: all ease-in 50ms;
}

.ResponsiveMode_desktop_font-slider__jW4ry div {
	display: flex;
	justify-content: center;
	align-items: center;
}

.ResponsiveMode_desktop_font-slider__jW4ry button {
	border: none;
	background: none;
	min-width: 40px;
	width: 40px;
	min-height: 30px;
	height: 30px;
}

.ResponsiveMode_desktop_font-slider__jW4ry button:disabled {
	opacity: 0.5;
	cursor: not-allowed;
}

/* Special styling for WebKit/Blink */
.ResponsiveMode_desktop_font-slider__jW4ry input[type='range'] {
	border-radius: 8px;
	height: 7px;
	width: 80%;
	outline: none;
	transition: background 450ms ease-in;
	-webkit-appearance: none;
}

.ResponsiveMode_desktop_font-slider__jW4ry input[type='range']::-webkit-slider-thumb {
	-webkit-appearance: none;
	cursor: ew-resize;
	width: 16px;
	height: 16px;
	border-radius: 50%;
	background: #3d3d3d;
}

.ResponsiveMode_desktop_font-slider__jW4ry input[type='range']::-moz-range-thumb {
	cursor: ew-resize;
	width: 16px;
	height: 16px;
	border-radius: 50%;
	background: #3d3d3d;
	border: 1px solid #000000;
}

.ResponsiveMode_desktop_font-slider__jW4ry input[type='range']::-ms-thumb {
	cursor: ew-resize;
	width: 16px;
	height: 16px;
	border-radius: 50%;
	background: #3d3d3d;
	border: 1px solid #000000;
}

/*Navigation Arrow*/
.ResponsiveMode_desktop_navigation-arrow__jdaYl {
	position: absolute;
	width: 70px;
	top: 50%;
	height: auto;
	z-index: 100;
	display: flex;
	justify-content: center;
	align-items: center;
}

.ResponsiveMode_desktop_navigation-arrow__jdaYl button {
	width: 50px;
	height: 50px;
	border-radius: 50%;
	z-index: 100;

	background: none;
	opacity: 0.5;
	border: 1px solid #eaeaea;
	background: #fff;
	transition: opacity ease-out 200ms;
}

.ResponsiveMode_desktop_navigation-arrow__jdaYl.ResponsiveMode_desktop_left__4UoWd {
	left: 0;
}

.ResponsiveMode_desktop_navigation-arrow__jdaYl.ResponsiveMode_desktop_left__4UoWd button {
	margin-left: 20px;
}

.ResponsiveMode_desktop_navigation-arrow__jdaYl.ResponsiveMode_desktop_right__2HTRU {
	right: 0;
}

.ResponsiveMode_desktop_navigation-arrow__jdaYl.ResponsiveMode_desktop_right__2HTRU button {
	margin-right: 20px;
}

.ResponsiveMode_desktop_navigation-arrow__jdaYl.ResponsiveMode_desktop_left__4UoWd:hover button,
.ResponsiveMode_desktop_navigation-arrow__jdaYl.ResponsiveMode_desktop_right__2HTRU:hover button {
	opacity: 1;
}

.ResponsiveMode_desktop_share-container__36dfm {
	float: left;
	position: fixed;
	min-width: 50px;
	width: 150px;
	height: 84px;
	z-index: 100;
	top: 0;
	background: #fff;
	right: 0;
	display: flex;
	margin-right: 0px;
	flex-direction: column;
	border: 1px solid #eaeaea;
	overflow: hidden;
	box-shadow: -2px 0 5px 0 rgb(0 0 0 / 40%);
	margin-top: calc(var(--navbar-height) + var(--progress-bar-height));
}

.ResponsiveMode_desktop_share-container__36dfm.ResponsiveMode_desktop_hidden__1fkCn {
	height: 0px;
	border: 0px;
	transition: height ease-out 200ms;
}

.ResponsiveMode_desktop_share-container__36dfm button {
	border: none;
	height: 42px;
	display: flex;
	background: transparent;
	align-items: center;
	transition: height ease-out 220ms;
}

.ResponsiveMode_desktop_share-container__36dfm button > :first-child {
	height: 32px;
	width: 32px;
}

.ResponsiveMode_desktop_share-container__36dfm button h4 {
	font-size: 1rem;
	margin-left: 8px;
}

.ResponsiveMode_desktop_share-container__36dfm > :first-child {
	border-bottom: 1px solid #eaeaea;
}

.ResponsiveMode_desktop_share-container__36dfm.ResponsiveMode_desktop_hidden__1fkCn button {
	height: 0px;
	border: 0;
	opacity: 0;
}

/*Print view*/
.ResponsiveMode_desktop_print-view__2qLwL section {
	height: auto;
	min-height: auto;
	width: 100%;
}

.ResponsiveMode_desktop_print-view__2qLwL main {
	min-width: 100% !important;
	width: 100% !important;
	margin-left: 0 !important;
}

.ResponsiveMode_desktop_print-view__2qLwL > div {
	width: 100%;
	margin: auto;
	max-width: 720px;
	height: 100vh !important;
}

.ResponsiveMode_desktop_print-view__2qLwL article .ResponsiveMode_desktop_canvas__3PM0n {
	display: block;
	position: relative;
	height: auto !important;
	overflow: visible !important;
}

.ResponsiveMode_desktop_print-view__2qLwL .ResponsiveMode_desktop_navigation-arrow__jdaYl {
	visibility: hidden;
}

.ResponsiveMode_desktop_print-view__2qLwL .ResponsiveMode_desktop_container__2o_X5 {
	min-height: 100%;
	height: 100%;
}

.ResponsiveMode_desktop_print-view__2qLwL main > div > div {
	overflow: unset !important;
}

/*Iframe view*/

.ResponsiveMode_desktop_iframe-view__3Dydc {
	height: 100%;
}

.ResponsiveMode_desktop_iframe-view__3Dydc nav,
.ResponsiveMode_desktop_iframe-view__3Dydc aside,
.ResponsiveMode_desktop_iframe-view__3Dydc .ResponsiveMode_desktop_progress-bar__3amaU {
	display: none;
}

.ResponsiveMode_desktop_iframe-view__3Dydc main {
	min-width: 100% !important;
	width: 100% !important;
	max-width: 100% !important;
	margin-left: 0px !important;
}

/*PLAYER TOOLBAR*/
.ResponsiveMode_desktop_player-toolbar__1RGgt {
	width: 25vw;
	max-width: 300px;
	background: white;
	position: absolute;
	display: grid;
	grid-template-columns: 1fr 100px;
	grid-template-rows: 1fr;
	grid-gap: 0px 0px;
	gap: 0px 0px;
	grid-template-areas: '. .';
	min-height: var(--bottom-bar-height);
	top: 0;
	right: 0;
	z-index: 10;
	border: 1px solid #eaeaea;
	transition: margin-top ease-out 220ms;
}

.ResponsiveMode_desktop_player-toolbar__1RGgt.ResponsiveMode_desktop_show__2DBVu {
	margin-top: 0;
}

.ResponsiveMode_desktop_player-toolbar__1RGgt.ResponsiveMode_desktop_hidden__1fkCn {
	margin-top: calc(-1 * var(--bottom-bar-height));
}

.ResponsiveMode_desktop_player-toolbar__1RGgt .ResponsiveMode_desktop_controls__2hMOj {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.ResponsiveMode_desktop_player-toolbar__1RGgt .ResponsiveMode_desktop_controls__2hMOj button {
	border: none;
	background: none;
	width: 50px;
	height: 50px;
}

.ResponsiveMode_desktop_player-toolbar__1RGgt .ResponsiveMode_desktop_controls__2hMOj button:disabled {
	opacity: 0.5;
}

.ResponsiveMode_desktop_player-toolbar__1RGgt .ResponsiveMode_desktop_progress__1gkId {
	display: flex;
	justify-content: center;
	align-items: center;
}

.ResponsiveMode_issue__3SrNt {
	display: grid;
	grid-template-rows: var(--navbar-height) 1fr;
	grid-template-columns: 0.2fr 0.8fr;
	transition: all ease-out 200ms;
	grid-gap: 0px 0px;
	gap: 0px 0px;
	grid-template-areas:
		'navbar navbar'
		'toc content';
}

.ResponsiveMode_navbar__2GzKV {
	grid-area: navbar;
}

.ResponsiveMode_navbar__2GzKV.ResponsiveMode_mobile__3jrK0 {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: var(--navbar-height) 0fr;
	grid-gap: 0px 0px;
	gap: 0px 0px;
	grid-template-areas:
	  "."
	  "navbar-toc";
}

.ResponsiveMode_navbar__2GzKV.ResponsiveMode_mobile__3jrK0.ResponsiveMode_expand__1xUs9 {
	grid-template-rows: var(--navbar-height) 1fr;
}

.ResponsiveMode_toc__3yyht {
	grid-area: toc;
    width: 100%;
	height: 100%;
	overflow: auto;
	border-right: 1px solid #eee;
	list-style-position: inside;
}

.ResponsiveMode_toc__3yyht > li {
	display: block;
	display: list-item;
	padding: 1.4em;
	font-size: 0.9rem;
	line-height: 1.5;
}

.ResponsiveMode_toc__3yyht > .ResponsiveMode_item__3efXT {
	cursor: pointer;
	transition: all ease-out 200ms;
}

.ResponsiveMode_content__2ClLH {
	grid-area: content;
}

.ResponsiveMode_article-swiper__W_HWi article {
	overflow-x: hidden;
	max-height: 100%;
}

.ResponsiveMode_swiper-container__pyL08 {
	width: 100%;
	margin: auto;
	height: 100%;
}

.ResponsiveMode_swiper-container__pyL08 > :first-child {
	height: 100%;
}
.ResponsiveMode_mobile_issue__3xrxa {
	display: block;
	position: relative;
	height: 100%;
	width: 100%;
	overflow: hidden;
}

.ResponsiveMode_mobile_issue__3xrxa main {
	min-height: calc(
		100% - var(--navbar-height) - var(--bottom-bar-height) -
			env(safe-area-inset-bottom, var(--safe-area-inset))
	);
	height: calc(
		100% - var(--navbar-height) - var(--bottom-bar-height) -
			env(safe-area-inset-bottom, var(--safe-area-inset))
	);
}

.ResponsiveMode_mobile_issue__3xrxa footer {
	position: relative;
	background: #3d3d3d;
	display: flex;
	justify-content: space-around;
	align-items: center;
	width: 100%;
	z-index: 100;
	min-height: calc(
		var(--bottom-bar-height) +
			env(safe-area-inset-bottom, var(--safe-area-inset))
	);
	height: calc(
		var(--bottom-bar-height) +
			env(safe-area-inset-bottom, var(--safe-area-inset))
	);
	max-height: calc(
		var(--bottom-bar-height) +
			env(safe-area-inset-bottom, var(--safe-area-inset))
	);
	padding-bottom: env(safe-area-inset-bottom, var(--safe-area-inset));
	border-top: 1px solid #e0e4ea;
}

.ResponsiveMode_mobile_progress-bar__1XpLx > div {
	height: 100%;
	transition: width ease-out 220ms;
}

.ResponsiveMode_mobile_toolbar__QzKx_ button {
	border: none;
	background: none;
	width: 50px;
	height: 50px;
}

.ResponsiveMode_mobile_toolbar__QzKx_ button:disabled {
	opacity: 0.5;
	cursor: not-allowed;
}

.ResponsiveMode_mobile_navbar__2kbJm {
	min-height: var(--navbar-height);
	max-height: var(--navbar-height);
	display: flex;
	justify-content: space-between;
	background: black;
	padding: 0px 0px;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.18), 0 1px 1px rgba(0, 0, 0, 0.03) !important;
	z-index: 150;
}

.ResponsiveMode_mobile_navbar__2kbJm div {
	display: flex;
	align-items: center;
}

/*Search view*/

.ResponsiveMode_mobile_search-view__e0r8o {
	width: 100%;
	height: 100%;
	overflow: hidden;
	display: block;
}

.ResponsiveMode_mobile_search-view__e0r8o .ResponsiveMode_mobile_navbar__2kbJm {
	display: flex;
	justify-content: center;
	align-items: center;
	background: #3d3d3d;
}

.ResponsiveMode_mobile_search-bar__3z4WG {
	width: 100%;
	height: 36px;
	position: relative;
}

.ResponsiveMode_mobile_search-bar__3z4WG .ResponsiveMode_mobile_icon__QE3Rl {
	width: 24px;
	margin-left: 10px;
	opacity: 0.5;
	height: 24px;
	position: absolute;
}

.ResponsiveMode_mobile_search-bar__3z4WG input {
	width: 98%;
	margin: auto;
	height: 100%;
	border: 0px;
	overflow: hidden;
	color: #fff;
	border-radius: 6px;
	background: #636363;
	padding-left: 32px;
	font-size: 1rem;
}

.ResponsiveMode_mobile_search-bar__3z4WG input:focus {
	outline: none;
}

.ResponsiveMode_mobile_search-bar__3z4WG input::-webkit-input-placeholder {
	color: #fff;
	font-size: 0.9rem;
	opacity: 0.5;
}

.ResponsiveMode_mobile_search-bar__3z4WG input::placeholder {
	color: #fff;
	font-size: 0.9rem;
	opacity: 0.5;
}

.ResponsiveMode_mobile_search-view__e0r8o ul > :not(:last-child) {
	border-bottom: 1px solid #eaeaea;
}

.ResponsiveMode_mobile_search-view__e0r8o li {
	height: 80px;
	width: 100%;
	display: grid;
	grid-template-columns: 60px 1fr;
	grid-template-rows: 1fr;
	grid-gap: 0px 0px;
	gap: 0px 0px;
	grid-template-areas: '. .';
}

.ResponsiveMode_mobile_search-view__e0r8o li > :first-child {
	display: flex;
	justify-content: center;
	align-items: center;
}

.ResponsiveMode_mobile_search-view__e0r8o li > :last-child {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	overflow: hidden;
	padding-right: 12px;
}

.ResponsiveMode_mobile_search-view__e0r8o li h4 {
	overflow: hidden;
	text-overflow: ellipsis;
	font-size: 0.8rem;
	max-width: calc(100%);
	white-space: nowrap;
}

.ResponsiveMode_mobile_search-view__e0r8o li small {
	overflow: hidden;
	text-overflow: ellipsis;
	font-size: 0.7rem;
	font-style: italic;
	color: #c4c4c4;
	white-space: nowrap;
	margin-top: 4px;
}

.ResponsiveMode_mobile_search-view__e0r8o li img {
	width: 60%;
	height: 60%;
	object-fit: contain;
	overflow: hidden;
	border-radius: 4px;
}

.ResponsiveMode_mobile_navbar__2kbJm button {
	width: 24px;
	height: 24px;
	padding: 0;
	border: none;
	background: none;
}

.ResponsiveMode_mobile_search-view__e0r8o main {
	overflow: auto;
}

/*ISSUEE*/

.ResponsiveMode_mobile_issue-view__2sSgT {
	width: 100%;
	height: 100%;
	overflow: hidden;
	display: block;
}

.ResponsiveMode_mobile_issue-view__2sSgT .ResponsiveMode_mobile_navbar__2kbJm {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-areas: 'logo actions';
	min-height: var(--navbar-height);
	grid-template-rows: 1fr;
	grid-gap: 0px 0px;
	gap: 0px 0px;
	height: 100%;
}

.ResponsiveMode_mobile_navbar__2kbJm .ResponsiveMode_mobile_logo__1272t {
	grid-area: logo;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
}

.ResponsiveMode_mobile_navbar__2kbJm .ResponsiveMode_mobile_logo__1272t a {
	max-width: 100%;
}

.ResponsiveMode_mobile_navbar__2kbJm .ResponsiveMode_mobile_logo__1272t img {
	/*max-width: 100%;*/
}

.ResponsiveMode_mobile_navbar__2kbJm .ResponsiveMode_mobile_actions__2wd-4 {
	grid-area: actions;
}

.ResponsiveMode_mobile_issue-view__2sSgT .ResponsiveMode_mobile_navbar__2kbJm button {
	border: none;
	background: none;
	width: 50px;
	height: 50px;
}

.ResponsiveMode_mobile_issue-view__2sSgT .ResponsiveMode_mobile_navbar__2kbJm > :first-child {
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
}

.ResponsiveMode_mobile_issue-view__2sSgT .ResponsiveMode_mobile_navbar__2kbJm > :first-child img {
	height: var(--navbar-height);
	min-height: var(--navbar-height);
	object-fit: cover;
}

.ResponsiveMode_mobile_issue-view__2sSgT .ResponsiveMode_mobile_navbar__2kbJm > :last-child {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.ResponsiveMode_mobile_issue-view__2sSgT main {
	position: relative;
	overflow: hidden;
}

/*.issue-view aside,
.issue-view section {
	overflow: hidden;
	overflow-y: auto;
}*/

.ResponsiveMode_mobile_issue-view__2sSgT .ResponsiveMode_mobile_content__2j0Bt {
	width: 100%;
	height: 100%;
	transition: -webkit-transform ease-out 220ms;
	transition: transform ease-out 220ms;
	transition: transform ease-out 220ms, -webkit-transform ease-out 220ms;
	/*overflow-y: auto;*/
}

.ResponsiveMode_mobile_issue-view__2sSgT .ResponsiveMode_mobile_content__2j0Bt > :first-child {
	min-height: 5px;
	height: 5px;
	position: relative;
	width: 100%;
	z-index: 10;
	background: #fff;
	border-bottom: 1px solid #eaeaea;
}

/*.issue-view .content > :last-child {
	height: calc(100% - 5px);
}*/

.ResponsiveMode_mobile_issue-view__2sSgT aside {
	position: absolute;
	width: 100%;
	height: 100%;
	transition: -webkit-transform ease-out 220ms;
	transition: transform ease-out 220ms;
	transition: transform ease-out 220ms, -webkit-transform ease-out 220ms;
}

.ResponsiveMode_mobile_toc-closed__3qhlh aside {
	-webkit-transform: translateX(-100%);
	        transform: translateX(-100%);
}

.ResponsiveMode_mobile_toc-open__3j9Vs aside {
	-webkit-transform: translateX(0%);
	        transform: translateX(0%);
}

.ResponsiveMode_mobile_issue-view__2sSgT aside ul > :not(:last-child) {
	border-bottom: 1px solid #eaeaea;
}

.ResponsiveMode_mobile_issue-view__2sSgT aside li {
	height: 80px;
	width: 100%;
	display: grid;
	grid-template-columns: 60px 1fr;
	grid-template-rows: 1fr;
	grid-gap: 0px 0px;
	gap: 0px 0px;
	grid-template-areas: '. .';
}

.ResponsiveMode_mobile_issue-view__2sSgT aside li.ResponsiveMode_mobile_selected__2kXg0 {
	font-weight: bold;
	font-family: Gibson-Semibold;
	border-left: 5px solid #000;
}

.ResponsiveMode_mobile_issue-view__2sSgT aside li > :first-child {
	display: flex;
	justify-content: center;
	align-items: center;
}

.ResponsiveMode_mobile_issue-view__2sSgT aside li .ResponsiveMode_mobile_item-content__2SrzH {
	height: 100%;
	padding-left: 8px;
	max-width: 100%;
	padding-right: 12px;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.ResponsiveMode_mobile_issue-view__2sSgT aside li img {
	width: 60%;
	height: 60%;
	border-radius: 4px;
	object-fit: contain;
}

.ResponsiveMode_mobile_issue-view__2sSgT aside li h4 {
	font-size: 1rem;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	line-height: 1.4;
}

.ResponsiveMode_mobile_issue-view__2sSgT aside li p {
	font-size: 0.8rem;
	color: #c4c4c4;
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.ResponsiveMode_mobile_issue-view__2sSgT aside li small {
	font-size: 0.7rem;
	color: #c4c4c4;
	font-style: italic;
}

.ResponsiveMode_mobile_issue-view__2sSgT aside li br {
	height: 5px;
}

.ResponsiveMode_mobile_toc-open__3j9Vs section {
	-webkit-transform: translateX(100%);
	        transform: translateX(100%);
	transition: -webkit-transform ease-out 220ms;
	transition: transform ease-out 220ms;
	transition: transform ease-out 220ms, -webkit-transform ease-out 220ms;
}

/*PLAYER TOOLBAR*/
.ResponsiveMode_mobile_player-toolbar__3nLgb {
	width: 100%;
	background: white;
	position: absolute;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-template-rows: 1fr;
	grid-gap: 0px 0px;
	gap: 0px 0px;
	grid-template-areas: '. . .';
	min-height: var(--bottom-bar-height);
	bottom: 0;
	border: 1px solid #eaeaea;
}

.ResponsiveMode_mobile_player-toolbar__3nLgb.ResponsiveMode_mobile_show__3C5gI {
	z-index: 300;
	margin-bottom: calc(
		var(--bottom-bar-height) +
			env(safe-area-inset-bottom, var(--safe-area-inset))
	);
}

.ResponsiveMode_mobile_player-toolbar__3nLgb.ResponsiveMode_mobile_hidden__1nONK {
	z-index: 0;
	margin-bottom: 0;
}

.ResponsiveMode_mobile_player-toolbar__3nLgb .ResponsiveMode_mobile_thumbnail__3sZLZ {
	display: flex;
	justify-content: center;
	align-items: center;
}

.ResponsiveMode_mobile_player-toolbar__3nLgb .ResponsiveMode_mobile_thumbnail__3sZLZ img {
	width: calc(var(--bottom-bar-height) * 0.75);
	height: calc(var(--bottom-bar-height) * 0.75);
	object-fit: cover;
	overflow: hidden;
	border-radius: 4px;
}

.ResponsiveMode_mobile_player-toolbar__3nLgb .ResponsiveMode_mobile_controls__25lwq {
	display: flex;
	justify-content: center;
	align-items: center;
}

.ResponsiveMode_mobile_player-toolbar__3nLgb .ResponsiveMode_mobile_controls__25lwq button {
	border: none;
	background: none;
	width: 50px;
	height: 50px;
}

.ResponsiveMode_mobile_player-toolbar__3nLgb .ResponsiveMode_mobile_controls__25lwq button:disabled {
	opacity: 0.5;
}

.ResponsiveMode_mobile_player-toolbar__3nLgb .ResponsiveMode_mobile_progress__1fG3I {
	display: flex;
	justify-content: center;
	align-items: center;
}

.ResponsiveMode_mobile_empty__17sEF {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #c4c4c4;
}

.ResponsiveMode_mobile_empty__17sEF > div {
	display: flex;
	flex-direction: column;
}

.ResponsiveMode_mobile_not-found__1cfiv {
	width: 100%;
	height: 100%;
	color: #c4c4c4;
	display: flex;
	justify-content: center;
	align-items: center;
}

.ResponsiveMode_mobile_not-found__1cfiv i {
	font-style: italic;
}

.ResponsiveMode_mobile_empty__17sEF h3,
.ResponsiveMode_mobile_empty__17sEF p,
.ResponsiveMode_mobile_not-found__1cfiv h3,
.ResponsiveMode_mobile_not-found__1cfiv p {
	text-align: center;
}

.ResponsiveMode_mobile_empty__17sEF > div > :first-child {
	width: 30vw;
	height: 30vw;
	margin: auto;
	-webkit-mask-size: 100%;
	        mask-size: 100%;
}

/*Iframe*/
.ResponsiveMode_mobile_iframe-view__1d7ip {
	width: 100%;
	height: 100%;
	overflow: auto;
	display: block;
}

.ResponsiveMode_mobile_iframe-view__1d7ip .ResponsiveMode_mobile_navbar__2kbJm,
.ResponsiveMode_mobile_iframe-view__1d7ip aside,
.ResponsiveMode_mobile_iframe-view__1d7ip footer,
.ResponsiveMode_mobile_iframe-view__1d7ip > div {
	display: none;
	min-height: 0;
}

.ResponsiveMode_mobile_iframe-view__1d7ip main {
	min-height: 100%;
	height: 100%;
}

.ResponsiveMode_mobile_toc-open__3j9Vs {
	overflow-y: auto !important;
}

/*Navigation Arrow*/
.ResponsiveMode_mobile_navigation-arrow__12ggH {
	position: absolute;
	width: 70px;
	top: 50%;
	height: auto;
	z-index: 100;
	display: flex;
	justify-content: center;
	align-items: center;
}

.ResponsiveMode_mobile_navigation-arrow__12ggH button {
	width: 50px;
	height: 50px;
	border-radius: 50%;
	z-index: 100;

	background: none;
	opacity: 0.5;
	border: 1px solid #eaeaea;
	background: #fff;
	transition: opacity ease-out 200ms;
}

.ResponsiveMode_mobile_navigation-arrow__12ggH.ResponsiveMode_mobile_left__2HqMx {
	left: 0;
}

.ResponsiveMode_mobile_navigation-arrow__12ggH.ResponsiveMode_mobile_left__2HqMx button {
	margin-left: 20px;
}

.ResponsiveMode_mobile_navigation-arrow__12ggH.ResponsiveMode_mobile_right__2M3X6 {
	right: 0;
}

.ResponsiveMode_mobile_navigation-arrow__12ggH.ResponsiveMode_mobile_right__2M3X6 button {
	margin-right: 20px;
}

.ResponsiveMode_mobile_navigation-arrow__12ggH.ResponsiveMode_mobile_left__2HqMx:hover button,
.ResponsiveMode_mobile_navigation-arrow__12ggH.ResponsiveMode_mobile_right__2M3X6:hover button {
	opacity: 1;
}

.Lightbox_lightbox__2NOP4 {
	width: 100vw;
	height: 100%;
	position: fixed;
	background: rgba(0, 0, 0, 0.85);
	display: flex;
	justify-content: center;
	align-items: center;
	float: left;
	top: 0;
	z-index: 999999;
}

.Lightbox_lightbox__2NOP4 > div {
	position: relative;
	max-height: 80%;
	max-width: 90%;
}

.Lightbox_close__3Mvx5 {
	width: 32px;
	height: 32px;
	position: absolute;
	right: 0;
	border-radius: 50%;
	margin-right: -16px;
	margin-top: -16px;
	background: white;
	border: 0px;
	z-index: 100;
	box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.75);
}

.Lightbox_content__H0OGN {
	overflow-x: hidden;
	overflow-y: auto;
	background: white;
}

.Lightbox_content__H0OGN article {
	max-height: 100%;
}

@media screen and (max-width: 767px) {
	.Lightbox_content__H0OGN {
		max-height: 70vh;
	}
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
	.Lightbox_content__H0OGN {
		max-height: 70vh;
	}
}

@media screen and (min-width: 1024px) {
	.Lightbox_content__H0OGN {
		max-height: 70vh;
	}

	.Lightbox_close__3Mvx5 {
		width: 36px;
		height: 36px;
		margin-right: -18px;
		margin-top: -18px;
	}
}

.Lock_lock__yDpOJ {
	height: 100vh;
    width: 100vw;
	background: #fafafa;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Lock_lock__yDpOJ > form {
    display: flex;
    flex-direction: column;
}

.Lock_lock-icon__2ilOh{
    height: 64px;
    width: 64px;
    background: #3d3d3d;
	-webkit-transform: translate(0%, -50%);
	        transform: translate(0%, -50%);
	-webkit-mask: url(/static/media/lock.733ab324.svg);
	        mask: url(/static/media/lock.733ab324.svg);
	-webkit-mask-size: cover;
	        mask-size: cover;
	margin: auto;
}

.Lock_lock-button__3Zoh3 {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.Lock_lock-button__3Zoh3 > button {
    border: none;
    width: 100%;
    height: 46px;
    background: #3d3d3d;
    color: white;
    border-radius: 4px;
}
.Settings_settings__1K2EA {
	width: 100%;
	height: calc(
		100vh - var(--bottom-bar-height) -
			env(safe-area-inset-bottom, var(--safe-area-inset))
	);
	overflow: auto;
	padding-top: var(--navbar-height);

	-webkit-user-select: none;
	user-select: none;
}

.Settings_settings__1K2EA {
	background: #f5f5f5;
}

.Settings_settings__1K2EA > div {
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
}

.Settings_settings__1K2EA > div > div {
	width: 100%;
}

.Settings_settings__1K2EA > div > a {
	text-decoration: none;
	color: inherit;
}

.Settings_settings__1K2EA > div > .Settings_group__1JqHy > * {
	background: #fff;
	height: 60px;
	margin-bottom: 1px;
	display: flex;
	align-items: center;
	padding-left: 20px;
	padding-right: 20px;
	color: #3d3d3d;
	cursor: pointer;
}

.Settings_settings__1K2EA > div > .Settings_group__1JqHy > a {
	text-decoration: none;
	color: inherit;
}

/*Stylesheet for the storage section*/

.Settings_settings-storage__1c5PZ {
	width: 100%;
	height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	padding-top: 20px;
	background: #f4f4f4;
	padding-top: calc(var(--navbar-height) + 20px);
	
	-webkit-user-select: none;
	user-select: none;
}

.Settings_settings-storage__1c5PZ > div {
	width: 100%;
}

.Settings_settings-storage__1c5PZ > .Settings_group__1JqHy > div {
	background: #fff;
	height: 60px;
	margin-bottom: 1px;
	display: flex;
	align-items: center;
	padding-left: 20px;
	color: #3d3d3d;
	cursor: pointer;
}

.Settings_settings__1K2EA > div > :first-child {
	height: 60px;
	color: #3d3d3d;
	background: #fff;
	width: 100%;
	padding-left: 20px;
	display: flex;
	align-items: center;
	margin-bottom: 10px;
	margin-top: 10px;
}

/*Stylesheet for the subscription section*/
.Settings_settings-subscription__1dda6 {
	width: 100%;
	height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	padding-top: calc(var(--navbar-height) + 20px);
	background: #f4f4f4;

	-webkit-user-select: none;
	user-select: none;
}

.Settings_settings-subscription__1dda6 > div {
	background: #fff;
	height: 100px;
	margin-bottom: 1px;
	display: flex;
	flex-direction: column;
	width: 100%;
	justify-content: center;
	align-items: center;
	color: #3d3d3d;
	cursor: pointer;
}

.Settings_settings-language__NGkaz {
	width: 100%;
	height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	padding-top: 20px;
	background: #f4f4f4;
	padding-top: calc(var(--navbar-height) + 20px);

	-webkit-user-select: none;
	user-select: none;
}

.Settings_settings-language__NGkaz > div {
	width: 100%;
}

.Settings_settings-language__NGkaz > .Settings_group__1JqHy > div {
	background: #fff;
	height: 60px;
	margin-bottom: 1px;
	display: flex;
	align-items: center;
	padding-left: 20px;
	padding-right: 20px;
	color: #3d3d3d;
	cursor: pointer;
	justify-content: space-between;
}

.Settings_settings-about__2t4GU {
	width: 100%;
	height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	padding-top: 20px;
	background: #f4f4f4;
	overflow-y: scroll;
	padding-top: calc(var(--navbar-height) + 20px);

	-webkit-user-select: none;
	user-select: none;
}

.Settings_settings-about__2t4GU > div {
	width: 100%;
}

.Settings_settings-about__2t4GU > .Settings_group__1JqHy > div {
	background: #fff;
	height: 60px;
	margin-bottom: 1px;
	display: flex;
	align-items: center;
	padding-left: 20px;
	padding-right: 20px;
	color: #3d3d3d;
	cursor: pointer;
	justify-content: space-between;
}

.Settings_settings-about__2t4GU > :last-of-type.Settings_group__1JqHy {
	margin-bottom: 30px;
}

.Settings_dialog-overlay__3lc5R {
	width: 100%;
	height: 100%;
	z-index: 9999;
	position: fixed;
	top: 0;
	float: left;
	display: flex;
	justify-content: center;
	align-items: center;
	background: rgba(0, 0, 0, 0.5);
}

.Settings_admin-dialog-container__24-Vf {
	max-width: 90%;
	min-width: 350px;
	width: 450px;
	height: 450px;
	background: #fff;
	border-radius: 4px;
	z-index: 99999;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 0.2fr 1.8fr;
	grid-gap: 0px 0px;
	gap: 0px 0px;
	grid-template-areas:
		'.'
		'.';
}

.Settings_admin-dialog-container__24-Vf > .Settings_topbar__1TGmn {
	width: 100%;
	height: var(--navbar-height);
	display: grid;
	grid-template-columns: 0.3fr 2.4fr 0.3fr;
	grid-template-rows: 1fr;
	grid-gap: 0px 0px;
	gap: 0px 0px;
	grid-template-areas:
	  ". . .";
	border-bottom: 1px solid #eee;
}

.Settings_admin-dialog-container__24-Vf > .Settings_topbar__1TGmn > h4 {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.Settings_admin-dialog-container__24-Vf > .Settings_topbar__1TGmn > button {
	-webkit-mask: url(/static/media/x-icon.6c9a4ba7.svg);
	        mask: url(/static/media/x-icon.6c9a4ba7.svg);
	height: 24px;
	width: 24px;
	margin-right: 10px;
	margin-top: auto;
	margin-bottom: auto;
	margin-left: auto;
	background: #000;
	right: 0;
}

.Settings_admin-dialog-container__24-Vf > .Settings_content__2sIQA {
	width: 100%;
	height: 100%;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 1.7fr 0.3fr;
	grid-gap: 0px 0px;
	gap: 0px 0px;
	grid-template-areas:
		'.'
		'.';
}

.Settings_admin-dialog-container__24-Vf > .Settings_content__2sIQA > div {
	padding: 0px 16px;
}

.Settings_admin-dialog-container__24-Vf > .Settings_content__2sIQA > div > .Settings_error__3Beq8 {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	top: 0;
	min-height: 30px;
	width: 100%;
	padding: 10px 10px;
	color: #fff;
	margin: 0px -16px;
	background: red;
	font-size: 0.8rem;
	transition: opacity ease-out 200ms;
	opacity: 1;
}

.Settings_admin-dialog-container__24-Vf > .Settings_content__2sIQA > div > .Settings_error__3Beq8.Settings_hide__39beJ {
	opacity: 0;
}

.Settings_admin-dialog-container__24-Vf > .Settings_content__2sIQA > :first-child {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.Settings_admin-dialog-container__24-Vf > .Settings_content__2sIQA > :first-child > img {
	width: 90px;
	height: 90px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 56px;
}

.Settings_admin-dialog-container__24-Vf > .Settings_content__2sIQA > :first-child > input {
	margin-bottom: 16px;
	height: 45px;
	padding-left: 8px;
	border: 1px solid #eee;
	border-radius: 4px;
}

.Settings_admin-dialog-container__24-Vf > .Settings_content__2sIQA > :last-child {
	display: flex;
	justify-content: center;
	align-items: center;
	border-top: 1px solid #eee;
}

.Settings_admin-dialog-container__24-Vf > .Settings_content__2sIQA > :last-child > button {
	width: 100%;
	background: #000;
	color: #fff;
	height: 32px;
	border: none;
	border-radius: 4px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.Settings_admin-dialog-container__24-Vf > .Settings_content__2sIQA > :last-child > button:disabled {
	background: rgba(0, 0, 0, 0.5);
	cursor: not-allowed;
}

@media screen and (min-width: 769px) {
	.Settings_settings__1K2EA {
		display: flex;
		height: calc(
			100vh - env(safe-area-inset-bottom, var(--safe-area-inset))
		);
	}

	.Settings_container__3jXWk {
		width: 100%;
	}

	.Settings_settings__1K2EA > div {
		width: calc(100% - var(--bottom-bar-height));
		margin-left: var(--bottom-bar-height);
	}
}

.container {
	height: 100%;
	width: 100%;
}

.main {
	min-height: 100%;
	min-width: 100%;
}

